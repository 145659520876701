import { Grid } from "@material-ui/core";
import * as React from "react";
import { useParams } from "react-router-dom";
import ClientDetails from "./components/ClientDetails";
import ClientYards from "./components/ClientYards";

/**
 * Client Page
 *
 * Contains: customer edit form,
 * list of its yards and drops
 */
const Client = () => {
	const { clientId } = useParams();

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<ClientDetails clientId={clientId} />
			</Grid>
			<Grid item xs={12}>
				<ClientYards clientId={clientId} />
			</Grid>
		</Grid>
	);
};

export default Client;
