/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The ProductUpdate model module.
* @module model/ProductUpdate
* @version 0.0.1
*/
export default class ProductUpdate {
    /**
    * Constructs a new <code>ProductUpdate</code>.
    * @alias module:model/ProductUpdate
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ProductUpdate</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ProductUpdate} obj Optional instance to populate.
    * @return {module:model/ProductUpdate} The populated <code>ProductUpdate</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductUpdate();
                        
            
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'String');
            }
            if (data.hasOwnProperty('field')) {
                obj['field'] = ApiClient.convertToType(data['field'], 'String');
            }
            if (data.hasOwnProperty('oldValue')) {
                obj['oldValue'] = ApiClient.convertToType(data['oldValue'], 'Number');
            }
            if (data.hasOwnProperty('newValue')) {
                obj['newValue'] = ApiClient.convertToType(data['newValue'], 'Number');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
        }
        return obj;
    }

    /**
    * Id of product modified
    * @member {String} productId
    */
    productId = undefined;
    /**
    * Enum of ['price', 'quantity', 'deleted'] 
    * @member {String} field
    */
    field = undefined;
    /**
    * Old value of 'key' of product
    * @member {Number} oldValue
    */
    oldValue = undefined;
    /**
    * New value of 'key' of product
    * @member {Number} newValue
    */
    newValue = undefined;
    /**
    * Optional field, describe the modification
    * @member {String} message
    */
    message = undefined;




}
