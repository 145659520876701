import { Button, FormControlLabel, Switch } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useLocal } from "../../../../../contexts/LocalContextProvider";
import { useModal } from "../../../../../contexts/ModalContextProvider";

const YardActions = ({ yard, selectedYardIds }) => {
	const { toggleYardUpdateMode, setHeatmap, yardInUpdateMode } = useLocal();
	const { openModal, setModalType } = useModal();

	const [init, setinit] = React.useState(true);

	React.useEffect(() => {
		if (init && yardInUpdateMode) {
			toggleYardUpdateMode();
		}

		return () => {
			setinit(false);
		};
	}, [init, toggleYardUpdateMode, yardInUpdateMode]);

	return (
		<>
			<Grid item>
				<FormControlLabel
					labelPlacement="end"
					control={
						<Switch
							onChange={() => toggleYardUpdateMode()}
							color="primary"
							size="small"
						/>
					}
					label={i18n.t("Client.Yards.editYardsMode")}
				/>
			</Grid>
			<Grid item>
				<Button
					variant="outlined"
					onClick={() => {
						setModalType("yardEditMap");
						openModal(true, {
							title: i18n.t("components.YardEditMap.title", {
								name: yard.name,
							}),
							options: {
								fullScreen: true,
								maxWidth: "xl",
							},
						});
					}}
					disabled={selectedYardIds.length !== 1}
					size="small"
				>
					<Typography variant="button">
						{i18n.t("Client.Yards.tooltips.editYardDelineation")}
					</Typography>
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant="outlined"
					onClick={() => {
						setModalType("dropEditMap");
						openModal(true, {
							title: i18n.t("components.DropEditMap.title", {
								name: yard.name,
							}),
							options: { fullScreen: true, maxWidth: "xl" },
						});
					}}
					disabled={selectedYardIds.length !== 1}
					size="small"
				>
					<Typography variant="button">
						{i18n.t("Client.Yards.editDrops")}
					</Typography>
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant="outlined"
					onClick={() => {
						setHeatmap("nope");
						setModalType("heatmap");
						openModal(true, {
							title: i18n.t("Client.Yards.heatmap.title"),
							options: { fullScreen: true, maxWidth: "xl" },
						});
					}}
					disabled={!selectedYardIds.length}
					size="small"
				>
					<Typography variant="button">
						{i18n.t("Client.Yards.heatmap.button")}
					</Typography>
				</Button>
			</Grid>
		</>
	);
};

YardActions.propTypes = {
	yard: PropTypes.object,
	selectedYardIds: PropTypes.array.isRequired,
};

export default YardActions;
