/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The CreditCard model module.
* @module model/CreditCard
* @version 0.0.1
*/
export default class CreditCard {
    /**
    * Constructs a new <code>CreditCard</code>.
    * Credit card data of the users (obfuscated data)
    * @alias module:model/CreditCard
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>CreditCard</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CreditCard} obj Optional instance to populate.
    * @return {module:model/CreditCard} The populated <code>CreditCard</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreditCard();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('brand')) {
                obj['brand'] = ApiClient.convertToType(data['brand'], 'String');
            }
            if (data.hasOwnProperty('expMonth')) {
                obj['expMonth'] = ApiClient.convertToType(data['expMonth'], 'Number');
            }
            if (data.hasOwnProperty('expYear')) {
                obj['expYear'] = ApiClient.convertToType(data['expYear'], 'Number');
            }
            if (data.hasOwnProperty('last4')) {
                obj['last4'] = ApiClient.convertToType(data['last4'], 'String');
            }
        }
        return obj;
    }

    /**
    * stripe id of the card
    * @member {String} id
    */
    id = undefined;
    /**
    * card brand
    * @member {String} brand
    */
    brand = undefined;
    /**
    * Month expiry of the card
    * @member {Number} expMonth
    */
    expMonth = undefined;
    /**
    * Year expiry of the card
    * @member {Number} expYear
    */
    expYear = undefined;
    /**
    * Last 4 digits of the card
    * @member {String} last4
    */
    last4 = undefined;




}
