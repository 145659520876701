/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BeekeeperWithCustomerInformation from './BeekeeperWithCustomerInformation';
import PaymentToBeekeeper from './PaymentToBeekeeper';
import ProductProposed from './ProductProposed';
import ProductProposedUpdate from './ProductProposedUpdate';

/**
* The ProductProposedWithDetails model module.
* @module model/ProductProposedWithDetails
* @version 0.0.1
*/
export default class ProductProposedWithDetails {
    /**
    * Constructs a new <code>ProductProposedWithDetails</code>.
    * @alias module:model/ProductProposedWithDetails
    * @class
    * @extends module:model/ProductProposed
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ProductProposedWithDetails</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ProductProposedWithDetails} obj Optional instance to populate.
    * @return {module:model/ProductProposedWithDetails} The populated <code>ProductProposedWithDetails</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductProposedWithDetails();
            
            ProductProposed.constructFromObject(data, obj);
            
            if (data.hasOwnProperty('customer')) {
                obj['customer'] = BeekeeperWithCustomerInformation.constructFromObject(data['customer']);
            }
            if (data.hasOwnProperty('payments')) {
                obj['payments'] = ApiClient.convertToType(data['payments'], [PaymentToBeekeeper]);
            }
        }
        return obj;
    }

    /**
    * @member {module:model/BeekeeperWithCustomerInformation} customer
    */
    customer = undefined;
    /**
    * @member {Array.<module:model/PaymentToBeekeeper>} payments
    */
    payments = undefined;




}
