/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Beekeeper model module.
* @module model/Beekeeper
* @version 0.0.1
*/
export default class Beekeeper {
    /**
    * Constructs a new <code>Beekeeper</code>.
    * Complement of customer&#x27;s information if the customer is using the beekeeper part of the website
    * @alias module:model/Beekeeper
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Beekeeper</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Beekeeper} obj Optional instance to populate.
    * @return {module:model/Beekeeper} The populated <code>Beekeeper</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Beekeeper();
                        
            
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
            }
            if (data.hasOwnProperty('validationStatus')) {
                obj['validationStatus'] = ApiClient.convertToType(data['validationStatus'], 'String');
            }
            if (data.hasOwnProperty('isContacted')) {
                obj['isContacted'] = ApiClient.convertToType(data['isContacted'], 'Boolean');
            }
            if (data.hasOwnProperty('contactMethod')) {
                obj['contactMethod'] = ApiClient.convertToType(data['contactMethod'], 'String');
            }
        }
        return obj;
    }

    /**
    * Id of the customer
    * @member {String} customerId
    */
    customerId = undefined;
    /**
    * Indicates beekeeper status between [\"validated\", \"not-validated\", \"rejected\"]
    * @member {String} validationStatus
    */
    validationStatus = undefined;
    /**
    * Indicates if beekeeper was contacted by Ubees
    * @member {Boolean} isContacted
    */
    isContacted = undefined;
    /**
    * Indicates the contact method
    * @member {String} contactMethod
    */
    contactMethod = undefined;




}
