/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Location model module.
* @module model/Location
* @version 0.0.1
*/
export default class Location {
    /**
    * Constructs a new <code>Location</code>.
    * @alias module:model/Location
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Location</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Location} obj Optional instance to populate.
    * @return {module:model/Location} The populated <code>Location</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Location();
                        
            
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
        }
        return obj;
    }

    /**
    * Latitude (as given by Google maps)
    * @member {Number} latitude
    */
    latitude = undefined;
    /**
    * Longitude (as given by Google maps)
    * @member {Number} longitude
    */
    longitude = undefined;




}
