import { createStyles, makeStyles } from "@material-ui/core/styles";
import { GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";
import * as React from "react";
import PinIcon from "../../../images/pin.svg";
import theme from "../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginLeft: "auto",
			marginRight: "auto",
			height: 500,
			pageBreakAfter: "always",
			padding: theme.dimensions.indent,
			[theme.breakpoints.down("xs")]: {
				paddingBlock: theme.dimensions.indent / 4,
				width: "auto",
			},
		},
		labelContainer: {
			borderRadius: theme.roundness,
			backgroundColor: theme.colors.map.background.information,
			padding: theme.dimensions.indent / 4,
			border: "solid 1px black",
		},
		mapContainer: {
			height: window.innerHeight - 70,
			margin: 20,
		},
		label: {
			margin: 0,
		},
		markerLabel: {
			fontSize: theme.fontSizes.xbig,
			fontFamily: theme.fonts.bold,
			marginBottom: 15,
			backgroundColor: "#f0d169",
			width: 20,
		},
	}),
);

const getRegion = (yard) => {
	const latitude = yard.vertices.reduce(
		(latitudes, vertice) => [...latitudes, vertice.latitude],
		[],
	);
	const longitude = yard.vertices.reduce(
		(longitudes, vertice) => [...longitudes, vertice.longitude],
		[],
	);
	const minLat = Math.min(...latitude);
	const maxLat = Math.max(...latitude);
	const minLng = Math.min(...longitude);
	const maxLng = Math.max(...longitude);
	return { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 };
};

const MapComponent = ({ yard, drops, mapLoaded }) => {
	const styles = useStyles();
	const mapRef = React.useRef();

	const center = getRegion(yard);

	return center ? (
		<div className={styles.container}>
			{/* Map of selected clients yards */}
			<GoogleMap
				onTilesLoaded={mapLoaded}
				initialCenter={center}
				mapContainerStyle={{ height: "100%" }}
				center={center}
				ref={mapRef}
				zoom={16}
				mapTypeId="hybrid"
				onLoad={(map) => {
					let bounds = new window.google.maps.LatLngBounds();
					yard.vertices.forEach((vertice) =>
						bounds.extend({
							lat: vertice.latitude,
							lng: vertice.longitude,
						}),
					);
					map.fitBounds(bounds);
				}}
			>
				<>
					{drops.map(
						(drop) =>
							drop && (
								<Marker
									key={drop.id}
									name="Current location"
									icon={PinIcon}
									position={{
										lat: drop.coordinate.latitude,
										lng: drop.coordinate.longitude,
									}}
									label={{
										text: `${drop.numberOfBeehives}`,
										color: "black",
										className: styles.markerLabel,
									}}
								></Marker>
							),
					)}
					<Polygon
						key={yard.id}
						path={yard.vertices.map((el) => ({
							lat: el.latitude,
							lng: el.longitude,
						}))}
						options={{
							strokeColor: theme.colors.backgrounds.darkWhite,
							fillColor: theme.colors.backgrounds.darkWhite,
						}}
					/>
				</>
			</GoogleMap>
		</div>
	) : (
		<></>
	);
};

MapComponent.propTypes = {
	yard: PropTypes.object.isRequired,
	mapLoaded: PropTypes.func.isRequired,
	drops: PropTypes.array.isRequired,
};

export default MapComponent;
