const columns = [
	{
		id: "drop",
		numeric: false,
		disablePadding: false,
	},
	{
		id: "numberOfBeehives",
		numeric: true,
		disablePadding: false,
	},
	{
		id: "fob",
		numeric: true,
		disablePadding: false,
	},
];

export default columns;
