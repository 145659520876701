import {
	Button,
	Grid,
	Paper,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Print from "@material-ui/icons/Print";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useLocal } from "../../../contexts/LocalContextProvider";
import paths from "../../paths";

const useStyles = makeStyles((theme) =>
	createStyles({
		padding: {
			padding: theme.dimensions.indent,
		},
		pageTitle: {
			fontFamily: theme.fonts.xBold,
			fontSize: theme.fontSizes.large,
		},
		searchBar: {
			width: "100%",
		},
	}),
);

const Header = ({ search, updateSearch, newClient }) => {
	const styles = useStyles();

	const navigate = useNavigate();
	const { selected } = useLocal();
	const theme = useTheme();
	const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<Paper className={styles.padding} elevation={0}>
			<Grid
				item
				container
				spacing={2}
				wrap={xsScreen ? "wrap-reverse" : "nowrap"}
			>
				<Grid item sm={3} xs={3}>
					<Typography className={styles.pageTitle}>
						{i18n.t("Clients.title")}
					</Typography>
				</Grid>
				<Grid item sm={6} xs={9}>
					<TextField
						size="small"
						variant="outlined"
						value={search}
						onChange={(e) => updateSearch(e.target.value)}
						className={styles.searchBar}
						label={i18n.t("Clients.searchClient")}
						color="secondary"
					/>
				</Grid>
				<Grid
					item
					container
					sm={4}
					spacing={xsScreen ? 0 : 1}
					xs={12}
					direction="row"
					justifyContent="flex-end"
					alignContent="flex-start"
				>
					<Grid item>
						<Button variant="outlined" onClick={newClient} size="small">
							<AddIcon color="action" />
							<Typography variant="button">
								{i18n.t("Clients.addClient")}
							</Typography>
						</Button>
					</Grid>
					{!xsScreen && (
						<Grid item>
							<Button
								disabled={!selected}
								size="small"
								variant="outlined"
								onClick={() => {
									navigate(paths.mapsToPrint.baseUrl);
								}}
							>
								<Print color="action" />
								<Typography variant="button">
									{i18n.t("Header.pages.mapsToPrint")}
								</Typography>
							</Button>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Paper>
	);
};

Header.propTypes = {
	search: PropTypes.string.isRequired,
	updateSearch: PropTypes.func.isRequired,
	newClient: PropTypes.func.isRequired,
};

export default Header;
