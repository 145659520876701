import PropTypes from "prop-types";
import * as React from "react";
import YardsHeatMap from "../../../../../components/DropEditMap/components/YardsHeatMap";
import { useLocal } from "../../../../../contexts/LocalContextProvider";
import Map from "./Map";

/**
 * YardsMap page component
 *
 * This page display all orchard of user on a map
 */
const YardsMap = ({ yards, selectedYardIds, onClickOnYard }) => {
	const { heatmap } = useLocal();
	const [currentZoom, setCurrentZoom] = React.useState(null);

	// Mapping yards with selected id and drops
	const selectedYards = yards.filter(
		(y) => selectedYardIds.indexOf(y.id) > -1 && y.drops.length > 0,
	);

	const onZoomChanged = (z) => {
		setCurrentZoom(z);
	};

	const yardHeatMap = () => [
		<YardsHeatMap key={0} yards={selectedYards} zoom={currentZoom} />,
	];

	return (
		<Map
			yards={yards}
			selectedYardIds={selectedYardIds}
			onClickOnYard={onClickOnYard}
			onZoomChanged={onZoomChanged}
		>
			{selectedYards.length > 0 &&
			heatmap !== "nope" &&
			selectedYards[0].drops.length > 0
				? yardHeatMap()
				: null}
		</Map>
	);
};

YardsMap.propTypes = {
	yards: PropTypes.array.isRequired,
	selectedYardIds: PropTypes.array.isRequired,
	onClickOnYard: PropTypes.func.isRequired,
};

export default YardsMap;
