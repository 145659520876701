import PropTypes from "prop-types";
import * as React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/semantic-ui.css";

const PhoneTextField = ({ value, onChange, containerClass, inputClass }) => {
	const formatPhone = (text) => {
		const part1 = text.substring(1, 4);
		const part2 = text.substring(4, 7);
		const part3 = text.substring(7, 11);
		const result =
			"+1" +
			(part1 ? " (" + part1 + ")" : "") +
			(part2 ? " " + part2 : "") +
			(part3 ? "-" + part3 : "");
		return result;
	};

	return (
		<PhoneInput
			country={"us"}
			disableDropdown
			value={value}
			onChange={(text) => onChange(formatPhone(text))}
			containerClass={containerClass}
			inputClass={inputClass}
		/>
	);
};

PhoneTextField.propTypes = {
	value: PropTypes.string,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	containerClass: PropTypes.string,
	inputClass: PropTypes.string,
};

export default PhoneTextField;
