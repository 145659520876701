import { GoogleMap, Polygon } from "@react-google-maps/api";
import PropTypes from "prop-types";
import * as React from "react";
import GM_OPTIONS from "../../../../../../constants/googlemapsOptions";
import { useLocal } from "../../../../../../contexts/LocalContextProvider";
import theme from "../../../../../../theme/theme";

const getRegion = (yards) => {
	if (yards.length === 0) {
		return null;
	}

	const latitude = yards
		.reduce((vertices, yard) => [...vertices, ...yard.vertices], [])
		.reduce((latitudes, vertice) => [...latitudes, vertice.latitude], []);
	const longitude = yards
		.reduce((vertices, yard) => [...vertices, ...yard.vertices], [])
		.reduce((longitudes, vertice) => [...longitudes, vertice.longitude], []);
	const minLat = Math.min(...latitude);
	const maxLat = Math.max(...latitude);
	const minLng = Math.min(...longitude);
	const maxLng = Math.max(...longitude);
	return { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 };
};

const MapComponent = ({
	children,
	yards,
	selectedYardIds,
	onClickOnYard,
	onZoomChanged,
}) => {
	const mapRef = React.useRef();

	const [center, setCenter] = React.useState(null);
	const [map, setMap] = React.useState();
	const [zoom, setZoom] = React.useState(14);
	const { heatmap } = useLocal();

	React.useEffect(() => {
		const yardsSelected = yards.reduce((selectedYards, yard) => {
			const isSelected = selectedYardIds.reduce(
				(isSelected, id) => isSelected || yard.id === id,
				false,
			);
			if (isSelected) {
				return [...selectedYards, yard];
			} else {
				return selectedYards;
			}
		}, []);
		setCenter(getRegion(selectedYardIds.length > 0 ? yardsSelected : yards));
	}, [yards, selectedYardIds, map]);

	const loadHandler = (map) => {
		// save map to use it in onZoomChanged()
		setMap(map);
		zoomHandler(map);
	};

	const zoomHandler = React.useCallback(() => {
		if (map) {
			setZoom(map.getZoom());
			onZoomChanged(map.getZoom());
		}
	}, [map, onZoomChanged]);

	React.useEffect(() => {
		zoomHandler();
	}, [heatmap, zoomHandler]);

	return (
		<GoogleMap
			mapContainerStyle={{ height: "100%" }}
			center={center}
			ref={mapRef}
			zoom={zoom}
			onZoomChanged={zoomHandler}
			onLoad={loadHandler}
			options={GM_OPTIONS}
		>
			{children}
			{yards.map((yard) => {
				const isSelected = selectedYardIds.reduce(
					(isSelected, id) => isSelected || yard.id === id,
					false,
				);

				return (
					<React.Fragment key={yard.id}>
						<Polygon
							path={yard.vertices.map((el) => ({
								lat: el.latitude,
								lng: el.longitude,
							}))}
							options={{
								strokeColor: isSelected
									? theme.colors.backgrounds.yellow
									: theme.colors.backgrounds.darkWhite,
								fillColor: isSelected
									? theme.colors.backgrounds.yellow
									: theme.colors.backgrounds.darkWhite,
							}}
							onClick={() => onClickOnYard(yard)}
						/>
					</React.Fragment>
				);
			})}
		</GoogleMap>
	);
};

MapComponent.propTypes = {
	yards: PropTypes.array.isRequired,
	selectedYardIds: PropTypes.array.isRequired,
	onClickOnYard: PropTypes.func.isRequired,
	children: PropTypes.array,
	onZoomChanged: PropTypes.func.isRequired,
};

export default MapComponent;
