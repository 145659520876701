import { Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PropTypes from "prop-types";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import paths from "../../../App/paths";

const useStyles = makeStyles((theme) =>
	createStyles({
		[`@media print`]: {
			hiddenPrint: { display: "none" },
		},
	}),
);

const MetricsTitle = ({ yardName, customer }) => {
	const styles = useStyles();

	const navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<>
			{pathname.indexOf("maps-to-print") > -1 ? (
				<Button
					variant="outlined"
					className={styles.button}
					endIcon={
						<ArrowForwardIcon color="action" className={styles.hiddenPrint} />
					}
					onClick={() => {
						navigate(
							paths.clients.baseUrl +
								paths.clients.getById.baseUrl.replace(":clientId", customer.id),
						);
					}}
				>
					<Typography variant="subtitle1" align="left">
						{customer.name}
					</Typography>
				</Button>
			) : (
				<Typography variant="subtitle1" align="left">
					{customer.name}
				</Typography>
			)}
			<Typography variant="subtitle1" align="left">
				{yardName}
			</Typography>
		</>
	);
};

MetricsTitle.propTypes = {
	yardName: PropTypes.string.isRequired,
	customer: PropTypes.object.isRequired,
};
export default MetricsTitle;
