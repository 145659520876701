import PropTypes from "prop-types";
import * as React from "react";
import AccountContextProvider from "./AccountContextProvider";
import ApiContextProvider from "./ApiContextProvider";
import LocalContextProvider from "./LocalContextProvider";
import ModalContextProvider from "./ModalContextProvider";
import NotificationContextProvider from "./NotificationContextProvider";

const RootContextProvider = ({ children }) => (
	<NotificationContextProvider>
		<ApiContextProvider>
			<LocalContextProvider>
				<ModalContextProvider>
					<AccountContextProvider>{children}</AccountContextProvider>
				</ModalContextProvider>
			</LocalContextProvider>
		</ApiContextProvider>
	</NotificationContextProvider>
);

RootContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default RootContextProvider;
