import { Grid, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
	GoogleMap,
	InfoWindow,
	Marker,
	Polygon,
	StandaloneSearchBox,
} from "@react-google-maps/api";
import i18n from "i18next";

import PropTypes from "prop-types";
import * as React from "react";
import PinIcon from "../../../../../../images/pin.svg";
import theme from "../../../../../../theme/theme";

const fresno = {
	lat: 36.7378,
	lng: -119.7871,
};

const useStyles = makeStyles((theme) =>
	createStyles({
		map: {
			height: "60vh",
			width: "100%",
		},
		padding: {
			paddingBlock: theme.dimensions.indent,
		},
	}),
);
const refs = {};
const MapComponent = ({
	yard,
	onPressOnMap,
	onMarkerDragEnd,
	removeVertice,
}) => {
	const styles = useStyles();
	const mapRef = React.useRef();

	const [selectedPin, setSelectedPin] = React.useState(undefined);

	const [currentLocation, setCurrentLocation] = React.useState(fresno);

	const [places, setPlaces] = React.useState([]);
	const [map, setMap] = React.useState();

	const [value, setValue] = React.useState("");
	const onSearchBoxMounted = (ref) => {
		refs.searchBox = ref;
	};

	const onSelectPlace = (e, place) => {
		const bounds = new window.google.maps.LatLngBounds();

		if (place) {
			setCurrentLocation(place.geometry.location);
			setValue("");
			if (place.geometry.viewport) {
				bounds.union(place.geometry.viewport);
			} else {
				bounds.extend(place.geometry.location);
			}
			map.fitBounds(bounds);
		} else setPlaces([]);
	};
	const onPlacesChanged = () => {
		let places = refs.searchBox.getPlaces();
		setPlaces(
			places.map((p) => ({
				label: p.formatted_address,
				geometry: p.geometry,
			})),
		);
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<div id="searchbox">
					<StandaloneSearchBox
						onPlacesChanged={onPlacesChanged}
						onLoad={onSearchBoxMounted}
					>
						<Autocomplete
							autoHighlight
							options={places}
							renderInput={(props, option) => (
								<TextField
									{...props}
									label={i18n.t(
										"Client.Yards.YardCreateMap.modal.searchLocation",
									)}
									placeholder={i18n.t(
										"Client.Yards.YardCreateMap.modal.searchLocation",
									)}
									variant="outlined"
									value={value}
									size="small"
								/>
							)}
							onChange={onSelectPlace}
							getOptionLabel={(option) => option.label}
							noOptionsText={i18n.t(
								"Client.Yards.YardCreateMap.modal.noAddressAvailable",
							)}
						/>
					</StandaloneSearchBox>
				</div>
				<Grid item className={styles.padding}>
					<Typography variant="subtitle2">
						{i18n.t("Client.Yards.YardCreateMap.modal.placePins")}
					</Typography>
				</Grid>
				<Grid item className={styles.map}>
					<GoogleMap
						initialCenter={fresno}
						center={currentLocation}
						ref={mapRef}
						zoom={16}
						mapTypeId="hybrid"
						onClick={(e) => {
							onPressOnMap(e);
							setSelectedPin();
						}}
						mapContainerStyle={{ height: "100%" }}
						onLoad={(map) => {
							setMap(map);
							navigator?.geolocation.getCurrentPosition(
								({ coords: { latitude: lat, longitude: lng } }) => {
									const pos = { lat, lng };
									setCurrentLocation(pos);
								},
							);
						}}
					>
						{yard.vertices &&
							yard.vertices.map((vertex, idx) => (
								<Marker
									name="Current location"
									key={idx}
									icon={PinIcon}
									position={{
										lat: vertex.latitude,
										lng: vertex.longitude,
									}}
									draggable
									onClick={() =>
										setSelectedPin((n) => (idx !== n ? idx : undefined))
									}
									onDragEnd={(event) => onMarkerDragEnd(event, idx)}
								>
									{selectedPin === idx && (
										<InfoWindow>
											<IconButton
												onClick={() => {
													removeVertice(idx);
													setSelectedPin();
												}}
											>
												<DeleteIcon />
											</IconButton>
										</InfoWindow>
									)}
								</Marker>
							))}
						<Polygon
							path={yard.vertices.map((el) => ({
								lat: el.latitude,
								lng: el.longitude,
							}))}
							options={{
								strokeColor: theme.colors.backgrounds.yellow,
								fillColor: theme.colors.backgrounds.yellow,
							}}
						/>
					</GoogleMap>
				</Grid>
			</Grid>
		</Grid>
	);
};

MapComponent.propTypes = {
	yard: PropTypes.object.isRequired,
	onPressOnMap: PropTypes.func.isRequired,
	onMarkerDragEnd: PropTypes.func.isRequired,
	removeVertice: PropTypes.func.isRequired,
};

export default MapComponent;
