/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Details from './Details';
import ProductAvailable from './ProductAvailable';

/**
* The ProductWithDetails model module.
* @module model/ProductWithDetails
* @version 0.0.1
*/
export default class ProductWithDetails {
    /**
    * Constructs a new <code>ProductWithDetails</code>.
    * @alias module:model/ProductWithDetails
    * @class
    * @extends module:model/ProductAvailable
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ProductWithDetails</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ProductWithDetails} obj Optional instance to populate.
    * @return {module:model/ProductWithDetails} The populated <code>ProductWithDetails</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductWithDetails();
            
            ProductAvailable.constructFromObject(data, obj);
            
            if (data.hasOwnProperty('details')) {
                obj['details'] = ApiClient.convertToType(data['details'], [Details]);
            }
        }
        return obj;
    }

    /**
    * @member {Array.<module:model/Details>} details
    */
    details = undefined;




}
