const paths = {
	home: {
		baseUrl: "/",
	},
	clients: {
		baseUrl: "/clients",
		getById: {
			baseUrl: "/:clientId",
			allYards: "/all-yards",
		},
	},
	mapsToPrint: {
		baseUrl: "/maps-to-print",
		getById: {
			baseUrl: "/:clientId",
		},
	},
};

export default paths;
