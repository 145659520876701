import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useApi } from "../../../../contexts/ApiContextProvider";
import { useLocal } from "../../../../contexts/LocalContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import Details from "./Details";
import EditForm from "./EditForm";

const useStyles = makeStyles((theme) => ({
	container: {
		margin: theme.dimensions.indent / 4,
	},
	client: {
		paddingInline: theme.dimensions.indent,
		paddingBlock: theme.dimensions.indent / 2,
	},
}));

const ClientDetails = ({ clientId }) => {
	const styles = useStyles();
	const [isEditMode, setIsEditMode] = React.useState(false);

	const { api } = useApi();
	const { notifError } = useNotification();
	const { client, setClient } = useLocal();

	const openEditMode = () => setIsEditMode(true);
	const closeEditMode = () => setIsEditMode(false);

	React.useEffect(() => {
		api
			.backofficeGrowersCustomersIdGet(clientId)
			.then((client) => setClient(client))
			.catch(() =>
				notifError("Client.EditCustomerForm.events.errors.getCustomer"),
			);
	}, [api, clientId, notifError, setClient]);

	return (
		client && (
			<Paper elevation={2} className={styles.container}>
				<Grid container className={styles.client} spacing={isEditMode ? 2 : 0}>
					{isEditMode ? (
						<EditForm
							client={client}
							closeEditMode={closeEditMode}
							openEditMode={openEditMode}
							setClient={setClient}
						/>
					) : (
						<Details
							client={client}
							closeEditMode={closeEditMode}
							openEditMode={openEditMode}
						/>
					)}
				</Grid>
			</Paper>
		)
	);
};

ClientDetails.propTypes = {
	clientId: PropTypes.string.isRequired,
};

export default ClientDetails;
