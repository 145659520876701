/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Product from './Product';

/**
* The ProductBought model module.
* @module model/ProductBought
* @version 0.0.1
*/
export default class ProductBought {
    /**
    * Constructs a new <code>ProductBought</code>.
    * @alias module:model/ProductBought
    * @class
    * @extends module:model/Product
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ProductBought</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ProductBought} obj Optional instance to populate.
    * @return {module:model/ProductBought} The populated <code>ProductBought</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductBought();
            
            Product.constructFromObject(data, obj);
            
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
        }
        return obj;
    }

    /**
    * Quantity bought by customer
    * @member {Number} quantity
    */
    quantity = undefined;




}
