import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import Header from "../components/Header";
import theme from "../theme/theme";
import Router from "./Router";

/**

 * This component is the main wrapper for the rest of the application
 */
const App = () => {
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		libraries: ["visualization", "places"],
	});

	return isLoaded ? (
		<ThemeProvider theme={theme}>
			<Router>
				<Header />
				<CssBaseline />
			</Router>
		</ThemeProvider>
	) : (
		<></>
	);
};

export default App;
