const cropsParameters = {
	almonds: [
		{ radius: 70, opacity: 0.6 },
		{ radius: 150, opacity: 0.4 },
		{ radius: 200, opacity: 0.4 },
		{ radius: 300, opacity: 0.4 },
	],
	avocados: [
		{ radius: 1500, opacity: 0.6 },
		{ radius: 2500, opacity: 0.4 },
		{ radius: 3500, opacity: 0.4 },
		{ radius: 4000, opacity: 0.4 },
		{ radius: 5000, opacity: 0.4 },
	],
	coffee: [
		{ radius: 1500, opacity: 0.6 },
		{ radius: 2500, opacity: 0.4 },
		{ radius: 3500, opacity: 0.4 },
		{ radius: 4000, opacity: 0.4 },
		{ radius: 5000, opacity: 0.4 },
	],
};

export default cropsParameters;
