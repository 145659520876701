/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BasketMetadata from './BasketMetadata';
import Company from './Company';
import Contract from './Contract';
import Discount from './Discount';
import Option from './Option';
import Payment from './Payment';
import ProductBought from './ProductBought';

/**
* The Order model module.
* @module model/Order
* @version 0.0.1
*/
export default class Order {
    /**
    * Constructs a new <code>Order</code>.
    * @alias module:model/Order
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Order</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Order} obj Optional instance to populate.
    * @return {module:model/Order} The populated <code>Order</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Order();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
            }
            if (data.hasOwnProperty('products')) {
                obj['products'] = ApiClient.convertToType(data['products'], [ProductBought]);
            }
            if (data.hasOwnProperty('options')) {
                obj['options'] = ApiClient.convertToType(data['options'], [Option]);
            }
            if (data.hasOwnProperty('discounts')) {
                obj['discounts'] = ApiClient.convertToType(data['discounts'], [Discount]);
            }
            if (data.hasOwnProperty('contract')) {
                obj['contract'] = Contract.constructFromObject(data['contract']);
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = Company.constructFromObject(data['company']);
            }
            if (data.hasOwnProperty('payment')) {
                obj['payment'] = Payment.constructFromObject(data['payment']);
            }
            if (data.hasOwnProperty('dateOfBooking')) {
                obj['dateOfBooking'] = ApiClient.convertToType(data['dateOfBooking'], 'String');
            }
            if (data.hasOwnProperty('dateOfRejection')) {
                obj['dateOfRejection'] = ApiClient.convertToType(data['dateOfRejection'], 'String');
            }
            if (data.hasOwnProperty('dateOfCompanySelection')) {
                obj['dateOfCompanySelection'] = ApiClient.convertToType(data['dateOfCompanySelection'], 'String');
            }
            if (data.hasOwnProperty('dateOfContractSignature')) {
                obj['dateOfContractSignature'] = ApiClient.convertToType(data['dateOfContractSignature'], 'String');
            }
            if (data.hasOwnProperty('dateOfPaymentInitialization')) {
                obj['dateOfPaymentInitialization'] = ApiClient.convertToType(data['dateOfPaymentInitialization'], 'String');
            }
            if (data.hasOwnProperty('dateOfPaymentConfirmation')) {
                obj['dateOfPaymentConfirmation'] = ApiClient.convertToType(data['dateOfPaymentConfirmation'], 'String');
            }
            if (data.hasOwnProperty('metadata')) {
                obj['metadata'] = BasketMetadata.constructFromObject(data['metadata']);
            }
        }
        return obj;
    }

    /**
    * Id of order
    * @member {String} id
    */
    id = undefined;
    /**
    * Customer id linked to the order
    * @member {String} customerId
    */
    customerId = undefined;
    /**
    * @member {Array.<module:model/ProductBought>} products
    */
    products = undefined;
    /**
    * @member {Array.<module:model/Option>} options
    */
    options = undefined;
    /**
    * @member {Array.<module:model/Discount>} discounts
    */
    discounts = undefined;
    /**
    * @member {module:model/Contract} contract
    */
    contract = undefined;
    /**
    * @member {module:model/Company} company
    */
    company = undefined;
    /**
    * @member {module:model/Payment} payment
    */
    payment = undefined;
    /**
    * Date at which the customer pressed the \"secure my hives\" button
    * @member {String} dateOfBooking
    */
    dateOfBooking = undefined;
    /**
    * Date at which the order was rejected. The order is considered rejected ONLY IF THIS DATE IS SET
    * @member {String} dateOfRejection
    */
    dateOfRejection = undefined;
    /**
    * Date at which the customer selected the company
    * @member {String} dateOfCompanySelection
    */
    dateOfCompanySelection = undefined;
    /**
    * Date at which the customer signed his contract
    * @member {String} dateOfContractSignature
    */
    dateOfContractSignature = undefined;
    /**
    * Date at which the customer initialized the payment
    * @member {String} dateOfPaymentInitialization
    */
    dateOfPaymentInitialization = undefined;
    /**
    * Date at which stripe or Ubees confirmed the payment was successful
    * @member {String} dateOfPaymentConfirmation
    */
    dateOfPaymentConfirmation = undefined;
    /**
    * @member {module:model/BasketMetadata} metadata
    */
    metadata = undefined;




}
