/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BeekeeperWithCustomerInformation from './BeekeeperWithCustomerInformation';
import ProductProposed from './ProductProposed';
import ProductProposedUpdate from './ProductProposedUpdate';

/**
* The ProductProposedWithCustomer model module.
* @module model/ProductProposedWithCustomer
* @version 0.0.1
*/
export default class ProductProposedWithCustomer {
    /**
    * Constructs a new <code>ProductProposedWithCustomer</code>.
    * @alias module:model/ProductProposedWithCustomer
    * @class
    * @extends module:model/ProductProposed
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ProductProposedWithCustomer</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ProductProposedWithCustomer} obj Optional instance to populate.
    * @return {module:model/ProductProposedWithCustomer} The populated <code>ProductProposedWithCustomer</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductProposedWithCustomer();
            
            ProductProposed.constructFromObject(data, obj);
            
            if (data.hasOwnProperty('customer')) {
                obj['customer'] = BeekeeperWithCustomerInformation.constructFromObject(data['customer']);
            }
        }
        return obj;
    }

    /**
    * @member {module:model/BeekeeperWithCustomerInformation} customer
    */
    customer = undefined;




}
