import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import PrintButton from "../../../components/PrintButton";
import { useApi } from "../../../contexts/ApiContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import YardMaps from "./YardsMaps";

const useStyles = makeStyles((theme) =>
	createStyles({
		center: {
			textAlign: "center",
		},
	}),
);
const YardsPaper = ({ clientId }) => {
	const styles = useStyles();
	const { api } = useApi();
	const { notifError } = useNotification();

	const [isLoaded, setIsLoaded] = React.useState(false);

	const [customer, setCustomer] = React.useState();
	const [yards, setYards] = React.useState([]);

	const refreshData = React.useCallback(() => {
		Promise.all([
			new Promise((resolve, reject) =>
				api
					.backofficeGrowersCustomersIdGet(clientId)
					.then(setCustomer)
					.then(resolve)
					.catch(reject),
			),
			new Promise((resolve, reject) =>
				api
					.backofficeGrowersYardsOfCustomerCustomerIdGet(clientId)
					.then(setYards)
					.then(resolve)
					.catch(reject),
			),
		])
			.then(() => setIsLoaded(true))
			.catch(() => notifError("Client.Yards.events.errors.getYards"));
	}, [api, clientId, notifError]);

	React.useEffect(() => {
		if (!isLoaded) {
			refreshData();
		}
	}, [refreshData, isLoaded]);

	return (
		<Grid
			container
			alignContent="flex-start"
			className={styles.center}
			spacing={4}
		>
			<Grid item container xs={12}>
				<Grid item xs={2}>
					<PrintButton />
				</Grid>
			</Grid>
			<Grid item container xs={12}>
				{customer && (
					<YardMaps
						yards={yards}
						dropMetricsAreVisible={true}
						customer={customer}
					/>
				)}
			</Grid>
		</Grid>
	);
};

YardsPaper.propTypes = {
	clientId: PropTypes.string.isRequired,
};

export default YardsPaper;
