import * as React from "react";
import { useParams } from "react-router-dom";
import YardsPaper from "./components/YardsPaper";

/**
 * ClientYards of the client for Nic to print
 */
const Yards = () => {
	const { clientId } = useParams();

	return <YardsPaper clientId={clientId} />;
};

export default Yards;
