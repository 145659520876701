import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import metrics from "../../../utils/metrics";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			padding: theme.dimensions.indent / 2,
			width: "100%",
		},
		title: {
			textAlign: "center",
			fontFamily: theme.fonts.xBold,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.large,
			margin: 0,
		},
		metrics: {
			textAlign: "center",
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.xBold,
			color: theme.colors.texts.primary,
		},
	}),
);

const YardInfo = ({ yard, drops }) => {
	const styles = useStyles();
	const [numberOfDrops, setNumberOfDrops] = React.useState();
	const [numberOfBeehives, setNumberOfBeehives] = React.useState();
	const [fobAverage, setFobAverage] = React.useState();
	const [hivesPerAcre, setHivesPerAcre] = React.useState();

	React.useEffect(() => {
		if (yard && drops) {
			const hivesPerAcre =
				yard.size > 0 ? metrics.hivesPerAcre(yard.size, drops) : "-";
			setNumberOfDrops(drops.length);
			setNumberOfBeehives(metrics.numberOfBeehives(drops));
			setFobAverage(metrics.fobAverage(drops));
			setHivesPerAcre(hivesPerAcre);
		}
	}, [yard, drops]);

	return (
		<Grid container spacing={1}>
			<Grid item container spacing={2}>
				{yard && (
					<Grid item xs={12}>
						<Typography className={styles.title}>{yard.name}</Typography>
					</Grid>
				)}
				<Grid item container justifyContent="center" alignItems="center">
					{numberOfDrops !== undefined && (
						<Typography className={styles.metrics}>
							{i18n.t("components.DropEditMap.YardInfo.drops", {
								count: numberOfDrops,
							})}
						</Typography>
					)}
					<FiberManualRecordIcon color="secondary" fontSize="small" />
					{numberOfBeehives !== undefined && (
						<Typography className={styles.metrics} display="inline">
							{i18n.t("components.DropEditMap.YardInfo.hives", {
								count: numberOfBeehives,
							})}
						</Typography>
					)}
				</Grid>
			</Grid>
			<Grid item container justifyContent="center" alignItems="center">
				{hivesPerAcre !== undefined && (
					<Typography className={styles.metrics}>
						{i18n.t("components.DropEditMap.YardInfo.hivesPerAcre", {
							hives: hivesPerAcre,
						})}
					</Typography>
				)}
				<FiberManualRecordIcon color="secondary" fontSize="small" />
				{fobAverage !== undefined && (
					<Typography className={styles.metrics}>
						{i18n.t("components.DropEditMap.YardInfo.fobAverage", {
							fob: fobAverage,
						})}
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};

YardInfo.propTypes = {
	yard: PropTypes.object.isRequired,
	drops: PropTypes.array.isRequired,
};

export default YardInfo;
