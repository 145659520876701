import { Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		[`@media print`]: {
			printButton: { display: "none" },
		},
		printButton: {
			backgroundColor: theme.colors.button.secondary,
			color: theme.colors.texts.primary,
			marginLeft: "auto",
			right: 24,
			position: "fixed",
			top: 60,
		},
	}),
);
const PrintButton = ({ disabled, children }) => {
	const styles = useStyles();

	return (
		<Button
			disabled={disabled}
			size="large"
			variant="contained"
			onClick={() => {
				window.print();
			}}
			className={styles.printButton}
		>
			{children}
			{i18n.t("MapsToPrint.print")}
		</Button>
	);
};

PrintButton.propTypes = {
	children: PropTypes.object,
	disabled: PropTypes.bool,
};
export default PrintButton;
