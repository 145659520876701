import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PollinationModal from "../../../../components/PollinationModal";
import { useApi } from "../../../../contexts/ApiContextProvider";
import { useLocal } from "../../../../contexts/LocalContextProvider";
import { useModal } from "../../../../contexts/ModalContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import paths from "../../../paths";
import YardActions from "./YardActions";
import YardsMap from "./YardsMap";
import YardsModalContent from "./YardsModalContent/YardsModalContent";
import YardsTable from "./YardsTable";

const useStyles = makeStyles((theme) =>
	createStyles({
		header: {
			padding: theme.dimensions.indent,
		},
		disabledIcon: {
			color: theme.colors.button.disabled,
		},
		pageTitle: {
			fontFamily: theme.fonts.xBold,
			fontSize: theme.fontSizes.large,
		},
	}),
);

const ClientYards = ({ clientId }) => {
	const styles = useStyles();

	const navigate = useNavigate();
	const { api } = useApi();

	const { client, setHeatmap } = useLocal();
	const { notifSuccess, notifError } = useNotification();
	const { openModal, setModalType } = useModal();

	const [isLoaded, setIsLoaded] = React.useState(false);
	const [yards, setYards] = React.useState([]);
	const [selectedYardIds, setSelectedYardIds] = React.useState([]);

	const refreshData = React.useCallback(
		() =>
			api
				.backofficeGrowersYardsOfCustomerCustomerIdGet(clientId)
				.then((yards) => {
					// Need numberOfDrops for sorting table by drops
					const mappedYards = yards.map((yard) => {
						return { ...yard, numberOfDrops: yard.drops.length };
					});
					setYards(mappedYards);
				})
				.then(() => setIsLoaded(true))
				.catch(() => notifError("Client.Yards.events.errors.getYards")),
		[api, clientId, notifError],
	);

	React.useEffect(() => {
		if (!isLoaded) {
			refreshData();
		}
	}, [refreshData, isLoaded]);

	const getYardDrops = React.useCallback(
		(currentYard) =>
			api
				.backofficeGrowersYardsIdDropsGet(currentYard.id)
				.then((drops) => {
					// Update drops list with index
					setYards((yards) =>
						yards.map((yard) =>
							currentYard.id === yard.id
								? {
										...yard,
										drops: drops.map((drop, index) => {
											return {
												...drop,
												index: index + 1,
											};
										}),
								  }
								: yard,
						),
					);
				})
				.catch(() => notifError("Client.Yards.events.errors.getDrops")),
		[api, notifError],
	);

	const createYard = ({ yard, onCreateCallback }) =>
		api
			.backofficeGrowersYardsPost({
				...yard,
				customerId: clientId,
				yardValidatedByGrower: new Date().getTime(),
				yardValidatedByUbees: new Date().getTime(),
			})
			.then(onCreateCallback)
			.then(refreshData)
			.then(() => notifSuccess("Client.Yards.events.success.addYard"))
			.catch(() => notifError("Client.Yards.events.errors.addYard"));

	const updateYard = ({ update, yardId }) =>
		api
			.backofficeGrowersYardsIdPatch(update, yardId)
			.then(() =>
				setYards((yards) =>
					yards.map((yard) =>
						yard.id === yardId ? { ...yard, ...update } : yard,
					),
				),
			)
			.then(() => notifSuccess("Client.Yards.events.success.updateYard"))
			.catch(() => notifError("Client.Yards.events.errors.updateYard"));

	const deleteYardsHandler = () => {
		Promise.all(
			selectedYardIds.map((id) => api.backofficeGrowersYardsIdDelete(id)),
		)
			.then(() => {
				setSelectedYardIds([]);
				refreshData();
				openModal(false);
				notifSuccess("Client.Yards.events.success.deleteYard");
			})
			.catch(() => notifError("Client.Yards.events.errors.deleteYard"));
	};

	const updateYardDrops = ({ yard, drops }) =>
		setYards((yards) =>
			yards.map((y) => (y.id === yard.id ? { ...y, drops } : y)),
		);

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = yards.map((n) => n.id);
			setSelectedYardIds(newSelecteds);
		} else {
			setSelectedYardIds([]);
		}
	};

	const handleSelectOneClick = (id) =>
		setSelectedYardIds((ids) => {
			const selectedIndex = ids.indexOf(id);
			let newSelected = [];
			if (selectedIndex === -1) {
				newSelected = newSelected.concat(ids, id);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(ids.slice(1));
			} else if (selectedIndex === ids.length - 1) {
				newSelected = newSelected.concat(ids.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					ids.slice(0, selectedIndex),
					ids.slice(selectedIndex + 1),
				);
			}
			return newSelected;
		});

	React.useEffect(() => {
		for (let id of selectedYardIds) {
			const yard = yards.find((y) => id === y.id);
			if (yard && yard.drops.length > 0 && typeof yard.drops[0] === "string") {
				getYardDrops(yard);
			}
		}
	}, [selectedYardIds, getYardDrops, yards]);

	return (
		<Paper variant="elevation" square elevation={0} className={styles.header}>
			<Grid container spacing={2}>
				<Grid item xs={7}>
					<Typography className={styles.pageTitle}>
						{i18n.t("Client.Yards.title")}
					</Typography>
				</Grid>
				<Grid item spacing={1} container xs={5} justifyContent="flex-end">
					<Grid item>
						<Button
							variant="outlined"
							onClick={() => {
								setModalType("yardCreate");
								openModal(true, {
									title: i18n.t("Client.Yards.YardCreateMap.modal.title", {
										client: `${client.company || client.name}`,
									}),
									options: {
										fullScreen: true,
										maxWidth: "md",
									},
								});
							}}
							size="small"
						>
							<AddIcon fontSize="small" color="action" />
							<Typography variant="button">
								{i18n.t("Client.Yards.createYard")}
							</Typography>
						</Button>
					</Grid>
					<Grid item>
						<Button
							size="small"
							variant="outlined"
							onClick={() => {
								navigate(
									paths.clients.baseUrl +
										paths.clients.getById.baseUrl.replace(
											":clientId",
											clientId,
										) +
										paths.clients.getById.allYards,
								);
							}}
						>
							<LocalPrintshopIcon fontSize="small" color="action" />
							<Typography variant="button">
								{i18n.t("Client.Yards.printYards")}
							</Typography>
						</Button>
					</Grid>
				</Grid>
				<Grid item container spacing={1} alignItems="center">
					<YardActions
						yard={yards.find((y) => y.id === selectedYardIds[0])}
						selectedYardIds={selectedYardIds}
					/>
					<Grid item>
						<Tooltip title={i18n.t("Client.Yards.tooltips.deleteYard")}>
							<span>
								<IconButton
									onClick={() => {
										setModalType("deleteYards");
										openModal(true, {
											title: i18n.t(
												"components.DeleteYardsConfirmation.title",
												{
													count: selectedYardIds.length,
												},
											),
											options: { fullScreen: false, maxWidth: "md" },
										});
									}}
									disabled={selectedYardIds.length === 0}
								>
									<DeleteIcon
										className={
											selectedYardIds.length === 0 ? styles.disabledIcon : ""
										}
									/>
								</IconButton>
							</span>
						</Tooltip>
					</Grid>
				</Grid>
				{!!yards.length && (
					<Grid item container>
						<Grid item xs={7}>
							<YardsTable
								yards={yards}
								selectedYardIds={selectedYardIds}
								handleSelectAllClick={handleSelectAllClick}
								handleSelectOneClick={handleSelectOneClick}
								updateYard={updateYard}
								getYardDrops={getYardDrops}
							/>
						</Grid>
						<Grid item xs={5}>
							{/* Small map with yards */}
							<YardsMap
								yards={yards}
								selectedYardIds={selectedYardIds}
								onClickOnYard={(yard) => setSelectedYardIds([yard.id])}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
			<PollinationModal
				onClose={() => {
					setHeatmap("nope");
				}}
			>
				<YardsModalContent
					createYard={createYard}
					yards={yards}
					updateYard={updateYard}
					selectedYardIds={selectedYardIds}
					updateYardDrops={updateYardDrops}
					getYardDrops={getYardDrops}
					setSelectedYardIds={setSelectedYardIds}
					deleteYardsHandler={deleteYardsHandler}
				/>
			</PollinationModal>
		</Paper>
	);
};

ClientYards.propTypes = {
	clientId: PropTypes.string.isRequired,
};

export default ClientYards;
