import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import ControlledPhoneTextField from "../../../../components/Fields/ControlledPhoneTextField";
import ControlledTextFieldOutlined from "../../../../components/Fields/ControlledTextFieldOutlined";
import { useApi } from "../../../../contexts/ApiContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import { usPhoneRegexp } from "../../../../utils/regExp";

const schema = Yup.object().shape({
	contact: Yup.string().min(
		1,
		i18n.t("Client.EditCustomerForm.errors.contact"),
	),
	phoneNumber: Yup.string()
		.matches(
			usPhoneRegexp,
			i18n.t("Client.EditCustomerForm.errors.phoneNumber"),
		)
		.min(1, i18n.t("Client.errors.phoneNumberRequired")),
	address: Yup.string().min(1, i18n.t("Client.errors.addressRequired")),
});

const fields = {
	contact: "contact",
	phoneNumber: "phoneNumber",
	address: "address",
	email: "email",
	company: "company",
};

const EditForm = ({ client, setClient, closeEditMode }) => {
	const { notifError, notifSuccess } = useNotification();
	const { api } = useApi();

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const updateProfile = (form) =>
		api
			.backofficeGrowersCustomersIdPatch(form, client.id)
			.then(() => {
				closeEditMode();
				setClient((c) => ({ ...c, form }));
				notifSuccess("Client.EditCustomerForm.events.success.patchProfile");
			})
			.catch(() =>
				notifError("Client.EditCustomerForm.events.errors.patchProfile"),
			);

	const onSubmit = handleSubmit(updateProfile);

	return (
		<>
			<Grid item container alignItems="center">
				<Grid item xs={11}>
					<Typography variant="subtitle1">
						{i18n.t("Clients.editClient")}
					</Typography>
				</Grid>
				<Grid item xs={1}>
					<IconButton onClick={closeEditMode}>
						<CloseIcon />
					</IconButton>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<ControlledTextFieldOutlined
					field={fields.contact}
					defaultValue={client?.name}
					label={i18n.t("Client.EditCustomerForm.labels.contact")}
					error={errors.contact}
					register={register}
					control={control}
				/>
			</Grid>
			<Grid item xs={12}>
				<ControlledPhoneTextField
					field={fields.phoneNumber}
					defaultValue={client?.phoneNumber}
					error={errors.phoneNumber}
					register={register}
					control={control}
				/>
			</Grid>
			<Grid item xs={12}>
				<ControlledTextFieldOutlined
					field={fields.address}
					defaultValue={client?.address}
					label={i18n.t("Client.EditCustomerForm.labels.address")}
					error={errors.address}
					register={register}
					control={control}
				/>
			</Grid>
			<Grid item xs={12}>
				<ControlledTextFieldOutlined
					field={fields.email}
					defaultValue={client?.email}
					label={i18n.t("Client.EditCustomerForm.labels.email")}
					error={errors.email}
					register={register}
					control={control}
				/>
			</Grid>
			<Grid item xs={12}>
				<ControlledTextFieldOutlined
					field={fields.company}
					defaultValue={client?.company}
					label={i18n.t("Client.EditCustomerForm.labels.company")}
					error={errors.company}
					register={register}
					control={control}
				/>
			</Grid>
			<Grid item container spacing={2} xs={6}>
				<Grid item xs={6}>
					<Button
						onClick={onSubmit}
						label={i18n.t("Client.EditCustomerForm.buttons.updateInfo")}
					/>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant="outlined"
						onClick={closeEditMode}
						label={i18n.t("Client.EditCustomerForm.buttons.cancel")}
					/>
				</Grid>
			</Grid>
		</>
	);
};

EditForm.propTypes = {
	client: PropTypes.object.isRequired,
	setClient: PropTypes.func.isRequired,
	closeEditMode: PropTypes.func.isRequired,
};

export default EditForm;
