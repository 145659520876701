/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Discount model module.
* @module model/Discount
* @version 0.0.1
*/
export default class Discount {
    /**
    * Constructs a new <code>Discount</code>.
    * @alias module:model/Discount
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Discount</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Discount} obj Optional instance to populate.
    * @return {module:model/Discount} The populated <code>Discount</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Discount();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('groupId')) {
                obj['groupId'] = ApiClient.convertToType(data['groupId'], 'String');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('percentage')) {
                obj['percentage'] = ApiClient.convertToType(data['percentage'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
        }
        return obj;
    }

    /**
    * Id of the \"discount product\". NOTE the discount applies to the beehives price, not the options
    * @member {String} id
    */
    id = undefined;
    /**
    * Id of the \"discount product\". NOTE the discount applies to the beehives price, not the options
    * @member {String} groupId
    */
    groupId = undefined;
    /**
    * Id of the product the discount applies to
    * @member {String} productId
    */
    productId = undefined;
    /**
    * The type says how we compute the price amountOnTotal: retrieve $5000 from the bill amountOnPercentage: retrieve `amount` for `percentage` of the beehives present in the bill amountPerBeehive: retrieve $5 per beehive from the bill percentage: multiply the total amount by 1-percentage/100 (round to the lowest cent) Enum of ['amountOnTotal', 'amountPerBeehive', 'percentage', 'amountOnPercentage'] 
    * @member {String} type
    */
    type = undefined;
    /**
    * Amount for the \"price\". See type for detail
    * @member {Number} amount
    */
    amount = undefined;
    /**
    * Percentage for the \"price\". See type for detail
    * @member {Number} percentage
    */
    percentage = undefined;
    /**
    * A title to explain what the product is about
    * @member {String} title
    */
    title = undefined;
    /**
    * A message to explain what the product is about
    * @member {String} message
    */
    message = undefined;




}
