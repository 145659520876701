/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The AccessToken model module.
* @module model/AccessToken
* @version 0.0.1
*/
export default class AccessToken {
    /**
    * Constructs a new <code>AccessToken</code>.
    * @alias module:model/AccessToken
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>AccessToken</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/AccessToken} obj Optional instance to populate.
    * @return {module:model/AccessToken} The populated <code>AccessToken</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccessToken();
                        
            
            if (data.hasOwnProperty('accessToken')) {
                obj['accessToken'] = ApiClient.convertToType(data['accessToken'], 'String');
            }
        }
        return obj;
    }

    /**
    * An access token of user
    * @member {String} accessToken
    */
    accessToken = undefined;




}
