/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Contact model module.
* @module model/Contact
* @version 0.0.1
*/
export default class Contact {
    /**
    * Constructs a new <code>Contact</code>.
    * @alias module:model/Contact
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Contact</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Contact} obj Optional instance to populate.
    * @return {module:model/Contact} The populated <code>Contact</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Contact();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('origin')) {
                obj['origin'] = ApiClient.convertToType(data['origin'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'String');
            }
            if (data.hasOwnProperty('called')) {
                obj['called'] = ApiClient.convertToType(data['called'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * Id of form
    * @member {String} id
    */
    id = undefined;
    /**
    * Origin of form derived from [\"beekeepers-form\", \"talk-to-sales\", \"simulator\", \"contact-customer-service\"]
    * @member {String} origin
    */
    origin = undefined;
    /**
    * Name of user
    * @member {String} name
    */
    name = undefined;
    /**
    * Phone of user
    * @member {String} phone
    */
    phone = undefined;
    /**
    * Email of user
    * @member {String} email
    */
    email = undefined;
    /**
    * Message of user
    * @member {String} message
    */
    message = undefined;
    /**
    * Date at which the user gave its details
    * @member {String} date
    */
    date = undefined;
    /**
    * Indicates whether the user has been contacted or not
    * @member {Boolean} called
    */
    called = undefined;




}
