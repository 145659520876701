import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import MetricsOnYard from "../../../components/MetricsOnYard";
import { useApi } from "../../../contexts/ApiContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import Map from "./Map";

/**
 * YardMaps page component
 *
 * This page display all orchard of user on a map
 */

const YardMaps = ({ yards, dropMetricsAreVisible, customer }) => {
	const { api } = useApi();
	const { notifError } = useNotification();
	const [drops, setDrops] = React.useState([]);

	React.useEffect(() => {
		if (customer)
			api
				.backofficeGrowersDropsOfCustomerCustomerIdGet(customer.id)
				.then((drops) => setDrops(drops))
				.catch(() => notifError("Client.Yards.events.errors.getDrops"));
	}, [customer, api, notifError]);

	return yards.map((yard) => {
		const _drops = yard.drops.map((id) => drops.find((d) => d.id === id));
		return (
			<Grid item container key={uuidv4()} alignItems="center" spacing={6}>
				<Grid item xs={8}>
					<Map
						key={yard.id}
						yard={yard}
						dropMetricsAreVisible={dropMetricsAreVisible}
					/>
				</Grid>
				<Grid item xs={4}>
					<MetricsOnYard yard={yard} drops={_drops} customers={[customer]} />
				</Grid>
			</Grid>
		);
	});
};

YardMaps.propTypes = {
	yards: PropTypes.array.isRequired,
	dropMetricsAreVisible: PropTypes.bool.isRequired,
	customer: PropTypes.object.isRequired,
};

export default YardMaps;
