import { Paper, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import Button from "../../components/Button";
import { useAccount } from "../../contexts/AccountContextProvider";
import { useNotification } from "../../contexts/NotificationContextProvider";
import theme from "../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
			width: "100%",
			marginTop: 70,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		paper: {
			padding: 20,
			width: 280,
			backgroundColor: theme.colors.backgrounds.primary,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		textInput: {
			marginTop: theme.dimensions.indent / 2,
		},
		button: {
			marginTop: theme.dimensions.indent / 2,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			textTransform: "uppercase",
		},
	}),
);

const SignIn = () => {
	const styles = useStyles();
	const { logIn, isLoading } = useAccount();
	const { notifSuccess, notifError } = useNotification();
	const [form, setForm] = React.useState({ email: "", password: "" });

	const updateForm = (key, value) =>
		setForm((oldForm) => {
			const newForm = { ...oldForm, [key]: value };
			return newForm;
		});

	const signIn = () => {
		if (
			form.email.endsWith("@ubees.com") ||
			form.email.endsWith("@ckpinsurance.com")
		) {
			logIn({
				email: form.email,
				password: form.password,
			})
				.then(() =>
					notifSuccess("ConnexionRequired.profile.events.success.signIn"),
				)
				.catch(() =>
					notifError("ConnexionRequired.profile.events.errors.signIn"),
				);
		} else {
			notifError("ConnexionRequired.profile.events.errors.signIn");
		}
	};

	return (
		<div className={styles.container}>
			<Paper elevation={2} square className={clsx(styles.paper)}>
				<Typography className={styles.title}>
					{i18n.t("ConnexionRequired.profile.title")}
				</Typography>
				<TextField
					id="email-textfield"
					label="Email"
					variant="outlined"
					size="small"
					className={styles.textInput}
					onChange={(event) => updateForm("email", event.target.value)}
					InputLabelProps={{
						style: {
							color: theme.colors.texts.primary,
							fontSize: theme.fontSizes.medium,
							fontFamily: theme.fonts.medium,
						},
					}}
					inputProps={{
						style: {
							color: theme.colors.texts.primary,
							fontSize: theme.fontSizes.medium,
							fontFamily: theme.fonts.medium,
							backgroundColor: theme.colors.backgrounds.primary,
						},
					}}
				/>
				<TextField
					id="password-textfield"
					label="Password"
					variant="outlined"
					size="small"
					type="password"
					className={styles.textInput}
					onChange={(event) => updateForm("password", event.target.value)}
					InputLabelProps={{
						style: {
							color: theme.colors.texts.primary,
							fontSize: theme.fontSizes.medium,
							fontFamily: theme.fonts.medium,
						},
					}}
					inputProps={{
						style: {
							color: theme.colors.texts.primary,
							fontSize: theme.fontSizes.medium,
							fontFamily: theme.fonts.medium,
							backgroundColor: theme.colors.backgrounds.primary,
						},
					}}
				/>
				<Button
					type="secondary"
					style={styles.button}
					styleText={styles.buttonText}
					onClick={signIn}
					loading={isLoading}
					label={i18n.t("ConnexionRequired.profile.signIn")}
				/>
			</Paper>
		</div>
	);
};

SignIn.propTypes = {
	changePageDisplayed: PropTypes.func,
};

export default SignIn;
