import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import * as React from "react";
import { useModal } from "../../contexts/ModalContextProvider";

const useStyles = makeStyles((theme) =>
	createStyles({
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xmedium,
			flex: 1,
			display: "flex",
			justifyContent: "center",
		},
		closeIcon: {
			textAlign: "end",
		},
	}),
);
const PollinationModal = ({ children, onClose }) => {
	const styles = useStyles();

	const { modalOpened, options, title, openModal } = useModal();
	return (
		<>
			<Dialog
				disableEscapeKeyDown
				maxWidth={options.maxWidth}
				fullScreen={options.fullScreen}
				open={modalOpened}
			>
				{title && (
					<DialogTitle>
						<Grid item container>
							<Grid item xs={10}>
								<Grid container>
									<Typography variant="body2" className={styles.title}>
										{title}
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={2} className={styles.closeIcon}>
								<IconButton
									onClick={() => {
										onClose();
										openModal(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							</Grid>
						</Grid>
					</DialogTitle>
				)}
				<DialogContent>{children}</DialogContent>
			</Dialog>
		</>
	);
};

PollinationModal.propTypes = {
	onClose: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
export default PollinationModal;
