import { Grid, withStyles } from "@material-ui/core";
import { TableHead as MuiTableHead } from "@material-ui/core";
import { TableRow as MuiTableRow } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useLocal } from "../../../../../contexts/LocalContextProvider";
import theme from "../../../../../theme/theme";
import descendingComparator from "../../../../../utils/descendingComparator";
import stableSort from "../../../../../utils/stableSort";
import InfoField from "./InfoField";
import TableRowDrop from "./TableRowDrop";
import YardsTableHead from "./YardsTableHead";

const StyledCell = withStyles({
	head: {
		backgroundColor: theme.colors.backgrounds.darkWhite,
	},
})(TableCell);

/**
 * Details of one yard's drops
 */
const DropsDetails = ({ drops, FoBAverage, totalHives, info, updateYard }) => {
	const { yardInUpdateMode } = useLocal();

	const [sortedData, setSortedData] = React.useState([]);
	const [dataToDisplay, setDataToDisplay] = React.useState([]);
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("date");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	React.useEffect(() => {
		const comparator =
			order === "desc"
				? (a, b) => descendingComparator(a, b, orderBy)
				: (a, b) => -descendingComparator(a, b, orderBy);

		const sortedDrops = stableSort(drops, comparator);
		setSortedData(sortedDrops);
	}, [drops, order, orderBy]);

	React.useEffect(() => {
		const paginatedYards = sortedData.slice(
			page * rowsPerPage,
			page * rowsPerPage + rowsPerPage,
		);

		setDataToDisplay(paginatedYards);
	}, [sortedData, page, rowsPerPage]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	const handleChangePage = (event, newPage) => setPage(newPage);

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Grid container spacing={2}>
			{(info || yardInUpdateMode) && (
				<Grid item xs={12}>
					<InfoField updateYard={updateYard} info={info} />
				</Grid>
			)}
			<Grid item container spacing={2}>
				{!!totalHives && (
					<Grid item xs={12} md={5}>
						<TableContainer component={Paper}>
							<Table size="small" aria-label="global metrics">
								<MuiTableHead>
									<MuiTableRow>
										<StyledCell>
											{i18n.t(`Client.Yards.YardsTable.totalHives`)}
										</StyledCell>
										<StyledCell>
											{i18n.t(`Client.Yards.YardsTable.fobAverage`)}
										</StyledCell>
									</MuiTableRow>
								</MuiTableHead>
								<TableBody>
									<MuiTableRow>
										<TableCell>{totalHives}</TableCell>
										<TableCell>{FoBAverage}</TableCell>
									</MuiTableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				)}
				{!!dataToDisplay.length && (
					<Grid item xs={12} md={7}>
						<TableContainer component={Paper}>
							<Table size="small">
								<YardsTableHead
									order={order}
									orderBy={orderBy}
									onRequestSort={handleRequestSort}
								/>
								<TableBody>
									{dataToDisplay.map((drop, index) => {
										const labelId = `enhanced-table-checkbox-${index}`;

										return <TableRowDrop key={labelId} drop={drop} />;
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 50]}
							component="div"
							count={drops.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

DropsDetails.propTypes = {
	drops: PropTypes.array.isRequired,
	info: PropTypes.string,
	totalHives: PropTypes.number.isRequired,
	FoBAverage: PropTypes.string.isRequired,
	updateYard: PropTypes.func.isRequired,
};

export default DropsDetails;
