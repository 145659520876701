import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useModal } from "../../contexts/ModalContextProvider";
import Button from "../Button";

const DeleteYardsConfirmation = ({ onDelete }) => {
	const { openModal } = useModal();

	return (
		<Grid container spacing={4}>
			<Grid container item spacing={4} direction="column">
				<Grid item>
					<Typography>
						{i18n.t("components.DeleteYardsConfirmation.confirmation")}
					</Typography>
				</Grid>
				<Grid item>
					<Typography>
						{i18n.t("components.DeleteYardsConfirmation.explanation")}
					</Typography>
				</Grid>
				<Grid container item justifyContent="space-around">
					<Grid item>
						<Button
							onClick={onDelete}
							label={i18n.t("components.DeleteYardsConfirmation.confirm")}
						/>
					</Grid>
					<Grid item>
						<Button
							variant="outlined"
							onClick={() => {
								openModal(false);
							}}
							label={i18n.t("components.DeleteYardsConfirmation.cancel")}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

DeleteYardsConfirmation.propTypes = {
	onDelete: PropTypes.func.isRequired,
};

export default DeleteYardsConfirmation;
