import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import YardsMap from "../../App/Client/components/ClientYards/YardsMap";
import HeatMapForm from "../../App/Client/components/ClientYards/YardsTable/HeatMapForm";

const useStyles = makeStyles((theme) =>
	createStyles({
		form: {
			padding: theme.dimensions.indent / 4,
		},
	}),
);
const HeatmapYard = ({ yards, selectedYardIds, setSelectedYardIds }) => {
	const styles = useStyles();

	return (
		<>
			<Grid container className={styles.form}>
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						{i18n.t("Client.Yards.heatmap.button")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<HeatMapForm />
				</Grid>
			</Grid>
			<Grid item xs={12} style={{ height: "calc(100% - 48px)" }}>
				<YardsMap
					yards={yards}
					selectedYardIds={selectedYardIds}
					onClickOnYard={(yard) => setSelectedYardIds([yard.id])}
				/>
			</Grid>
		</>
	);
};

HeatmapYard.propTypes = {
	yards: PropTypes.array.isRequired,
	selectedYardIds: PropTypes.array.isRequired,
	setSelectedYardIds: PropTypes.func.isRequired,
};

export default HeatmapYard;
