import { TableHead, TableRow } from "@material-ui/core";
import i18n from "i18next";
import * as React from "react";
import StyledCell from "./StyledCell";

const StyledHead = () => {
	return (
		<TableHead>
			<TableRow>
				<StyledCell>
					{i18n.t("components.DropEditMap.SelectProduct.hives")}
				</StyledCell>
				<StyledCell>
					{i18n.t("components.DropEditMap.SelectProduct.fob")}
				</StyledCell>
				<StyledCell>
					{i18n.t("components.DropEditMap.SelectProduct.remove")}
				</StyledCell>
			</TableRow>
		</TableHead>
	);
};

export default StyledHead;
