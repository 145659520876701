import { TextField, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "../../../components/Button";
import PhoneTextField from "../../../components/Fields/PhoneTextField";
import { useApi } from "../../../contexts/ApiContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import theme from "../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		header: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			width: "100%",
			alignItems: "center",
		},
		textInput: {
			marginTop: theme.dimensions.indent / 2,
			width: "100%",
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
		},
		phoneInput: {
			width: "100% !important",
			height: "40px !important",
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			backgroundColor: theme.colors.backgrounds.primary,
		},
	}),
);

const ClientCreate = ({ onQuit, refreshData }) => {
	const styles = useStyles();
	const { notifWarning } = useNotification();
	const { api } = useApi();
	const { notifSuccess, notifError } = useNotification();

	const [client, setClient] = React.useState({
		email: "",
		name: "",
		phoneNumber: "",
		address: "",
		id: uuidv4(),
		stripeAccountId: uuidv4(),
	});

	const updateClient = (key, value) =>
		setClient((oldClient) => {
			return { ...oldClient, [key]: value };
		});

	const handleNewClient = () => {
		if (
			client.name !== "" &&
			client.email !== "" &&
			client.address !== "" &&
			client.phoneNumber !== ""
		) {
			createClient({ client, onCreateCallback: onQuit });
		} else {
			notifWarning("Clients.events.warnings.addClient");
		}
	};

	const createClient = ({ client, onCreateCallback }) => {
		api
			.backofficeGrowersCustomersPost(client)
			.then(onCreateCallback)
			.then(refreshData)
			.then(() => notifSuccess("Clients.events.success.addClient"))
			.catch(() => {
				notifError("Clients.events.errors.addClient");
			});
	};

	return (
		<Grid container spacing={4}>
			<Grid item container spacing={2}>
				<Grid item xs={12}>
					<TextField
						id="email-textfield"
						label="Email"
						variant="outlined"
						size="small"
						className={styles.textInput}
						onChange={(event) => updateClient("email", event.target.value)}
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
					<TextField
						id="name-textfield"
						label="Name"
						variant="outlined"
						size="small"
						className={styles.textInput}
						onChange={(event) => updateClient("name", event.target.value)}
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
					<PhoneTextField
						value={client.phoneNumber}
						onChange={(text) => updateClient("phoneNumber", text)}
						containerClass={styles.textInput}
						inputClass={styles.phoneInput}
					/>
					<TextField
						id="address-textfield"
						label="Address"
						variant="outlined"
						size="small"
						type="text"
						className={styles.textInput}
						onChange={(event) => updateClient("address", event.target.value)}
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
					<TextField
						id="company-textfield"
						label="Company"
						variant="outlined"
						size="small"
						className={styles.textInput}
						onChange={(event) => updateClient("company", event.target.value)}
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
				</Grid>
				<Grid
					item
					container
					alignItems="center"
					spacing={2}
					justifyContent="space-around"
				>
					<Grid item>
						<Button
							type="secondary"
							styleText={styles.buttonText}
							onClick={handleNewClient}
							label={i18n.t("Clients.confirm")}
						/>
					</Grid>
					<Grid item>
						<Button
							variant="outlined"
							onClick={onQuit}
							label={i18n.t("Clients.cancel")}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

ClientCreate.propTypes = {
	refreshData: PropTypes.func.isRequired,
	onQuit: PropTypes.func.isRequired,
};

export default ClientCreate;
