import { withStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import theme from "../../../../theme/theme";

const StyledCell = withStyles({
	root: {
		fontFamily: theme.fonts.condensedMedium,
	},
	head: {
		backgroundColor: theme.colors.backgrounds.darkWhite,
	},
})(TableCell);

export default StyledCell;
