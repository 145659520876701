import ButtonBase from "@material-ui/core/ButtonBase";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			flexDirection: "row",
			borderRadius: 100,
			padding: theme.dimensions.indent / 4,
			border: theme.border.invisible,
			marginLeft: theme.dimensions.indent / 6,
			marginRight: theme.dimensions.indent / 6,
		},
		selected: {
			backgroundColor: theme.colors.backgrounds.yellow,
			border: theme.border.greySmall,
		},
		item: {
			flexDirection: "row",
		},
		enabled: {
			color: theme.colors.texts.primary,
		},
		disabled: {
			color: theme.colors.texts.grey,
		},
		selectionError: {
			backgroundColor: theme.colors.backgrounds.red,
		},
	}),
);

const NumberItem = ({ number, selected, enabled = true, onClick }) => {
	const styles = useStyles();

	return (
		<ButtonBase
			onClick={onClick}
			disabled={!enabled}
			className={clsx(
				styles.container,
				selected && styles.selected,
				selected && !enabled && styles.selectionError,
			)}
		>
			<div className={styles.item}>
				<Typography
					className={clsx(
						styles.highlight,
						enabled ? styles.enabled : styles.disabled,
					)}
				>
					{number}
				</Typography>
			</div>
		</ButtonBase>
	);
};

NumberItem.propTypes = {
	number: PropTypes.number.isRequired,
	selected: PropTypes.bool.isRequired,
	enabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};

export default NumberItem;
