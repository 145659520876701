import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import initYard from "../../../../../constants/initYard";
import { useModal } from "../../../../../contexts/ModalContextProvider";
import { useNotification } from "../../../../../contexts/NotificationContextProvider";
import NameAndSizeForm from "./components/NameAndSizeForm";
import Map from "./Map";

/**
 * YardCreateMap page component
 *
 * This page display all orchard of user on a map
 */
const YardCreateMap = ({ createYard }) => {
	const { notifWarning } = useNotification();
	const { openModal } = useModal();

	const [yard, setYard] = React.useState(initYard);

	const setYardName = (name) => setYard((oldYard) => ({ ...oldYard, name }));
	const setYardSize = (size) =>
		setYard((oldYard) => ({
			...oldYard,
			size: Number(size.replace(",", ".")),
		}));
	const setYardCrops = (crops) => setYard((oldYard) => ({ ...oldYard, crops }));

	const setYardAdditionalInfo = (additionalInfo) =>
		setYard((oldYard) => ({ ...oldYard, additionalInfo }));
	const addVertice = (coordinates) => {
		setYard((oldYard) => {
			const newVertices = [...oldYard.vertices, coordinates];
			return { ...oldYard, vertices: newVertices };
		});
	};

	const updateVertice = (coordinate, index) =>
		setYard((oldYard) => {
			// Do not remove the copy, otherwise breaks because we update an immutable
			const newYard = {
				...oldYard,
				vertices: [...oldYard.vertices],
			};
			newYard.vertices.splice(index, 1, coordinate);
			return newYard;
		});

	const removeVertice = (index) => {
		setYard((oldYard) => {
			const newYard = {
				...oldYard,
				vertices: [...oldYard.vertices],
			};
			newYard.vertices.splice(index, 1);
			return newYard;
		});
	};

	const onPressOnMap = (position) =>
		addVertice({
			latitude: position.latLng.lat(),
			longitude: position.latLng.lng(),
		});

	const onMarkerDragEnd = (event, index) =>
		updateVertice(
			{
				latitude: event.latLng.lat(),
				longitude: event.latLng.lng(),
			},
			index,
		);

	const _createYard = () => {
		if (yard.name !== "" && yard.size !== "" && yard.vertices.length >= 3) {
			createYard({
				yard,
				onCreateCallback: () => {
					openModal(false);
				},
			});
			setYard(initYard);
		} else {
			notifWarning("Client.Yards.YardCreateMap.events.warnings.addYard");
		}
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<NameAndSizeForm
					yard={yard}
					setYardName={setYardName}
					setYardSize={setYardSize}
					setYardCrops={setYardCrops}
					setYardAdditionalInfo={setYardAdditionalInfo}
					createYard={_createYard}
				/>
			</Grid>
			<Grid item xs={12}>
				<Map
					yard={yard}
					onPressOnMap={onPressOnMap}
					onMarkerDragEnd={onMarkerDragEnd}
					removeVertice={removeVertice}
				/>
			</Grid>
		</Grid>
	);
};

YardCreateMap.propTypes = {
	createYard: PropTypes.func.isRequired,
};

export default YardCreateMap;
