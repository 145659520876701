const initYard = {
	name: "",
	size: "",
	// Locations of the yards borders
	vertices: [],
	// Drops in the yard
	drops: [],
	// Who did the last vertice update
	lastYardUpdater: "ubees",
	// Who did the last drop update
	lastDropsUpdater: "ubees",
	// If last updater is Ubees, growers says he agree
	yardValidatedByGrower: false,
	// If last updater is Ubees, growers says he agree
	dropsValidatedByGrower: false,
	// If last updater is grower, Ubees says we agree
	yardValidatedByUbees: false,
	yardRejectedByUbees: false,
	// If last updater is grower, Ubees says we agree
	dropsValidatedByUbees: false,
	dropsRejectedByUbees: false,
	pruningCompleted: false,
	sprayingCompleted: false,
	flaggingCompleted: false,
	hivesLeftOriginState: false,
	hivesArrivedDestinationState: false,
	hivesDropPending: false,
	deliveryStarted: false,
	deliveryCompleted: false,
	region: "california",
	additionalInfo: "",
};

export default initYard;
