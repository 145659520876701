import { Button as ButtonMui } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CreateIcon from "@material-ui/icons/Create";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
	end: {
		textAlign: "end",
	},
}));

const Details = ({ openEditMode, client }) => {
	const styles = useStyles();

	return (
		<>
			<Grid item xs={12} className={styles.end}>
				<ButtonMui variant="outlined" onClick={openEditMode} size="small">
					<CreateIcon color="action" fontSize="small" />
					<Typography variant="button">
						{i18n.t("Clients.editClient")}
					</Typography>
				</ButtonMui>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="subtitle1">{client?.name}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1">{client?.phoneNumber}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1">{client?.address}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1">{client?.email}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1">{client?.company}</Typography>
			</Grid>
		</>
	);
};

Details.propTypes = {
	openEditMode: PropTypes.func.isRequired,
	client: PropTypes.object.isRequired,
};

export default Details;
