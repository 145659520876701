import PropTypes from "prop-types";
import * as React from "react";

const ModalContext = React.createContext();

const OPTIONS = {
	fullScreen: false,
	maxWidth: "md",
};
const ModalContextProvider = ({ children }) => {
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState(OPTIONS);
	const [title, setTitle] = React.useState("");
	const [modalType, setModalType] = React.useState("");

	const openModal = (open, params) => {
		setOpen(open);

		if (params) {
			setTitle(params.title);
			setOptions(params.options);
		} else {
			setTimeout(() => {
				setModalType("");
				setTitle("");
				setOptions(OPTIONS);
			}, 100);
		}
	};

	return (
		<ModalContext.Provider
			value={{
				title,
				modalOpened: open,
				openModal,
				modalType,
				setModalType,
				options,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = () => React.useContext(ModalContext);

ModalContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default ModalContextProvider;
