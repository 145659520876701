const en = {
	Header: {
		profile: {
			logOut: "Log out",
			back: "Back",
			events: {
				success: {
					logOut: "You sucessfully logged out.",
				},
			},
		},
		pages: {
			clients: "Home",
			mapsToPrint: "Maps to print",
			maps: "Maps",
		},
	},
	ApiContextProvider: {
		events: {
			errors: {
				unauthorizedRequest:
					"Unauthorized request. Please make sure you verified your email",
			},
		},
	},
	ConnexionRequired: {
		profile: {
			title: "My Pollination Marketplace backoffice",
			signIn: "Sign in",
			ok: "OK",
			events: {
				success: {
					signIn: "You are now signed in!",
					logOut: "You sucessfully logged out.",
				},
				errors: {
					signIn:
						"An error occured. Are you sure you used the correct email and password ?",
				},
			},
		},
		pages: {
			clients: "Clients",
			map: "Map",
		},
	},
	Clients: {
		title: "Clients",
		headCells: {
			blank: "",
			name: "Contact",
			email: "Email",
			phoneNumber: "Phone Number",
			address: "Address",
			company: "Company",
			select: "Select",
			nbDrops: "Drops",
		},
		addClient: "Add client",
		editClient: "Edit client",
		searchClient: "Search a client",
		deleteClientModal: {
			title: "Delete client",
			confirmation: " Are you sure you want to delete this client ?",
			explanation:
				"The yards and drops of this client will be deleted as well.",
			confirm: "confirm",
			cancel: "cancel",
		},
		confirm: "add",
		cancel: "cancel",
		events: {
			copied: "phone number copied",
			success: {
				addClient: "Client created",
				deleteClient: "Client deleted",
			},
			errors: {
				addClient:
					"An error occured while creating this client. Please make sure that the email is unique",
				deleteClient:
					"An error occured while deleting this client. Please try again later.",
				getClients: "Could not fetch clients data. Please try again later.",
			},
			warnings: {
				addClient:
					"This client is incomplete. Please fill the email, the name, the phone number and the address",
			},
		},
	},
	Client: {
		title: "{customer} informations",
		EditCustomerForm: {
			labels: {
				contact: "Contact",
				email: "Email :",
				phoneNumber: "Phone Number",
				stripeID: "Stripe ID: {id}",
				address: "Address",
				company: "Company",
			},
			formErrors: {
				name: "Please enter a name.",
				email: "Please enter a valid email address",
				phoneNumber: "Phone number does not have a proper format",
				phoneNumberRequired: "Please provide a phone number",
				addressRequired: "Please provide an address",
			},
			buttons: {
				updateInfo: "edit",
				cancel: "cancel",
			},
			events: {
				success: {
					patchProfile: "Client's data updated",
				},
				errors: {
					patchProfile:
						"Could not update the client's data. Please retry later.",
					getCustomer:
						"Error while fetching customer data. Please try again later",
				},
			},
		},
		yards: {
			title: "Yards",
			selected: "selected",
		},
		Yards: {
			title: "Yards",
			heatmap: {
				hidden: "Hidden",
				byArea: "By area",
				title: "Yards",
				button: "Heatmap",
			},
			editYardsMode: "Edit mode",
			createYard: "Create a yard",
			printYards: "Print yards",
			editDrops: "Edit drops",
			tooltips: {
				deleteYard: "Delete the selection",
				editYardDelineation: "Edit yard delineation",
			},
			events: {
				success: {
					addYard: "Yard created",
					updateYard: "Yard updated",
					deleteYard: "Yards deleted",
				},
				errors: {
					getYards: "Could not fetch yards data. Please try again later.",
					getDrops: "Could not fetch drops data. Please try again later.",
					addYard: "An error occured while creating this yard.",
					updateYard: "An error occured while updating this yard.",
					deleteYard:
						"An error occured while deleting those yards. On or more may have been deleted anyway. Please refresh the page !",
				},
			},
			crops: {
				almonds: "Almonds",
				blueberries: "Blueberries",
				avocados: "Avocados",
				cranberries: "Cranberries",
				canola: "Canola",
				apples: "Apples",
				cherries: "Cherries",
			},
			YardsTable: {
				header: {
					blank: "",
					name: "Name",
					size: "Size",
					crops: "Crops",
					numberOfDrops: "Drops",
					action: "Action",
				},
				additionalInfo: "Info",
				totalHives: "Total of hives",
				fobAverage: "FoB average",
			},
			DropsDetails: {
				header: {
					drop: "Drop",
					fob: "FoB",
					numberOfBeehives: "Beehives",
				},
			},
			YardCreateMap: {
				modal: {
					title: "Create a yard for {{client}}",
					setName: "Name",
					setSize: "Size (in acres)",
					setCrops: "Crops",
					setAdditionalInfo: "Additional Info",
					createYard: "Create",
					searchLocation: "Type a location and press enter",
					placePins: "Place markers on the map to draw the yard's area",
					noAddressAvailable:
						"No address available, please enter your address then press enter",
				},
				crops: {
					almonds: "Almonds",
					blueberries: "Blueberries",
					avocados: "Avocados",
					cranberries: "Cranberries",
					canola: "Canola",
					apples: "Apples",
					cherries: "Cherries",
				},
				confirm: "Confirm",
				events: {
					warnings: {
						addYard:
							"This yard is incomplete. Please fill the name, the size and place at least 3 corners.",
					},
				},
			},
			YardsMap: {
				label: {
					fob: "FoB:",
					numberOfBeehives: "# hives:",
				},
			},
		},
	},
	MapPage: {
		MetricsOnYard: {
			title: "Yard selected: {yardName}",
			owner: "Owner: {ownerName}",
			size: "Acres",
			drops: "Drops",
			crops: "Crops",
			beehives: "Beehives",
			fobAverage: "FoB av.",
			hivesPerAcre: "Hives/acre",
			note: "Note: ",
			openMap: "Open map",
		},
		Map: {
			label: {
				fob: "FoB:",
				numberOfBeehives: "# hives:",
			},
		},
		events: {
			success: {
				deleteDrop: "Drop deleted",
				updateYard: "Yard updated",
				deleteYard: "Yard deleted",
			},
			errors: {
				getDrops: "Could not get the yards data. Please try again later.",
				getYards: "Could not get the drops data. Please try again later.",
				getCustomers:
					"Could not get the customers data. Please try again later.",
				deleteDrop: "Could not delete the drop. Please try again later.",
				updateYard: "Could not update the yard. Please try again later.",
				deleteYard: "Could not delete the yard. Please try again later.",
			},
		},
	},
	MapsToPrint: {
		print: "Print",
	},
	components: {
		YardEditMap: {
			title: "Edit delineation of the yard {{name}}",
		},
		DropEditMap: {
			title: "Edit drops of the yard {{name}}",
			showDropDetails: "Show drops details",
			hideDropDetails: "Hide drops details",
			editSelectedDrop: "Edit selected drop",
			YardInfo: {
				placePins: "Place a new drop in the yard's area:",
				drops_one: "{{count}} drop",
				drops_other: "{{count}} drops",
				hives_one: "{{count}} hive",
				hives_other: "{{count}} hives",
				hivesPerAcre: "{{hives}} hives/acre",
				fobAverage: "{{fob}} FoB",
			},
			SelectProduct: {
				selectNbOfFrames: "Frame of Bees (FoB)",
				selectNbOfHives: "Number of hives",
				frames: "frames",
				fob: "FoB",
				hives: "Hives",
				available: "hives left",
				unknownLocation: "Location unknown",
				remove: "Remove",
			},
			events: {
				success: {
					addDrop: "Drop successfuly added",
					updateDrop: "Drop successfuly updated",
					deleteDrop: "Drop successfuly deleted",
				},
				errors: {
					addDrop: "Error while adding drop",
					updateDrop: "Error while updating the drop",
					deleteDrop: "Error while deleting the drop",
					getProducts: "Could not fetch the available number of frames",
					coordinatesNotValid:
						"The coordinates are invalid. Please place the drop inside the yard.",
				},
			},
			buttons: {
				validate: "Validate",
			},
		},
		DeleteYardsConfirmation: {
			title_one: "Delete the yard",
			title_other: "Delete {{count}} yards",
			confirmation: "Are you sure?",
			explanation: "The drops will be deleted as well.",
			cancel: "Cancel",
			confirm: "Confirm",
		},
	},
};

export default en;
