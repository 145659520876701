import { createStyles, makeStyles } from "@material-ui/core/styles";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import PropTypes from "prop-types";
import * as React from "react";
import { useCallback, useRef } from "react";
import { useApi } from "../../../contexts/ApiContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import PinIcon from "../../../images/pin.svg";
import theme from "../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			width: "70vw",
			height: 500,
			pageBreakAfter: "always",
		},
		labelContainer: {
			borderRadius: theme.roundness,
			backgroundColor: theme.colors.map.background.information,
			padding: theme.dimensions.indent / 4,
			border: "solid 1px black",
		},
		label: {
			margin: 0,
		},
		markerLabel: {
			fontSize: theme.fontSizes.xbig,
			fontFamily: theme.fonts.bold,
			marginBottom: 15,
			backgroundColor: "#f0d169",
			width: 20,
		},
	}),
);

const fresno = {
	lat: 36.7378,
	lng: -119.7871,
};

const getRegion = (yard) => {
	const latitude = yard.vertices.reduce(
		(latitudes, vertice) => [...latitudes, vertice.latitude],
		[],
	);
	const longitude = yard.vertices.reduce(
		(longitudes, vertice) => [...longitudes, vertice.longitude],
		[],
	);
	const minLat = Math.min(...latitude);
	const maxLat = Math.max(...latitude);
	const minLng = Math.min(...longitude);
	const maxLng = Math.max(...longitude);
	return { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 };
};

const MapComponent = ({ yard }) => {
	const styles = useStyles();
	const mapRef = React.useRef();
	const { api } = useApi();
	const { notifError } = useNotification();

	const [center, setCenter] = React.useState(fresno);
	const [drops, setDrops] = React.useState([]);

	React.useEffect(() => {
		setCenter(getRegion(yard));
	}, [yard]);
	const mountedRef = useRef(true);
	const getDrops = useCallback(() => {
		return api
			.backofficeGrowersYardsIdDropsGet(yard.id)
			.then((drops) => {
				// Avoid memory leak
				if (!mountedRef.current) return null;
				setDrops(drops);
				return drops;
			})
			.catch(() => notifError("Client.Yards.events.errors.getDrops"));
	}, [api, notifError, yard.id]);

	React.useEffect(() => {
		if (!drops.length) {
			getDrops();
		}

		return () => {
			mountedRef.current = false;
		};
	}, [drops.length, getDrops]);

	return (
		<div className={styles.container}>
			{/* Map of yards' client */}
			<GoogleMap
				mapContainerStyle={{ height: "100%" }}
				initialCenter={center}
				center={center}
				ref={mapRef}
				zoom={16}
				mapTypeId="hybrid"
				gestureHandling="greedy"
				onLoad={(map) => {
					let bounds = new window.google.maps.LatLngBounds();
					yard.vertices.forEach((vertice) =>
						bounds.extend({
							lat: vertice.latitude,
							lng: vertice.longitude,
						}),
					);
					map.fitBounds(bounds);
				}}
			>
				<React.Fragment>
					{drops.map((drop) => (
						<Marker
							key={drop.id}
							name="Current location"
							icon={PinIcon}
							position={{
								lat: drop.coordinate.latitude,
								lng: drop.coordinate.longitude,
							}}
							label={{
								text: `${drop.numberOfBeehives}`,
								color: "black",
								className: styles.markerLabel,
							}}
						></Marker>
					))}
					<Polygon
						path={yard.vertices.map((el) => ({
							lat: el.latitude,
							lng: el.longitude,
						}))}
						options={{
							strokeColor: theme.colors.backgrounds.darkWhite,
							fillColor: theme.colors.backgrounds.darkWhite,
							fillOpacity: 0.5,
							strokeOpacity: 1,
						}}
					/>
				</React.Fragment>
			</GoogleMap>
		</div>
	);
};

MapComponent.propTypes = {
	yard: PropTypes.object.isRequired,
	dropMetricsAreVisible: PropTypes.bool.isRequired,
};

export default MapComponent;
