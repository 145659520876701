import { FormControl, FormLabel, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useLocal } from "../../../../../contexts/LocalContextProvider";

const InfoField = ({ updateYard, info }) => {
	const { yardInUpdateMode } = useLocal();

	return yardInUpdateMode ? (
		<Alert severity="info">
			<FormControl fullWidth={true}>
				<FormLabel>
					<Typography>
						{i18n.t(`Client.Yards.YardsTable.additionalInfo`)}
					</Typography>
				</FormLabel>
				<TextField
					onBlur={(event) =>
						updateYard({
							update: {
								additionalInfo: event.target.value,
							},
						})
					}
					defaultValue={info}
				/>
			</FormControl>
		</Alert>
	) : (
		<Alert severity="info">{info}</Alert>
	);
};

InfoField.propTypes = {
	info: PropTypes.string,
	updateYard: PropTypes.func.isRequired,
};

export default InfoField;
