import PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";

const LocalContext = React.createContext();

const LocalContextProvider = ({ children }) => {
	const [clients, setClients] = React.useState([]);
	const [client, setClient] = React.useState(null);
	const [yards, setYards] = React.useState([]);
	const [selected, setSelected] = React.useState(false);
	const [yardInUpdateMode, setYardInUpdateMode] = React.useState(false);
	const [heatmap, setHeatmap] = useState("nope");

	React.useEffect(() => {}, [clients]);

	const updateClientsList = (customers) => {
		if (!clients.length || customers.length !== clients.length) {
			setClients(
				customers.map((customer) => {
					const yardsDrops = yards
						.filter((yard) => yard.customerId === customer.id)
						.reduce(function (previous, key) {
							return previous + key.drops.length;
						}, 0);

					return {
						...customer,
						active: false,
						nbDrops: yardsDrops,
					};
				}),
			);
		}
	};

	const initYards = (data) => {
		if (!yards.length) {
			setYards(data);
		}
	};
	const updateClient = (client) => {
		let activeClients = 0;
		if (client) {
			const newClients = clients.map((current) => {
				const { id, active } = current;

				if ((active && client.id !== id) || !client.active) {
					activeClients += 1;
				}

				return {
					...current,
					active: id === client.id ? !client.active : active,
				};
			});

			setClients(newClients);

			if (activeClients > 0) {
				setSelected(true);
			} else {
				setSelected(false);
			}
		}
	};
	const toggleSelectAll = () => {
		setClients(
			clients.map((client) => {
				return { ...client, active: !selected };
			}),
		);
	};

	const toggleYardUpdateMode = () => {
		setYardInUpdateMode(!yardInUpdateMode);
	};

	// TODO too much values
	return (
		<LocalContext.Provider
			value={{
				updateClientsList,
				clients,
				updateClient,
				toggleSelectAll,
				selected,
				setSelected,
				initYards,
				yards,
				client,
				setClient,
				yardInUpdateMode,
				toggleYardUpdateMode,
				heatmap,
				setHeatmap,
			}}
		>
			{children}
		</LocalContext.Provider>
	);
};

export const useLocal = () => React.useContext(LocalContext);

LocalContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default LocalContextProvider;
