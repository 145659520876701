/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Tokens model module.
* @module model/Tokens
* @version 0.0.1
*/
export default class Tokens {
    /**
    * Constructs a new <code>Tokens</code>.
    * @alias module:model/Tokens
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Tokens</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Tokens} obj Optional instance to populate.
    * @return {module:model/Tokens} The populated <code>Tokens</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Tokens();
                        
            
            if (data.hasOwnProperty('accessToken')) {
                obj['accessToken'] = ApiClient.convertToType(data['accessToken'], 'String');
            }
            if (data.hasOwnProperty('refreshToken')) {
                obj['refreshToken'] = ApiClient.convertToType(data['refreshToken'], 'String');
            }
        }
        return obj;
    }

    /**
    * An oauth2 access token, used to access data on the backend
    * @member {String} accessToken
    */
    accessToken = undefined;
    /**
    * An oauth2 refresh token, used to renew the access token when it expires
    * @member {String} refreshToken
    */
    refreshToken = undefined;




}
