import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Button from "../../../components/Button";
import { useApi } from "../../../contexts/ApiContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";

const useStyles = makeStyles((theme) =>
	createStyles({
		header: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			width: "100%",
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

const ClientDelete = ({ onQuit, client, refreshData }) => {
	const styles = useStyles();

	const { api } = useApi();
	const { notifSuccess, notifError } = useNotification();

	const deleteClient = ({ client, onDeleteCallback }) => {
		api
			.backofficeGrowersCustomersIdDelete(client.id)
			.then(onDeleteCallback)
			.then(refreshData)
			.then(() => notifSuccess("Clients.events.success.deleteClient"))
			.catch(() => {
				notifError("Clients.events.errors.deleteClient");
			});
	};

	return (
		<Grid container>
			<Grid item>
				<Typography variant="body2">
					{i18n.t("Clients.deleteClientModal.confirmation")}
				</Typography>
				<Typography variant="body2">
					{i18n.t("Clients.deleteClientModal.explanation")}
				</Typography>
			</Grid>
			<Grid container item>
				<Grid item xs={6}>
					<Button
						type="primary"
						style={styles.button}
						onClick={() => {
							deleteClient({ client, onDeleteCallback: onQuit });
						}}
						label={i18n.t("Clients.deleteClientModal.confirm")}
					/>
				</Grid>
				<Grid item xs={6}>
					<Button
						type="secondary"
						style={styles.button}
						onClick={onQuit}
						label={i18n.t("Clients.deleteClientModal.cancel")}
						variant="outlined"
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

ClientDelete.propTypes = {
	onQuit: PropTypes.func.isRequired,
	refreshData: PropTypes.func.isRequired,
	client: PropTypes.object.isRequired,
};

export default ClientDelete;
