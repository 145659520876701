/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Location from './Location';

/**
* The Drop model module.
* @module model/Drop
* @version 0.0.1
*/
export default class Drop {
    /**
    * Constructs a new <code>Drop</code>.
    * @alias module:model/Drop
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Drop</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Drop} obj Optional instance to populate.
    * @return {module:model/Drop} The populated <code>Drop</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Drop();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('coordinate')) {
                obj['coordinate'] = Location.constructFromObject(data['coordinate']);
            }
            if (data.hasOwnProperty('numberOfBeehives')) {
                obj['numberOfBeehives'] = ApiClient.convertToType(data['numberOfBeehives'], 'Number');
            }
            if (data.hasOwnProperty('productId')) {
                obj['productId'] = ApiClient.convertToType(data['productId'], 'String');
            }
            if (data.hasOwnProperty('fobAverage')) {
                obj['fobAverage'] = ApiClient.convertToType(data['fobAverage'], 'Number');
            }
            if (data.hasOwnProperty('locationOrigin')) {
                obj['locationOrigin'] = ApiClient.convertToType(data['locationOrigin'], 'String');
            }
        }
        return obj;
    }

    /**
    * Id provided by Ubees to follow the yards.
    * @member {String} id
    */
    id = undefined;
    /**
    * @member {module:model/Location} coordinate
    */
    coordinate = undefined;
    /**
    * Theoretical number of beehives in the yard
    * @member {Number} numberOfBeehives
    */
    numberOfBeehives = undefined;
    /**
    * Id of the product placed on the drop
    * @member {String} productId
    */
    productId = undefined;
    /**
    * Theoretical FOB average of the beehives
    * @member {Number} fobAverage
    */
    fobAverage = undefined;
    /**
    * From which region does the hive come from ?
    * @member {String} locationOrigin
    */
    locationOrigin = undefined;




}
