import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useLocal } from "../../../../../contexts/LocalContextProvider";
import metrics from "../../../../../utils/metrics";
import TableRowDropLevel from "./TableRowDropLevel";

const useStyles = makeStyles((theme) => ({
	text: { fontFamily: theme.fonts.bold },
	row: {
		cursor: "pointer",
		"&:hover": {
			background: theme.colors.backgrounds.darkWhite,
		},
	},
	expanded: {
		backgroundColor: theme.colors.backgrounds.darkWhite,
	},
}));

/**
 * On row representing one yard
 *
 * Can be expanded to see drops.
 */
const TableRowYardLevel = ({
	yard,
	isSelected,
	onSelect,
	onExpand,
	updateYard,
}) => {
	const styles = useStyles();
	const [dropDataIsVisible, setDropDataIsVisible] = React.useState(false);
	const { yardInUpdateMode } = useLocal();

	const toggleDropData = (e) => {
		e.stopPropagation();
		if (e.target.tagName !== "INPUT")
			setDropDataIsVisible((visible) => {
				if (!visible) onExpand();
				return !visible;
			});
	};

	return (
		<>
			<TableRow
				hover
				aria-checked={isSelected}
				className={clsx(dropDataIsVisible ? styles.expanded : null, styles.row)}
				selected={isSelected}
				tabIndex={-1}
				onClick={toggleDropData}
			>
				<TableCell align="center" padding="none">
					<Checkbox checked={isSelected} onClick={onSelect} size="small" />
				</TableCell>
				<TableCell className={styles.rowsPerPage} align="center" padding="none">
					{!yardInUpdateMode ? (
						yard.name
					) : (
						<TextField
							onBlur={(event) =>
								updateYard({
									update: {
										name: event.target.value,
									},
								})
							}
							defaultValue={yard.name}
						/>
					)}
				</TableCell>
				<TableCell align="center" padding="none">
					{!yardInUpdateMode ? (
						i18n.t(`Client.Yards.crops.${yard.crops}`)
					) : (
						<FormControl size="small">
							<Select
								value={yard.crops}
								onChange={(e) =>
									updateYard({
										update: {
											crops: e.target.value,
										},
									})
								}
							>
								<MenuItem value="almonds">
									{i18n.t("Client.Yards.crops.almonds")}
								</MenuItem>
								<MenuItem value="blueberries">
									{i18n.t("Client.Yards.crops.blueberries")}
								</MenuItem>
								<MenuItem value="avocados">
									{i18n.t("Client.Yards.crops.avocados")}
								</MenuItem>
								<MenuItem value="cranberries">
									{i18n.t("Client.Yards.crops.cranberries")}
								</MenuItem>
								<MenuItem value="canola">
									{i18n.t("Client.Yards.crops.canola")}
								</MenuItem>
								<MenuItem value="apples">
									{i18n.t("Client.Yards.crops.apples")}
								</MenuItem>
								<MenuItem value="cherries">
									{i18n.t("Client.Yards.crops.cherries")}
								</MenuItem>
							</Select>
						</FormControl>
					)}
				</TableCell>
				<TableCell align="center" padding="none">
					{!yardInUpdateMode ? (
						yard.size
					) : (
						<TextField
							onBlur={(event) =>
								updateYard({
									update: {
										size: event.target.value,
									},
								})
							}
							defaultValue={yard.size}
						/>
					)}
				</TableCell>
				<TableCell align="center" padding="none">
					{yard.drops.length}
				</TableCell>
				<TableCell padding="none">
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={toggleDropData}
					>
						{dropDataIsVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			{typeof yard.drops[0] !== "string" && (
				<TableRowDropLevel
					info={yard.additionalInfo}
					updateYard={updateYard}
					drops={yard.drops}
					visible={dropDataIsVisible}
					metrics={{
						totalHives: metrics.numberOfBeehives(yard.drops),
						FoBAverage: metrics.fobAverage(yard.drops),
					}}
				/>
			)}
		</>
	);
};

TableRowYardLevel.propTypes = {
	yard: PropTypes.object.isRequired,
	isSelected: PropTypes.bool.isRequired,
	updateYard: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	onExpand: PropTypes.func.isRequired,
};

export default TableRowYardLevel;
