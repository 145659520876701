/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Mandate model module.
* @module model/Mandate
* @version 0.0.1
*/
export default class Mandate {
    /**
    * Constructs a new <code>Mandate</code>.
    * Bank mandate of the users (obfuscated data)
    * @alias module:model/Mandate
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Mandate</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Mandate} obj Optional instance to populate.
    * @return {module:model/Mandate} The populated <code>Mandate</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Mandate();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('holderName')) {
                obj['holderName'] = ApiClient.convertToType(data['holderName'], 'String');
            }
            if (data.hasOwnProperty('bankName')) {
                obj['bankName'] = ApiClient.convertToType(data['bankName'], 'String');
            }
            if (data.hasOwnProperty('routingNumber')) {
                obj['routingNumber'] = ApiClient.convertToType(data['routingNumber'], 'String');
            }
            if (data.hasOwnProperty('last4')) {
                obj['last4'] = ApiClient.convertToType(data['last4'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
        }
        return obj;
    }

    /**
    * stripe id of the card
    * @member {String} id
    */
    id = undefined;
    /**
    * Name of the account holder
    * @member {String} holderName
    */
    holderName = undefined;
    /**
    * Name of the bank
    * @member {String} bankName
    */
    bankName = undefined;
    /**
    * Routing number
    * @member {String} routingNumber
    */
    routingNumber = undefined;
    /**
    * Last 4 digits of the bank account
    * @member {String} last4
    */
    last4 = undefined;
    /**
    * Gives the status of the bank account
    * @member {String} status
    */
    status = undefined;




}
