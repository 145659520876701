import {
	Table,
	TableBody,
	TableRow,
	IconButton,
	Button,
	Grid,
	useTheme,
	useMediaQuery,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Print from "@material-ui/icons/Print";
import PrintDisabled from "@material-ui/icons/PrintDisabled";
import RoomIcon from "@material-ui/icons/Room";
import clsx from "clsx";
import Fuse from "fuse.js";
import i18n from "i18next";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PollinationModal from "../../components/PollinationModal";
import { useApi } from "../../contexts/ApiContextProvider";
import { useLocal } from "../../contexts/LocalContextProvider";
import { useModal } from "../../contexts/ModalContextProvider";
import { useNotification } from "../../contexts/NotificationContextProvider";
import paths from "../paths";
import ClientsModalContent from "./components/ClientsModalContent";
import ClientTableCell from "./components/ClientTableCell";
import EnhancedTableHead from "./components/EnhancedTableHead";
import Header from "./components/Header";

const useStyles = makeStyles((theme) =>
	createStyles({
		row: {
			cursor: "pointer",
			"&:nth-child(odd)": {
				background: theme.colors.backgrounds.lightGrey,
			},
			"&:hover": {
				background: theme.colors.backgrounds.darkWhite,
			},
		},
		cell: {
			overflow: "hidden",
			width: 180,
			display: "box",
			lineClamp: 1,
			boxOrient: "vertical",
		},
		xsCell: {
			width: 90,
		},
		printer: {
			"&:hover": {
				background: theme.colors.icon.grey,
				borderRadius: 30,
			},
		},
		shadowTable: {
			position: "relative",
			"&::after": {
				content: '""',
				zIndex: 9999999,
				height: "100vh",
				top: 0,
				boxShadow: "#000 -10px 0 22px",
				position: "fixed",
				right: -12,
				width: 12,
			},
		},
	}),
);

/**
 * Clients component
 *
 * List of clients
 */
const Clients = () => {
	const styles = useStyles();
	const navigate = useNavigate();
	const { api } = useApi();

	const theme = useTheme();
	const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));

	const { clients, updateClient, updateClientsList, initYards } = useLocal();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("date");
	const { notifError, notifInfo } = useNotification();
	const { openModal, setModalType } = useModal();

	const [clientToDelete, setClientToDelete] = React.useState();
	const [fuse, setFuse] = React.useState(undefined);
	const [search, setSearch] = React.useState("");
	const [filteredClients, setFilteredClients] = React.useState([]);
	const [hasData, setHasData] = React.useState(false);

	React.useEffect(() => {
		if (!hasData) {
			api
				.backofficeGrowersYardsGet()
				.then(initYards)
				.then(() => {
					api
						.backofficeGrowersCustomersGet()
						.then(updateClientsList)
						.then(() => setHasData(true));
				});
		}
	}, [api, hasData, updateClientsList, initYards]);

	React.useEffect(() => {
		const searchKeysMobile = {
			false: ["name", "company", "email", "address"],
			true: ["name"],
		};

		setFilteredClients(clients);

		setFuse(new Fuse(clients, { keys: searchKeysMobile[xsScreen] }));
	}, [clients, xsScreen]);

	const updateSearch = (value) => {
		setSearch(value);
		if (value.length > 2) {
			setFilteredClients(fuse.search(value).map((s) => s.item));
		} else if (value.length === 0) {
			setFilteredClients(clients);
		}
	};

	const refreshData = React.useCallback(
		() =>
			api
				.backofficeGrowersCustomersGet()
				.then(updateClientsList)
				.catch(() => notifError("Clients.events.errors.getClients")),
		[api, updateClientsList, notifError],
	);

	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	};

	const getComparator = (order, orderBy) => {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	};

	const stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const newClient = () => {
		setModalType("newClient");
		openModal(true, {
			title: i18n.t("Clients.addClient"),
			options: { fullScreen: false, maxWidth: "md" },
		});
	};

	const copy = (e, text) => {
		navigator.clipboard.writeText(text);
		notifInfo("Clients.events.copied");
		e.stopPropagation();
	};

	const rowAction = {
		true: (row) => {
			updateClient(row);
			navigate(
				paths.mapsToPrint.baseUrl +
					paths.clients.getById.baseUrl.replace(":clientId", row.id),
			);
		},
		false: (row, target) => {
			// case clicked element is not a checkbox or delete icon
			if (target.tagName !== "INPUT" && target.tagName !== "path") {
				navigate(
					paths.clients.baseUrl +
						paths.clients.getById.baseUrl.replace(":clientId", row.id),
				);
			}
		},
	};
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Header
					search={search}
					updateSearch={updateSearch}
					newClient={newClient}
				/>
			</Grid>
			<Grid item xs={12} className={styles.shadowTable}>
				<Table stickyHeader size="small">
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
					/>
					<TableBody>
						{stableSort(filteredClients, getComparator(order, orderBy)).map(
							(row, index) => {
								const labelId = `enhanced-table-checkbox-${index}`;

								return (
									<TableRow
										tabIndex={-1}
										key={row.id}
										className={styles.row}
										size="small"
										onClick={({ target }) => {
											rowAction[xsScreen](row, target);
										}}
									>
										<ClientTableCell isVisible={xsScreen && row.nbDrops > 0}>
											<IconButton size="small">
												<RoomIcon color="secondary" />
											</IconButton>
										</ClientTableCell>
										<ClientTableCell isVisible={row.nbDrops === 0} isEmpty />
										<ClientTableCell isVisible={!xsScreen && row.nbDrops > 0}>
											<Checkbox
												className={styles.printer}
												icon={<PrintDisabled />}
												checkedIcon={<Print />}
												size="small"
												checked={row.active}
												onChange={() => {
													updateClient(row);
												}}
											/>
										</ClientTableCell>
										<ClientTableCell isVisible data={row.name} />
										<ClientTableCell isVisible={!xsScreen} data={row.email} />
										<ClientTableCell isVisible={xsScreen}>
											<Button
												variant="text"
												size="small"
												onClick={(e) => copy(e, row.phoneNumber)}
											>
												<FileCopyIcon fontSize="small" color="action" />
												<div
													className={clsx(
														styles.cell,
														xsScreen ? styles.xsCell : null,
													)}
												>
													{row.phoneNumber}
												</div>
											</Button>
										</ClientTableCell>
										<ClientTableCell
											isVisible={!xsScreen}
											data={row.phoneNumber}
										/>
										<ClientTableCell isVisible={!xsScreen} data={row.address} />
										<ClientTableCell isVisible={!xsScreen} data={row.company} />
										<ClientTableCell isVisible={!xsScreen} data={row.nbDrops} />
										<ClientTableCell isVisible={!xsScreen} id={labelId}>
											<IconButton
												size="small"
												onClick={() => {
													setClientToDelete(row);
													setModalType("deleteClient");
													openModal(true, {
														// content: renderContentModal("deleteClient", row),
														title: i18n.t("Clients.deleteClientModal.title"),
														options: {
															fullScreen: false,
															maxWidth: "sm",
														},
													});
												}}
											>
												<DeleteIcon />
											</IconButton>
										</ClientTableCell>
									</TableRow>
								);
							},
						)}
					</TableBody>
				</Table>
			</Grid>
			<PollinationModal>
				<ClientsModalContent
					clientToDelete={clientToDelete}
					refreshData={refreshData}
				/>
			</PollinationModal>
		</Grid>
	);
};

export default Clients;
