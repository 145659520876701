import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Print from "@material-ui/icons/Print";
import PrintDisabled from "@material-ui/icons/PrintDisabled";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useLocal } from "../../../contexts/LocalContextProvider";
const cells = [
	{
		id: "select",
		numeric: false,
		disablePadding: false,
		mobile: true,
	},
	{
		id: "name",
		numeric: false,
		disablePadding: true,
		mobile: true,
	},
	{
		id: "email",
		numeric: false,
		disablePadding: false,
		mobile: false,
	},
	{
		id: "phoneNumber",
		numeric: false,
		disablePadding: false,
		mobile: true,
	},
	{
		id: "address",
		numeric: false,
		disablePadding: false,
		mobile: false,
	},
	{
		id: "company",
		numeric: false,
		disablePadding: false,
		mobile: false,
	},
	{
		id: "nbDrops",
		numeric: true,
		disablePadding: false,
		mobile: false,
	},
	{
		id: "blank",
		numeric: false,
		disablePadding: true,
	},
];

/**
 * EnhancedTableHead component for Prospect Page.
 *
 * @param {Object} styles
 *  Styles for components
 *
 * @param {String} order
 *  Ascendant or descendant
 *
 * @param {String} orderBy
 *  The column used to order the rows
 *
 * @param {Function} onRequestSort
 *  Sort rows
 *
 * Columns of rows and select all checkbox
 */
const EnhancedTableHead = ({ order, orderBy, onRequestSort }) => {
	const theme = useTheme();
	const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));

	const { toggleSelectAll, clients, selected, setSelected } = useLocal();
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	const [headCells, setHeadCells] = React.useState([]);

	const getLabel = React.useCallback(() => {
		return cells.map((el) => ({
			...el,
			label: i18n.t(`Clients.headCells.${el.id}`),
		}));
	}, []);

	React.useEffect(() => {
		setHeadCells(getLabel());
	}, [getLabel]);

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {
					const display = !xsScreen || (headCell.mobile && xsScreen);

					return (
						display && (
							<TableCell
								key={headCell.id}
								align={"left"}
								sortDirection={orderBy === headCell.id ? order : false}
							>
								{headCell.id !== "select" ? (
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id)}
									>
										{headCell.label}
									</TableSortLabel>
								) : (
									<Grid container>
										<Grid item component="span">
											{xsScreen ? (
												"Drops"
											) : (
												<Checkbox
													icon={<PrintDisabled />}
													checkedIcon={<Print />}
													checked={selected}
													indeterminate={
														!clients
															.map((client) => client.active)
															.every((val, i, arr) => val === arr[0])
													}
													onChange={() => {
														setSelected(!selected);
														toggleSelectAll();
													}}
													inputProps={{ "aria-label": "select clients" }}
												/>
											)}
										</Grid>
									</Grid>
								)}
							</TableCell>
						)
					);
				})}
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
