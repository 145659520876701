import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";

const AlertNavigator = ({ pathname }) => {
	const [open, setOpen] = React.useState(true);

	const browserNotChrome = window.navigator.userAgent.indexOf("Chrome") < 0;

	return (
		pathname === "/maps-to-print" &&
		browserNotChrome && (
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				open={open}
				onClose={() => setOpen(false)}
				autoHideDuration={8000}
			>
				<Alert severity="warning" variant="filled">
					Print feature works better with Chrome
				</Alert>
			</Snackbar>
		)
	);
};

AlertNavigator.propTypes = {
	pathname: PropTypes.string.isRequired,
};
export default AlertNavigator;
