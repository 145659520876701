import * as React from "react";
import DeleteYardsConfirmation from "../../../../../components/DeleteYardsConfirmation";
import DropEditMap from "../../../../../components/DropEditMap";
import HeatmapYard from "../../../../../components/HeatMapYard";
import YardEditMap from "../../../../../components/YardEditMap";
import { useModal } from "../../../../../contexts/ModalContextProvider";
import YardCreateMap from "../YardCreateMap";

const YardsModalContent = ({
	createYard,
	yards,
	updateYard,
	selectedYardIds,
	updateYardDrops,
	getYardDrops,
	setSelectedYardIds,
	deleteYardsHandler,
}) => {
	const { modalType } = useModal();

	const r = () => {
		switch (modalType) {
			case "yardCreate":
				return <YardCreateMap createYard={createYard} />;
			case "yardEditMap":
				return (
					<YardEditMap
						yard={yards.find((y) => y.id === selectedYardIds[0])}
						updateYard={updateYard}
					/>
				);
			case "dropEditMap":
				return (
					<DropEditMap
						yard={yards.find((y) => y.id === selectedYardIds[0])}
						drops={yards.find((y) => y.id === selectedYardIds[0]).drops}
						updateYard={updateYardDrops}
						getYardDrops={getYardDrops}
					/>
				);
			case "deleteYards":
				return <DeleteYardsConfirmation onDelete={deleteYardsHandler} />;
			case "heatmap":
				return (
					<HeatmapYard
						selectedYardIds={selectedYardIds}
						setSelectedYardIds={setSelectedYardIds}
						yards={yards}
					/>
				);
			default:
				return null;
		}
	};

	return r();
};

export default YardsModalContent;
