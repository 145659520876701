const gradients = [
	[
		"rgba(255,0,0,0)",
		"rgba(255,0,0,1)",
		"rgba(255,0,0,1)",
		"rgba(255,0,0,1)",
		"rgba(255,0,0,1)",
		"rgba(255,0,0,1)",
	],
	[
		"rgba(255,165,0,0)",
		"rgba(255,165,0,1)",
		"rgba(255,165,0,1)",
		"rgba(255,165,0,1)",
		"rgba(255,165,0,1)",
		"rgba(255,165,0,1)",
	],
	[
		"rgba(255,255,0,0)",
		"rgba(255,255,0,1)",
		"rgba(255,255,0,1)",
		"rgba(255,255,0,1)",
		"rgba(255,255,0,1)",
		"rgba(255,255,0,1)",
	],
	[
		"rgba(0,255,0,0)",
		"rgba(0,255,0,1)",
		"rgba(0,255,0,1)",
		"rgba(0,255,0,1)",
		"rgba(0,255,0,1)",
		"rgba(0,255,0,1)",
	],
	[
		"rgba(0,0,255,0)",
		"rgba(0,0,255,1)",
		"rgba(0,0,255,1)",
		"rgba(0,0,255,1)",
		"rgba(0,0,255,1)",
		"rgba(0,0,255,1)",
	],
];

export default gradients;
