import {
	ButtonBase,
	Grid,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeOutlined from "@material-ui/icons/HomeOutlined";
import i18n from "i18next";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import paths from "../../App/paths";
import { useAccount } from "../../contexts/AccountContextProvider";
import { useNotification } from "../../contexts/NotificationContextProvider";
import AlertNavigator from "./components/AlertNavigator";

const useStyles = makeStyles((theme) =>
	createStyles({
		[`@media print`]: {
			toolbar: { display: "none" },
		},
		blackText: {
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			[theme.breakpoints.down("xs")]: {
				fontSize: theme.fontSizes.smedium,
			},
		},
		end: {
			marginLeft: "auto",
		},
		grey: {
			color: theme.colors.texts.primary,
		},
	}),
);

const Header = () => {
	const styles = useStyles();

	const theme = useTheme();
	const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));

	const { notifSuccess } = useNotification();
	const { pathname } = useLocation();

	const { isConnected, logOut } = useAccount();
	const navigate = useNavigate();
	const onLogOut = () => {
		logOut();
		notifSuccess("Header.profile.events.success.logOut");
	};
	const goBack = () => {
		navigate(-1);
	};

	const backButton = pathname.indexOf("all-yards") > -1;

	return (
		<>
			<Toolbar className={styles.toolbar}>
				<Grid container spacing={2}>
					{pathname !== "/" && (
						<Grid item>
							<ButtonBase
								className={styles.button}
								onClick={() => {
									navigate(paths.home.baseUrl);
								}}
							>
								<HomeOutlined
									fontSize={xsScreen ? "medium" : "small"}
									className={styles.grey}
								/>
								<Typography className={styles.blackText}>
									{i18n.t("Header.pages.clients")}
								</Typography>
							</ButtonBase>
						</Grid>
					)}
					{backButton && (
						<Grid item>
							<ButtonBase className={styles.button} onClick={goBack}>
								<ArrowBackIcon
									fontSize={xsScreen ? "medium" : "small"}
									className={styles.grey}
								/>
								<Typography className={styles.blackText}>
									{i18n.t("Header.profile.back")}
								</Typography>
							</ButtonBase>
						</Grid>
					)}
					{isConnected && (
						<Grid item className={styles.end}>
							<ButtonBase className={styles.button} onClick={onLogOut}>
								<ExitToAppIcon
									fontSize={xsScreen ? "medium" : "small"}
									className={styles.grey}
								/>
								<Typography className={styles.blackText}>
									{i18n.t("Header.profile.logOut")}
								</Typography>
							</ButtonBase>
						</Grid>
					)}
				</Grid>
			</Toolbar>
			<AlertNavigator pathname={pathname} />
		</>
	);
};

export default Header;
