import { Grid, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import MapIcon from "@material-ui/icons/Map";
import i18n from "i18next";
import * as React from "react";
import { useParams } from "react-router-dom";
import MetricsOnYard from "../../components/MetricsOnYard";
import PrintButton from "../../components/PrintButton";
import { useApi } from "../../contexts/ApiContextProvider";
import { useLocal } from "../../contexts/LocalContextProvider";
import { useNotification } from "../../contexts/NotificationContextProvider";
import getRegion from "../../utils/getRegions";
import Map from "./components/Map";

const useStyles = makeStyles((theme) =>
	createStyles({
		margin: {
			marginTop: theme.dimensions.indent,
		},
		oneYard: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "flex-start",
		},
		alignEnd: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "flex-end",
		},
	}),
);

/**
 * MapsToPrint component
 *
 * List of Google Maps of yard
 */
const MapPage = () => {
	const styles = useStyles();
	const theme = useTheme();
	const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));

	const { api } = useApi();
	const { clients } = useLocal();
	const { notifError } = useNotification();
	const { clientId } = useParams();

	const [yards, setYards] = React.useState([]);
	const [drops, setDrops] = React.useState([]);
	const [customers, setCustomers] = React.useState([]);
	const [mapsLoaded, setMapsLoaded] = React.useState(0);

	const [hasData, setHasData] = React.useState(false);

	const filterByClients = React.useCallback(
		(yards) => {
			const activeClients =
				clientId ||
				clients.flatMap((client) => (client.active ? client.id : null));

			return yards.filter(
				(yard) =>
					activeClients.indexOf(yard.customerId) > -1 && !!yard.drops.length,
			);
		},
		[clientId, clients],
	);

	React.useEffect(() => {
		if (!hasData) {
			api
				.backofficeGrowersYardsGet()
				.then(filterByClients)
				.then(setYards)
				.catch(() => notifError("MapPage.events.errors.getYards"));
			api
				.backofficeGrowersDropsGet()
				.then(setDrops)
				.catch(() => notifError("MapPage.events.errors.getDrops"));
			api
				.backofficeGrowersCustomersGet()
				.then(setCustomers)
				.then(() => setHasData(true))
				.catch(() => notifError("MapPage.events.errors.getCustomers"));
		}
	}, [api, hasData, filterByClients, notifError]);

	return (
		<Grid container className={styles.margin}>
			{!xsScreen && (
				<Grid item className={styles.alignEnd}>
					<PrintButton disabled={mapsLoaded < yards.length}>
						{mapsLoaded < yards.length ? <ArrowDownward /> : null}
					</PrintButton>
				</Grid>
			)}
			{yards.map((yard, index) => {
				const _drops = yard.drops.map((id) => drops.find((d) => d.id === id));
				const { lat, lng } = getRegion(yard);

				const linkToGetDirections = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`;

				return (
					<Grid container key={yard.id} className={styles.oneYard}>
						<Grid item xs={xsScreen ? 12 : 8}>
							<Map
								yard={yard}
								drops={_drops}
								mapLoaded={() => {
									setMapsLoaded(mapsLoaded + 1);
								}}
							/>
						</Grid>
						<Grid
							item
							container
							xs={xsScreen ? 12 : 4}
							direction={xsScreen ? "row" : "column"}
						>
							<Grid
								item
								container
								xs={12}
								wrap="wrap-reverse"
								justifyContent="flex-start"
								alignContent="space-around"
								direction="column"
							>
								{xsScreen && (
									<Grid item className={styles.header} xs={6}>
										<a
											target="_blank"
											rel="noreferrer"
											href={linkToGetDirections}
										>
											<Button
												size="large"
												aria-label="open map"
												variant="outlined"
											>
												<MapIcon />
												{i18n.t("MapPage.MetricsOnYard.openMap")}
											</Button>
										</a>
									</Grid>
								)}
								<Grid
									item
									xs={xsScreen ? 6 : 12}
									container
									alignItems="center"
									direction="column"
								>
									<Grid item xs={12}>
										<MetricsOnYard
											yard={yard}
											drops={_drops}
											customers={customers}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default MapPage;
