const columns = [
	{
		id: "name",
		numeric: false,
		sort: true,
	},
	{
		id: "crops",
		numeric: false,
		sort: true,
	},
	{
		id: "size",
		numeric: false,
		sort: true,
	},
	{
		id: "numberOfDrops",
		numeric: true,
		sort: true,
	},
	{
		id: "blank",
		numeric: false,
		sort: false,
	},
];

export default columns;
