/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Product from './Product';

/**
* The ProductInStock model module.
* @module model/ProductInStock
* @version 0.0.1
*/
export default class ProductInStock {
    /**
    * Constructs a new <code>ProductInStock</code>.
    * @alias module:model/ProductInStock
    * @class
    * @extends module:model/Product
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ProductInStock</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ProductInStock} obj Optional instance to populate.
    * @return {module:model/ProductInStock} The populated <code>ProductInStock</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductInStock();
            
            Product.constructFromObject(data, obj);
            
            if (data.hasOwnProperty('stockTotal')) {
                obj['stockTotal'] = ApiClient.convertToType(data['stockTotal'], 'Number');
            }
            if (data.hasOwnProperty('stockAvailable')) {
                obj['stockAvailable'] = ApiClient.convertToType(data['stockAvailable'], 'Number');
            }
        }
        return obj;
    }

    /**
    * Quantity total of beehives in stock
    * @member {Number} stockTotal
    */
    stockTotal = undefined;
    /**
    * Quantity of beehives in stock remaining to be placed on the maps
    * @member {Number} stockAvailable
    */
    stockAvailable = undefined;




}
