/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The ProductProposedUpdate model module.
* @module model/ProductProposedUpdate
* @version 0.0.1
*/
export default class ProductProposedUpdate {
    /**
    * Constructs a new <code>ProductProposedUpdate</code>.
    * A request from the beekeeper to update his product
    * @alias module:model/ProductProposedUpdate
    * @class
    */

    constructor() {



    }

    /**
    * Constructs a <code>ProductProposedUpdate</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ProductProposedUpdate} obj Optional instance to populate.
    * @return {module:model/ProductProposedUpdate} The populated <code>ProductProposedUpdate</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductProposedUpdate();


            if (data.hasOwnProperty('action')) {
                obj['action'] = ApiClient.convertToType(data['action'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('isDone')) {
                obj['isDone'] = ApiClient.convertToType(data['isDone'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * Action to applied to product [\"update\" or \"delete\"]
    * @member {String} action
    */
    action = undefined;
    /**
    * NotifMessage of beekeeper explaining why he wants delete this product
    * @member {String} message
    */
    message = undefined;
    /**
    * Indicates if action is done
    * @member {Boolean} isDone
    */
    isDone = undefined;




}
