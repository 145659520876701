/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Payment model module.
* @module model/Payment
* @version 0.0.1
*/
export default class Payment {
    /**
    * Constructs a new <code>Payment</code>.
    * @alias module:model/Payment
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Payment</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Payment} obj Optional instance to populate.
    * @return {module:model/Payment} The populated <code>Payment</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Payment();
                        
            
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('deposit')) {
                obj['deposit'] = ApiClient.convertToType(data['deposit'], 'Number');
            }
            if (data.hasOwnProperty('onDeliveryAmount')) {
                obj['onDeliveryAmount'] = ApiClient.convertToType(data['onDeliveryAmount'], 'Number');
            }
            if (data.hasOwnProperty('onPickUpAmount')) {
                obj['onPickUpAmount'] = ApiClient.convertToType(data['onPickUpAmount'], 'Number');
            }
            if (data.hasOwnProperty('depositIsPaid')) {
                obj['depositIsPaid'] = ApiClient.convertToType(data['depositIsPaid'], 'Boolean');
            }
            if (data.hasOwnProperty('onDeliveryAmountIsPaid')) {
                obj['onDeliveryAmountIsPaid'] = ApiClient.convertToType(data['onDeliveryAmountIsPaid'], 'Boolean');
            }
            if (data.hasOwnProperty('onPickUpAmountIsPaid')) {
                obj['onPickUpAmountIsPaid'] = ApiClient.convertToType(data['onPickUpAmountIsPaid'], 'Boolean');
            }
            if (data.hasOwnProperty('method')) {
                obj['method'] = ApiClient.convertToType(data['method'], 'String');
            }
            if (data.hasOwnProperty('stripePaymentSourceId')) {
                obj['stripePaymentSourceId'] = ApiClient.convertToType(data['stripePaymentSourceId'], 'String');
            }
            if (data.hasOwnProperty('stripeClientSecret')) {
                obj['stripeClientSecret'] = ApiClient.convertToType(data['stripeClientSecret'], 'String');
            }
            if (data.hasOwnProperty('stripePaymentIntentId')) {
                obj['stripePaymentIntentId'] = ApiClient.convertToType(data['stripePaymentIntentId'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
        }
        return obj;
    }

    /**
    * Price displayed in the front
    * @member {Number} price
    */
    price = undefined;
    /**
    * Deposit paid or to pay
    * @member {Number} deposit
    */
    deposit = undefined;
    /**
    * Amount paid on delivery
    * @member {Number} onDeliveryAmount
    */
    onDeliveryAmount = undefined;
    /**
    * Amount paid on pick up
    * @member {Number} onPickUpAmount
    */
    onPickUpAmount = undefined;
    /**
    * True if the deposit was paid
    * @member {Boolean} depositIsPaid
    */
    depositIsPaid = undefined;
    /**
    * True if the delivery amount was paid
    * @member {Boolean} onDeliveryAmountIsPaid
    */
    onDeliveryAmountIsPaid = undefined;
    /**
    * True if the pick up was paid
    * @member {Boolean} onPickUpAmountIsPaid
    */
    onPickUpAmountIsPaid = undefined;
    /**
    * Payment method used
    * @member {String} method
    */
    method = undefined;
    /**
    * Id of the payment source to use, retrieved from stripe
    * @member {String} stripePaymentSourceId
    */
    stripePaymentSourceId = undefined;
    /**
    * Client secret necessary to confirm payment
    * @member {String} stripeClientSecret
    */
    stripeClientSecret = undefined;
    /**
    * Stringified version of the intent
    * @member {String} stripePaymentIntentId
    */
    stripePaymentIntentId = undefined;
    /**
    * 'pending', 'confirmed' or 'rejected', depending of the payment outcome
    * @member {String} state
    */
    state = undefined;




}
