/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BasketMetadata from './BasketMetadata';
import Discount from './Discount';
import Option from './Option';
import ProductBought from './ProductBought';

/**
* The Basket model module.
* @module model/Basket
* @version 0.0.1
*/
export default class Basket {
    /**
    * Constructs a new <code>Basket</code>.
    * @alias module:model/Basket
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Basket</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Basket} obj Optional instance to populate.
    * @return {module:model/Basket} The populated <code>Basket</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Basket();
                        
            
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
            }
            if (data.hasOwnProperty('products')) {
                obj['products'] = ApiClient.convertToType(data['products'], [ProductBought]);
            }
            if (data.hasOwnProperty('options')) {
                obj['options'] = ApiClient.convertToType(data['options'], [Option]);
            }
            if (data.hasOwnProperty('discounts')) {
                obj['discounts'] = ApiClient.convertToType(data['discounts'], [Discount]);
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('deposit')) {
                obj['deposit'] = ApiClient.convertToType(data['deposit'], 'Number');
            }
            if (data.hasOwnProperty('metadata')) {
                obj['metadata'] = BasketMetadata.constructFromObject(data['metadata']);
            }
        }
        return obj;
    }

    /**
    * Customer id linked to the basket
    * @member {String} customerId
    */
    customerId = undefined;
    /**
    * @member {Array.<module:model/ProductBought>} products
    */
    products = undefined;
    /**
    * @member {Array.<module:model/Option>} options
    */
    options = undefined;
    /**
    * @member {Array.<module:model/Discount>} discounts
    */
    discounts = undefined;
    /**
    * Price of the basket
    * @member {Number} price
    */
    price = undefined;
    /**
    * Deposit required to secure this basket
    * @member {Number} deposit
    */
    deposit = undefined;
    /**
    * @member {module:model/BasketMetadata} metadata
    */
    metadata = undefined;




}
