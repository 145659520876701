import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import Map from "./components/Map";

/**
 * Dialog to edit a yard vertices
 *
 * This page display a yard and allows to place drop inside
 */
const YardEditMap = ({ yard, updateYard }) => {
	const addVertice = (coordinates) =>
		updateYard({
			update: { vertices: [...yard.vertices, coordinates] },
			yardId: yard.id,
		});

	const updateVertice = (coordinate, index) =>
		updateYard({
			update: {
				vertices: yard.vertices.map((v, i) => (i === index ? coordinate : v)),
			},
			yardId: yard.id,
		});

	const removeVertice = (index) =>
		updateYard({
			update: {
				vertices: yard.vertices.filter((v, i) => i !== index),
			},
			yardId: yard.id,
		});

	const onPressOnMap = (position) =>
		addVertice({
			latitude: position.latLng.lat(),
			longitude: position.latLng.lng(),
		});

	const onMarkerDragEnd = (event, index) =>
		updateVertice(
			{
				latitude: event.latLng.lat(),
				longitude: event.latLng.lng(),
			},
			index,
		);

	return (
		<Grid container style={{ height: "100%" }} alignItems="center">
			<Grid item xs={12} style={{ height: "calc(100% - 48px)" }}>
				<Map
					yard={yard}
					onPressOnMap={onPressOnMap}
					onMarkerDragEnd={onMarkerDragEnd}
					removeVertice={removeVertice}
				/>
			</Grid>
		</Grid>
	);
};

YardEditMap.propTypes = {
	yard: PropTypes.object.isRequired,
	updateYard: PropTypes.func.isRequired,
};

export default YardEditMap;
