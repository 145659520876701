import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import * as React from "react";
import descendingComparator from "../../../../../utils/descendingComparator";
import stableSort from "../../../../../utils/stableSort";
import TableHead from "./TableHead";
import TableRowYardLevel from "./TableRowYardLevel";

/**
 * YardsTable page
 *
 * Contains the table that lists all yards of the user.
 */
const YardsTable = ({
	yards,
	selectedYardIds,
	handleSelectAllClick,
	handleSelectOneClick,
	getYardDrops,
	updateYard,
}) => {
	const [sortedData, setSortedData] = React.useState([]);
	const [dataToDisplay, setDataToDisplay] = React.useState([]);

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("date");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	React.useEffect(() => {
		const comparator =
			order === "desc"
				? (a, b) => descendingComparator(a, b, orderBy)
				: (a, b) => -descendingComparator(a, b, orderBy);

		const sortedYards = stableSort(yards, comparator);
		setSortedData(sortedYards);
	}, [yards, order, orderBy]);

	React.useEffect(() => {
		const paginatedYards = sortedData.slice(
			page * rowsPerPage,
			page * rowsPerPage + rowsPerPage,
		);

		setDataToDisplay(paginatedYards);
	}, [sortedData, page, rowsPerPage]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => setPage(newPage);

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, yards.length - page * rowsPerPage);

	return (
		<TableContainer>
			<Table size="small">
				<TableHead
					numSelected={selectedYardIds.length}
					order={order}
					orderBy={orderBy}
					onSelectAllClick={handleSelectAllClick}
					onRequestSort={handleRequestSort}
					rowCount={yards.length}
				/>
				<TableBody>
					{dataToDisplay.map((yard) => {
						const isSelected = selectedYardIds.reduce(
							(isSelected, id) => isSelected || yard.id === id,
							false,
						);
						return (
							<TableRowYardLevel
								key={yard.id}
								yard={yard}
								updateYard={({ update }) =>
									updateYard({ update, yardId: yard.id })
								}
								onSelect={() => handleSelectOneClick(yard.id)}
								onExpand={() => getYardDrops(yard)}
								isSelected={isSelected}
								selectedYardIds={selectedYardIds}
							/>
						);
					})}
					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>
			</Table>
			<TablePagination
				variant="caption"
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={yards.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</TableContainer>
	);
};

YardsTable.propTypes = {
	yards: PropTypes.array.isRequired,
	selectedYardIds: PropTypes.array.isRequired,
	getYardDrops: PropTypes.func.isRequired,
	updateYard: PropTypes.func.isRequired,
	handleSelectAllClick: PropTypes.func.isRequired,
	handleSelectOneClick: PropTypes.func.isRequired,
};

export default YardsTable;
