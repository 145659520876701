import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) =>
	createStyles({
		textfield: {
			marginTop: theme.dimensions.indent / 2,
			width: "100%",
			backgroundColor: theme.colors.backgrounds.primary,
		},
		inputLabelText: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		input: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		red: {
			color: theme.colors.alert,
		},
	}),
);

const TextFieldOutlined = React.forwardRef(
	(
		{
			defaultValue,
			label,
			error,
			type,
			size = "medium",
			multiline,
			onBlur,
			onChange,
		},
		ref,
	) => {
		const styles = useStyles();
		return (
			<>
				<TextField
					ref={ref}
					label={label}
					defaultValue={defaultValue}
					variant="outlined"
					multiline={multiline}
					size={size}
					error={error}
					type={type}
					className={styles.textfield}
					InputLabelProps={{
						className: styles.inputLabelText,
					}}
					inputProps={{
						className: styles.input,
					}}
					onBlur={onBlur}
					onChange={onChange}
				/>
				<div className={styles.red}>{error?.message}</div>
			</>
		);
	},
);

TextFieldOutlined.displayName = "TextFieldOutlined";

TextFieldOutlined.propTypes = {
	label: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	error: PropTypes.bool,
	type: PropTypes.string,
	size: PropTypes.string,
	multiline: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
};

const ControlledTextFieldOutlined = ({
	field,
	label,
	defaultValue = "",
	error,
	type,
	size = "medium",
	multiline,

	onBlur = () => {},
	onChange = () => {},
	register,
	control,
}) => (
	<Controller
		{...register(field)}
		control={control}
		render={({ field }) => {
			const _onBlur = (e) => {
				onBlur(e);
				field.onBlur(e);
			};
			const _onChange = (e) => {
				onChange(e);
				field.onChange(e);
			};
			return (
				<TextFieldOutlined
					defaultValue={defaultValue}
					error={error}
					label={label}
					type={type}
					size={size}
					multiline={multiline}
					{...field}
					onBlur={_onBlur}
					onChange={_onChange}
				/>
			);
		}}
	/>
);

ControlledTextFieldOutlined.propTypes = {
	field: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	error: PropTypes.string,
	type: PropTypes.string,
	size: PropTypes.string,
	multiline: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	register: PropTypes.func.isRequired,
	control: PropTypes.object.isRequired,
};

export default ControlledTextFieldOutlined;
