/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import AccessToken from '../model/AccessToken';
import Basket from '../model/Basket';
import Beekeeper from '../model/Beekeeper';
import BeekeeperWithCustomerInformation from '../model/BeekeeperWithCustomerInformation';
import BeekeeperWithDetails from '../model/BeekeeperWithDetails';
import Company from '../model/Company';
import Contact from '../model/Contact';
import Contract from '../model/Contract';
import Credentials from '../model/Credentials';
import CreditCard from '../model/CreditCard';
import Customer from '../model/Customer';
import CustomerToUpdateData from '../model/CustomerToUpdateData';
import CustomerWithPassword from '../model/CustomerWithPassword';
import CustomerWithStripeId from '../model/CustomerWithStripeId';
import CustomerWithTokens from '../model/CustomerWithTokens';
import Discount from '../model/Discount';
import Drop from '../model/Drop';
import Email from '../model/Email';
import Mandate from '../model/Mandate';
import Message from '../model/Message';
import Option from '../model/Option';
import Order from '../model/Order';
import PasswordUpdate from '../model/PasswordUpdate';
import Payment from '../model/Payment';
import PaymentMean from '../model/PaymentMean';
import PaymentToBeekeeper from '../model/PaymentToBeekeeper';
import PaymentToBeekeeperWithDetails from '../model/PaymentToBeekeeperWithDetails';
import PaymentToBeekeeperWithProducts from '../model/PaymentToBeekeeperWithProducts';
import PlaidExchangedLinkToken from '../model/PlaidExchangedLinkToken';
import PlaidLinkToken from '../model/PlaidLinkToken';
import ProductAvailable from '../model/ProductAvailable';
import ProductBought from '../model/ProductBought';
import ProductInStock from '../model/ProductInStock';
import ProductProposed from '../model/ProductProposed';
import ProductProposedUpdate from '../model/ProductProposedUpdate';
import ProductProposedWithCustomer from '../model/ProductProposedWithCustomer';
import ProductProposedWithDetails from '../model/ProductProposedWithDetails';
import ProductProposedWithPayments from '../model/ProductProposedWithPayments';
import ProductWithDetails from '../model/ProductWithDetails';
import RefreshToken from '../model/RefreshToken';
import Simulation from '../model/Simulation';
import Tokens from '../model/Tokens';
import Yard from '../model/Yard';

/**
* Default service.
* @module api/DefaultApi
* @version 0.0.1
*/
export default class DefaultApi {

    /**
    * Constructs a new DefaultApi.
    * @alias module:api/DefaultApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create a firebase account and a customer in database.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerWithTokens} and HTTP response
     */
    accountPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CustomerWithTokens;

      return this.apiClient.callApi(
        '/account', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a firebase account and a customer in database.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerWithTokens}
     */
    accountPost(body) {
      return this.accountPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Asks firebase to send a verification email to the specified email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    accountRequestAddressVerificationEmailPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/account/request-address-verification-email', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Asks firebase to send a verification email to the specified email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    accountRequestAddressVerificationEmailPost(body) {
      return this.accountRequestAddressVerificationEmailPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Asks firebase to send a reset email to the specified email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    accountRequestPasswordResetEmailPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/account/request-password-reset-email', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Asks firebase to send a reset email to the specified email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    accountRequestPasswordResetEmailPost(body) {
      return this.accountRequestPasswordResetEmailPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get account details corresponding to the provided access token
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Customer} and HTTP response
     */
    appAccountGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Customer;

      return this.apiClient.callApi(
        '/app/account', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get account details corresponding to the provided access token
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Customer}
     */
    appAccountGet() {
      return this.appAccountGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update the customer&#x27;s password in firebase
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    appAccountPasswordPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/app/account/password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update the customer&#x27;s password in firebase
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    appAccountPasswordPost(body) {
      return this.appAccountPasswordPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update the account data, including in firebase if the email needs a change
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Customer} and HTTP response
     */
    appAccountPatchWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json', 'text/plain'];
      let returnType = Customer;

      return this.apiClient.callApi(
        '/app/account', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update the account data, including in firebase if the email needs a change
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Customer}
     */
    appAccountPatch(body) {
      return this.appAccountPatchWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the data of the connected beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Beekeeper} and HTTP response
     */
    appBeekeeperInformationGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Beekeeper;

      return this.apiClient.callApi(
        '/app/beekeeper/information', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the data of the connected beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Beekeeper}
     */
    appBeekeeperInformationGet() {
      return this.appBeekeeperInformationGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update the data on the beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Beekeeper} and HTTP response
     */
    appBeekeeperInformationPatchWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Beekeeper;

      return this.apiClient.callApi(
        '/app/beekeeper/information', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update the data on the beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Beekeeper}
     */
    appBeekeeperInformationPatch(body) {
      return this.appBeekeeperInformationPatchWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a beekeeper entry if the customer starts to use the beekeeper side of the marketplace
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Beekeeper} and HTTP response
     */
    appBeekeeperInformationPostWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Beekeeper;

      return this.apiClient.callApi(
        '/app/beekeeper/information', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a beekeeper entry if the customer starts to use the beekeeper side of the marketplace
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Beekeeper}
     */
    appBeekeeperInformationPost() {
      return this.appBeekeeperInformationPostWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the made to the connected beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/PaymentToBeekeeper>} and HTTP response
     */
    appBeekeeperPaymentToBeekeeperGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [PaymentToBeekeeper];

      return this.apiClient.callApi(
        '/app/beekeeper/payment-to-beekeeper', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the made to the connected beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/PaymentToBeekeeper>}
     */
    appBeekeeperPaymentToBeekeeperGet() {
      return this.appBeekeeperPaymentToBeekeeperGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the products the connected beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductProposed>} and HTTP response
     */
    appBeekeeperProductProposedGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductProposed];

      return this.apiClient.callApi(
        '/app/beekeeper/product-proposed', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the products the connected beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductProposed>}
     */
    appBeekeeperProductProposedGet() {
      return this.appBeekeeperProductProposedGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete the product if it is not marked as sold yet
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    appBeekeeperProductProposedIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/app/beekeeper/product-proposed/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete the product if it is not marked as sold yet
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    appBeekeeperProductProposedIdDelete(id) {
      return this.appBeekeeperProductProposedIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the products the connected beekeeper proposed
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductProposed} and HTTP response
     */
    appBeekeeperProductProposedIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProductProposed;

      return this.apiClient.callApi(
        '/app/beekeeper/product-proposed/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the products the connected beekeeper proposed
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductProposed}
     */
    appBeekeeperProductProposedIdGet(id) {
      return this.appBeekeeperProductProposedIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a product proposed corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductProposed} and HTTP response
     */
    appBeekeeperProductProposedIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProductProposed;

      return this.apiClient.callApi(
        '/app/beekeeper/product-proposed/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a product proposed corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductProposed}
     */
    appBeekeeperProductProposedIdPatch(body, id) {
      return this.appBeekeeperProductProposedIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Sends a query to update or delete the products fromn the marketplace
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductProposed} and HTTP response
     */
    appBeekeeperProductProposedIdUpdatesPostWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProductProposed;

      return this.apiClient.callApi(
        '/app/beekeeper/product-proposed/{id}/updates', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Sends a query to update or delete the products fromn the marketplace
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductProposed}
     */
    appBeekeeperProductProposedIdUpdatesPost(body, id) {
      return this.appBeekeeperProductProposedIdUpdatesPostWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a product that the beekeeper wants us to buy
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductProposed} and HTTP response
     */
    appBeekeeperProductProposedPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProductProposed;

      return this.apiClient.callApi(
        '/app/beekeeper/product-proposed', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a product that the beekeeper wants us to buy
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductProposed}
     */
    appBeekeeperProductProposedPost(body) {
      return this.appBeekeeperProductProposedPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Clear the updates array of the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    appGrowerBasketClearUpdatesPatchWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/app/grower/basket/clear-updates', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Clear the updates array of the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    appGrowerBasketClearUpdatesPatch() {
      return this.appGrowerBasketClearUpdatesPatchWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove an discount from the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    appGrowerBasketDiscountIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/app/grower/basket/discount/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove an discount from the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    appGrowerBasketDiscountIdDelete(id) {
      return this.appGrowerBasketDiscountIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add an discount to the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    appGrowerBasketDiscountPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/app/grower/basket/discount', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add an discount to the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    appGrowerBasketDiscountPost(body) {
      return this.appGrowerBasketDiscountPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the basket of the authentified user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    appGrowerBasketGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/app/grower/basket', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the basket of the authentified user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    appGrowerBasketGet() {
      return this.appGrowerBasketGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove an option from the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    appGrowerBasketOptionIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/app/grower/basket/option/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove an option from the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    appGrowerBasketOptionIdDelete(id) {
      return this.appGrowerBasketOptionIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add an option to the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    appGrowerBasketOptionPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/app/grower/basket/option', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add an option to the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    appGrowerBasketOptionPost(body) {
      return this.appGrowerBasketOptionPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Updates the quantity of a product which is in the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    appGrowerBasketPatchWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/app/grower/basket', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Updates the quantity of a product which is in the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    appGrowerBasketPatch(body) {
      return this.appGrowerBasketPatchWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add an item to the basket of the authentified user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    appGrowerBasketPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/app/grower/basket', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add an item to the basket of the authentified user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    appGrowerBasketPost(body) {
      return this.appGrowerBasketPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the companies of the authentified customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Company>} and HTTP response
     */
    appGrowerCompaniesGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Company];

      return this.apiClient.callApi(
        '/app/grower/companies', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the companies of the authentified customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Company>}
     */
    appGrowerCompaniesGet() {
      return this.appGrowerCompaniesGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete an company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    appGrowerCompaniesIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/app/grower/companies/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete an company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    appGrowerCompaniesIdDelete(id) {
      return this.appGrowerCompaniesIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the company corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Company} and HTTP response
     */
    appGrowerCompaniesIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Company;

      return this.apiClient.callApi(
        '/app/grower/companies/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the company corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Company}
     */
    appGrowerCompaniesIdGet(id) {
      return this.appGrowerCompaniesIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Company} and HTTP response
     */
    appGrowerCompaniesIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Company;

      return this.apiClient.callApi(
        '/app/grower/companies/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Company}
     */
    appGrowerCompaniesIdPatch(body, id) {
      return this.appGrowerCompaniesIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add a company associated to the authenticated users&#x27;
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Company} and HTTP response
     */
    appGrowerCompaniesPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Company;

      return this.apiClient.callApi(
        '/app/grower/companies', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add a company associated to the authenticated users&#x27;
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Company}
     */
    appGrowerCompaniesPost(body) {
      return this.appGrowerCompaniesPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns all the drops the user placed in his yards
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Drop>} and HTTP response
     */
    appGrowerDropsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Drop];

      return this.apiClient.callApi(
        '/app/grower/drops', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns all the drops the user placed in his yards
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Drop>}
     */
    appGrowerDropsGet() {
      return this.appGrowerDropsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the drop corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Drop} and HTTP response
     */
    appGrowerDropsIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Drop;

      return this.apiClient.callApi(
        '/app/grower/drops/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the drop corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Drop}
     */
    appGrowerDropsIdGet(id) {
      return this.appGrowerDropsIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a drop
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Drop} and HTTP response
     */
    appGrowerDropsIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Drop;

      return this.apiClient.callApi(
        '/app/grower/drops/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a drop
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Drop}
     */
    appGrowerDropsIdPatch(body, id) {
      return this.appGrowerDropsIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the orders of the authentified customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Order>} and HTTP response
     */
    appGrowerOrdersGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Order];

      return this.apiClient.callApi(
        '/app/grower/orders', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the orders of the authentified customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Order>}
     */
    appGrowerOrdersGet() {
      return this.appGrowerOrdersGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add company info the the order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    appGrowerOrdersIdAddCompanyPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/app/grower/orders/{id}/add-company', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add company info the the order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    appGrowerOrdersIdAddCompanyPatch(body, id) {
      return this.appGrowerOrdersIdAddCompanyPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Place order in delete state
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    appGrowerOrdersIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/app/grower/orders/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Place order in delete state
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    appGrowerOrdersIdDelete(id) {
      return this.appGrowerOrdersIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the order corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    appGrowerOrdersIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/app/grower/orders/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the order corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    appGrowerOrdersIdGet(id) {
      return this.appGrowerOrdersIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add payment info the the order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    appGrowerOrdersIdInitPaymentPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/app/grower/orders/{id}/init-payment', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add payment info the the order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    appGrowerOrdersIdInitPaymentPatch(body, id) {
      return this.appGrowerOrdersIdInitPaymentPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the order contract in a pdf file
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    appGrowerOrdersIdPdfGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/pdf', 'text/plain'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/app/grower/orders/{id}/pdf', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the order contract in a pdf file
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    appGrowerOrdersIdPdfGet(id) {
      return this.appGrowerOrdersIdPdfGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add contract info the the order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    appGrowerOrdersIdSignContractPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/app/grower/orders/{id}/sign-contract', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add contract info the the order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    appGrowerOrdersIdSignContractPatch(body, id) {
      return this.appGrowerOrdersIdSignContractPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Place an order on behalf of the authenticated used
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    appGrowerOrdersPostWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/app/grower/orders', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Place an order on behalf of the authenticated used
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    appGrowerOrdersPost() {
      return this.appGrowerOrdersPostWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the simulations of beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Simulation>} and HTTP response
     */
    appGrowerPollinationSimulationGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Simulation];

      return this.apiClient.callApi(
        '/app/grower/pollination-simulation', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the simulations of beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Simulation>}
     */
    appGrowerPollinationSimulationGet() {
      return this.appGrowerPollinationSimulationGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update the simulations of beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Simulation} and HTTP response
     */
    appGrowerPollinationSimulationIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Simulation;

      return this.apiClient.callApi(
        '/app/grower/pollination-simulation/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update the simulations of beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Simulation}
     */
    appGrowerPollinationSimulationIdPatch(body, id) {
      return this.appGrowerPollinationSimulationIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns all the products bought by the authenticated user, with available and used stocks
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductInStock>} and HTTP response
     */
    appGrowerStockGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductInStock];

      return this.apiClient.callApi(
        '/app/grower/stock', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns all the products bought by the authenticated user, with available and used stocks
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductInStock>}
     */
    appGrowerStockGet() {
      return this.appGrowerStockGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the cards attached to the customer&#x27;s account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CreditCard>} and HTTP response
     */
    appGrowerStripeCardsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CreditCard];

      return this.apiClient.callApi(
        '/app/grower/stripe/cards', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the cards attached to the customer&#x27;s account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreditCard>}
     */
    appGrowerStripeCardsGet() {
      return this.appGrowerStripeCardsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove the card from the customer&#x27;s account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    appGrowerStripeCardsIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/app/grower/stripe/cards/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove the card from the customer&#x27;s account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    appGrowerStripeCardsIdDelete(id) {
      return this.appGrowerStripeCardsIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Attach a card to the customer&#x27;s stripe account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreditCard} and HTTP response
     */
    appGrowerStripeCardsPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CreditCard;

      return this.apiClient.callApi(
        '/app/grower/stripe/cards', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Attach a card to the customer&#x27;s stripe account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreditCard}
     */
    appGrowerStripeCardsPost(body) {
      return this.appGrowerStripeCardsPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the mandates attached to the customer&#x27;s account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Mandate>} and HTTP response
     */
    appGrowerStripeMandatesGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Mandate];

      return this.apiClient.callApi(
        '/app/grower/stripe/mandates', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the mandates attached to the customer&#x27;s account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Mandate>}
     */
    appGrowerStripeMandatesGet() {
      return this.appGrowerStripeMandatesGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove the card from the customer&#x27;s account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    appGrowerStripeMandatesIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/app/grower/stripe/mandates/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove the card from the customer&#x27;s account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    appGrowerStripeMandatesIdDelete(id) {
      return this.appGrowerStripeMandatesIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a Plaid link token to verify a bank account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PlaidLinkToken} and HTTP response
     */
    appGrowerStripeMandatesLinkTokenGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PlaidLinkToken;

      return this.apiClient.callApi(
        '/app/grower/stripe/mandates/link-token', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get a Plaid link token to verify a bank account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PlaidLinkToken}
     */
    appGrowerStripeMandatesLinkTokenGet() {
      return this.appGrowerStripeMandatesLinkTokenGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Attach a banck account ACH mandate -provided by Plaid- to the customer&#x27;s stripe account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Mandate} and HTTP response
     */
    appGrowerStripeMandatesPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Mandate;

      return this.apiClient.callApi(
        '/app/grower/stripe/mandates', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Attach a banck account ACH mandate -provided by Plaid- to the customer&#x27;s stripe account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Mandate}
     */
    appGrowerStripeMandatesPost(body) {
      return this.appGrowerStripeMandatesPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the yards of the authentcated user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Yard>} and HTTP response
     */
    appGrowerYardsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Yard];

      return this.apiClient.callApi(
        '/app/grower/yards', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the yards of the authentcated user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Yard>}
     */
    appGrowerYardsGet() {
      return this.appGrowerYardsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    appGrowerYardsIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/app/grower/yards/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    appGrowerYardsIdDelete(id) {
      return this.appGrowerYardsIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete the drop and remove it from the yard&#x27;s drops list.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    appGrowerYardsIdDropsDropIdDeleteWithHttpInfo(id, dropId) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'dropId': dropId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/app/grower/yards/{id}/drops/{dropId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete the drop and remove it from the yard&#x27;s drops list.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    appGrowerYardsIdDropsDropIdDelete(id, dropId) {
      return this.appGrowerYardsIdDropsDropIdDeleteWithHttpInfo(id, dropId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a drop and add it to the yard&#x27;s drops list.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Drop} and HTTP response
     */
    appGrowerYardsIdDropsPostWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Drop;

      return this.apiClient.callApi(
        '/app/grower/yards/{id}/drops', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a drop and add it to the yard&#x27;s drops list.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Drop}
     */
    appGrowerYardsIdDropsPost(body, id) {
      return this.appGrowerYardsIdDropsPostWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the yard corresponding to the provided user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Yard} and HTTP response
     */
    appGrowerYardsIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Yard;

      return this.apiClient.callApi(
        '/app/grower/yards/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the yard corresponding to the provided user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Yard}
     */
    appGrowerYardsIdGet(id) {
      return this.appGrowerYardsIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Yard} and HTTP response
     */
    appGrowerYardsIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Yard;

      return this.apiClient.callApi(
        '/app/grower/yards/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Yard}
     */
    appGrowerYardsIdPatch(body, id) {
      return this.appGrowerYardsIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a yard.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Yard} and HTTP response
     */
    appGrowerYardsPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Yard;

      return this.apiClient.callApi(
        '/app/grower/yards', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a yard.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Yard}
     */
    appGrowerYardsPost(body) {
      return this.appGrowerYardsPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all beekeeper informations
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/BeekeeperWithCustomerInformation>} and HTTP response
     */
    backofficeBeekeepersInformationGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [BeekeeperWithCustomerInformation];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/information', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all beekeeper informations
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/BeekeeperWithCustomerInformation>}
     */
    backofficeBeekeepersInformationGet() {
      return this.backofficeBeekeepersInformationGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the customers who are not beekeepers
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Customer>} and HTTP response
     */
    backofficeBeekeepersInformationNotABeekeeperGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Customer];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/information/not-a-beekeeper', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the customers who are not beekeepers
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Customer>}
     */
    backofficeBeekeepersInformationNotABeekeeperGet() {
      return this.backofficeBeekeepersInformationNotABeekeeperGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a beekeeper information.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeBeekeepersInformationOfCustomerIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/information/of-customer/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a beekeeper information.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeBeekeepersInformationOfCustomerIdDelete(id) {
      return this.backofficeBeekeepersInformationOfCustomerIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get beekeeper informations corresponding to the provided customer id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BeekeeperWithCustomerInformation} and HTTP response
     */
    backofficeBeekeepersInformationOfCustomerIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = BeekeeperWithCustomerInformation;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/information/of-customer/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get beekeeper informations corresponding to the provided customer id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BeekeeperWithCustomerInformation}
     */
    backofficeBeekeepersInformationOfCustomerIdGet(id) {
      return this.backofficeBeekeepersInformationOfCustomerIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update the beekeeper&#x27;s information
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BeekeeperWithCustomerInformation} and HTTP response
     */
    backofficeBeekeepersInformationOfCustomerIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = BeekeeperWithCustomerInformation;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/information/of-customer/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update the beekeeper&#x27;s information
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BeekeeperWithCustomerInformation}
     */
    backofficeBeekeepersInformationOfCustomerIdPatch(body, id) {
      return this.backofficeBeekeepersInformationOfCustomerIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add beekeeper information on an existing customer account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BeekeeperWithCustomerInformation} and HTTP response
     */
    backofficeBeekeepersInformationPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = BeekeeperWithCustomerInformation;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/information', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add beekeeper information on an existing customer account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BeekeeperWithCustomerInformation}
     */
    backofficeBeekeepersInformationPost(body) {
      return this.backofficeBeekeepersInformationPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all beekeeper informations with their payment data
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/BeekeeperWithDetails>} and HTTP response
     */
    backofficeBeekeepersInformationWithDetailsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [BeekeeperWithDetails];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/information/with-details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all beekeeper informations with their payment data
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/BeekeeperWithDetails>}
     */
    backofficeBeekeepersInformationWithDetailsGet() {
      return this.backofficeBeekeepersInformationWithDetailsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the payment outgoing between Ubees and beekeepers
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/PaymentToBeekeeper>} and HTTP response
     */
    backofficeBeekeepersPaymentToBeekeeperGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [PaymentToBeekeeper];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/payment-to-beekeeper', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the payment outgoing between Ubees and beekeepers
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/PaymentToBeekeeper>}
     */
    backofficeBeekeepersPaymentToBeekeeperGet() {
      return this.backofficeBeekeepersPaymentToBeekeeperGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete payment outgoing
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeBeekeepersPaymentToBeekeeperIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/payment-to-beekeeper/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete payment outgoing
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeBeekeepersPaymentToBeekeeperIdDelete(id) {
      return this.backofficeBeekeepersPaymentToBeekeeperIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the payment outgoing corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaymentToBeekeeper} and HTTP response
     */
    backofficeBeekeepersPaymentToBeekeeperIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PaymentToBeekeeper;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/payment-to-beekeeper/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the payment outgoing corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaymentToBeekeeper}
     */
    backofficeBeekeepersPaymentToBeekeeperIdGet(id) {
      return this.backofficeBeekeepersPaymentToBeekeeperIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a payment corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaymentToBeekeeper} and HTTP response
     */
    backofficeBeekeepersPaymentToBeekeeperIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = PaymentToBeekeeper;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/payment-to-beekeeper/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a payment corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaymentToBeekeeper}
     */
    backofficeBeekeepersPaymentToBeekeeperIdPatch(body, id) {
      return this.backofficeBeekeepersPaymentToBeekeeperIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the payment outgoing corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaymentToBeekeeperWithProducts} and HTTP response
     */
    backofficeBeekeepersPaymentToBeekeeperIdWithProductDetailsGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PaymentToBeekeeperWithProducts;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/payment-to-beekeeper/{id}/with-product-details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the payment outgoing corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaymentToBeekeeperWithProducts}
     */
    backofficeBeekeepersPaymentToBeekeeperIdWithProductDetailsGet(id) {
      return this.backofficeBeekeepersPaymentToBeekeeperIdWithProductDetailsGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a payment outgoing
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PaymentToBeekeeper} and HTTP response
     */
    backofficeBeekeepersPaymentToBeekeeperPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = PaymentToBeekeeper;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/payment-to-beekeeper', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a payment outgoing
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PaymentToBeekeeper}
     */
    backofficeBeekeepersPaymentToBeekeeperPost(body) {
      return this.backofficeBeekeepersPaymentToBeekeeperPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the payment outgoing between Ubees and beekeepers
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/PaymentToBeekeeperWithDetails>} and HTTP response
     */
    backofficeBeekeepersPaymentToBeekeeperWithDetailsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [PaymentToBeekeeperWithDetails];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/payment-to-beekeeper/with-details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the payment outgoing between Ubees and beekeepers
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/PaymentToBeekeeperWithDetails>}
     */
    backofficeBeekeepersPaymentToBeekeeperWithDetailsGet() {
      return this.backofficeBeekeepersPaymentToBeekeeperWithDetailsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the products proposed by every beekeepers of the platform
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductProposed>} and HTTP response
     */
    backofficeBeekeepersProductProposedGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductProposed];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the products proposed by every beekeepers of the platform
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductProposed>}
     */
    backofficeBeekeepersProductProposedGet() {
      return this.backofficeBeekeepersProductProposedGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Set product \&quot;isDeleted\&quot; to true
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeBeekeepersProductProposedIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Set product \&quot;isDeleted\&quot; to true
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeBeekeepersProductProposedIdDelete(id) {
      return this.backofficeBeekeepersProductProposedIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the product proposed corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductProposed} and HTTP response
     */
    backofficeBeekeepersProductProposedIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProductProposed;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the product proposed corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductProposed}
     */
    backofficeBeekeepersProductProposedIdGet(id) {
      return this.backofficeBeekeepersProductProposedIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a product proposed corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductProposed} and HTTP response
     */
    backofficeBeekeepersProductProposedIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProductProposed;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a product proposed corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductProposed}
     */
    backofficeBeekeepersProductProposedIdPatch(body, id) {
      return this.backofficeBeekeepersProductProposedIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the product proposed corresponding to the provided id with payments
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductProposedWithPayments} and HTTP response
     */
    backofficeBeekeepersProductProposedIdWithPaymentsGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProductProposedWithPayments;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed/{id}/with-payments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the product proposed corresponding to the provided id with payments
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductProposedWithPayments}
     */
    backofficeBeekeepersProductProposedIdWithPaymentsGet(id) {
      return this.backofficeBeekeepersProductProposedIdWithPaymentsGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the products proposed the beekeeper of the platform
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductProposed>} and HTTP response
     */
    backofficeBeekeepersProductProposedOfCustomerIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductProposed];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed/of-customer/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the products proposed the beekeeper of the platform
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductProposed>}
     */
    backofficeBeekeepersProductProposedOfCustomerIdGet(id) {
      return this.backofficeBeekeepersProductProposedOfCustomerIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the products proposed the beekeeper of the platform
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductProposedWithPayments>} and HTTP response
     */
    backofficeBeekeepersProductProposedOfCustomerIdWithPaymentsGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductProposedWithPayments];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed/of-customer/{id}/with-payments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the products proposed the beekeeper of the platform
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductProposedWithPayments>}
     */
    backofficeBeekeepersProductProposedOfCustomerIdWithPaymentsGet(id) {
      return this.backofficeBeekeepersProductProposedOfCustomerIdWithPaymentsGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a product proposed
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductProposed} and HTTP response
     */
    backofficeBeekeepersProductProposedPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProductProposed;

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a product proposed
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductProposed}
     */
    backofficeBeekeepersProductProposedPost(body) {
      return this.backofficeBeekeepersProductProposedPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the products proposed by every beekeepers of the platform, with data on the customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductProposedWithCustomer>} and HTTP response
     */
    backofficeBeekeepersProductProposedWithCustomerDataGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductProposedWithCustomer];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed/with-customer-data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the products proposed by every beekeepers of the platform, with data on the customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductProposedWithCustomer>}
     */
    backofficeBeekeepersProductProposedWithCustomerDataGet() {
      return this.backofficeBeekeepersProductProposedWithCustomerDataGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the products proposed by every beekeepers of the platform, with data on the customers and on the payments
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductProposedWithDetails>} and HTTP response
     */
    backofficeBeekeepersProductProposedWithDetailsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductProposedWithDetails];

      return this.apiClient.callApi(
        '/backoffice/beekeepers/product-proposed/with-details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the products proposed by every beekeepers of the platform, with data on the customers and on the payments
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductProposedWithDetails>}
     */
    backofficeBeekeepersProductProposedWithDetailsGet() {
      return this.backofficeBeekeepersProductProposedWithDetailsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update called params of all provided contact form
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Boolean'} and HTTP response
     */
    backofficeContactFormCalledPatchWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'Boolean';

      return this.apiClient.callApi(
        '/backoffice/contact-form/called', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update called params of all provided contact form
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Boolean'}
     */
    backofficeContactFormCalledPatch(body) {
      return this.backofficeContactFormCalledPatchWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns all the contact forms
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Contact>} and HTTP response
     */
    backofficeContactFormGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Contact];

      return this.apiClient.callApi(
        '/backoffice/contact-form', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns all the contact forms
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Contact>}
     */
    backofficeContactFormGet() {
      return this.backofficeContactFormGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a form
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeContactFormIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/contact-form/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a form
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeContactFormIdDelete(id) {
      return this.backofficeContactFormIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the form corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Contact} and HTTP response
     */
    backofficeContactFormIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Contact;

      return this.apiClient.callApi(
        '/backoffice/contact-form/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the form corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Contact}
     */
    backofficeContactFormIdGet(id) {
      return this.backofficeContactFormIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a form
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Contact} and HTTP response
     */
    backofficeContactFormIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Contact;

      return this.apiClient.callApi(
        '/backoffice/contact-form/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a form
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Contact}
     */
    backofficeContactFormIdPatch(body, id) {
      return this.backofficeContactFormIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the entire content of the basket collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Basket>} and HTTP response
     */
    backofficeGrowersBasketsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Basket];

      return this.apiClient.callApi(
        '/backoffice/growers/baskets', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the entire content of the basket collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Basket>}
     */
    backofficeGrowersBasketsGet() {
      return this.backofficeGrowersBasketsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersBasketsOfCustomerIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/baskets/of-customer/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersBasketsOfCustomerIdDelete(id) {
      return this.backofficeGrowersBasketsOfCustomerIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove an discount from the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    backofficeGrowersBasketsOfCustomerIdDiscountsDiscountIdDeleteWithHttpInfo(id, discountId) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'discountId': discountId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/backoffice/growers/baskets/of-customer/{id}/discounts/{discountId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove an discount from the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    backofficeGrowersBasketsOfCustomerIdDiscountsDiscountIdDelete(id, discountId) {
      return this.backofficeGrowersBasketsOfCustomerIdDiscountsDiscountIdDeleteWithHttpInfo(id, discountId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add an discount to the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    backofficeGrowersBasketsOfCustomerIdDiscountsPostWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/backoffice/growers/baskets/of-customer/{id}/discounts', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add an discount to the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    backofficeGrowersBasketsOfCustomerIdDiscountsPost(body, id) {
      return this.backofficeGrowersBasketsOfCustomerIdDiscountsPostWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the basket data corresponding to the provided customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    backofficeGrowersBasketsOfCustomerIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/backoffice/growers/baskets/of-customer/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the basket data corresponding to the provided customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    backofficeGrowersBasketsOfCustomerIdGet(id) {
      return this.backofficeGrowersBasketsOfCustomerIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove an option from the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    backofficeGrowersBasketsOfCustomerIdOptionsOptionIdDeleteWithHttpInfo(id, optionId) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'optionId': optionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/backoffice/growers/baskets/of-customer/{id}/options/{optionId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Remove an option from the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    backofficeGrowersBasketsOfCustomerIdOptionsOptionIdDelete(id, optionId) {
      return this.backofficeGrowersBasketsOfCustomerIdOptionsOptionIdDeleteWithHttpInfo(id, optionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add an option to the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    backofficeGrowersBasketsOfCustomerIdOptionsPostWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/backoffice/growers/baskets/of-customer/{id}/options', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add an option to the basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    backofficeGrowersBasketsOfCustomerIdOptionsPost(body, id) {
      return this.backofficeGrowersBasketsOfCustomerIdOptionsPostWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Basket} and HTTP response
     */
    backofficeGrowersBasketsOfCustomerIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Basket;

      return this.apiClient.callApi(
        '/backoffice/growers/baskets/of-customer/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a basket
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Basket}
     */
    backofficeGrowersBasketsOfCustomerIdPatch(body, id) {
      return this.backofficeGrowersBasketsOfCustomerIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a basket.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Basket>} and HTTP response
     */
    backofficeGrowersBasketsPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [Basket];

      return this.apiClient.callApi(
        '/backoffice/growers/baskets', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a basket.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Basket>}
     */
    backofficeGrowersBasketsPost(body) {
      return this.backofficeGrowersBasketsPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the entire content of the order collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Company>} and HTTP response
     */
    backofficeGrowersCompaniesGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Company];

      return this.apiClient.callApi(
        '/backoffice/growers/companies', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the entire content of the order collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Company>}
     */
    backofficeGrowersCompaniesGet() {
      return this.backofficeGrowersCompaniesGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete an company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersCompaniesIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/companies/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete an company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersCompaniesIdDelete(id) {
      return this.backofficeGrowersCompaniesIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the company corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Company} and HTTP response
     */
    backofficeGrowersCompaniesIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Company;

      return this.apiClient.callApi(
        '/backoffice/growers/companies/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the company corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Company}
     */
    backofficeGrowersCompaniesIdGet(id) {
      return this.backofficeGrowersCompaniesIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Company} and HTTP response
     */
    backofficeGrowersCompaniesIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Company;

      return this.apiClient.callApi(
        '/backoffice/growers/companies/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a company
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Company}
     */
    backofficeGrowersCompaniesIdPatch(body, id) {
      return this.backofficeGrowersCompaniesIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the entire content of the company collection linked to specified customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Company>} and HTTP response
     */
    backofficeGrowersCompaniesOfCustomerCustomerIdGetWithHttpInfo(customerId) {
      let postBody = null;

      let pathParams = {
        'customerId': customerId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Company];

      return this.apiClient.callApi(
        '/backoffice/growers/companies/of-customer/{customerId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the entire content of the company collection linked to specified customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Company>}
     */
    backofficeGrowersCompaniesOfCustomerCustomerIdGet(customerId) {
      return this.backofficeGrowersCompaniesOfCustomerCustomerIdGetWithHttpInfo(customerId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add a company and associate it with the spedified user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Company} and HTTP response
     */
    backofficeGrowersCompaniesOfCustomerCustomerIdPostWithHttpInfo(body, customerId) {
      let postBody = body;

      let pathParams = {
        'customerId': customerId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Company;

      return this.apiClient.callApi(
        '/backoffice/growers/companies/of-customer/{customerId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add a company and associate it with the spedified user
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Company}
     */
    backofficeGrowersCompaniesOfCustomerCustomerIdPost(body, customerId) {
      return this.backofficeGrowersCompaniesOfCustomerCustomerIdPostWithHttpInfo(body, customerId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the entire content of the customer collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CustomerWithStripeId>} and HTTP response
     */
    backofficeGrowersCustomersGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CustomerWithStripeId];

      return this.apiClient.callApi(
        '/backoffice/growers/customers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the entire content of the customer collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CustomerWithStripeId>}
     */
    backofficeGrowersCustomersGet() {
      return this.backofficeGrowersCustomersGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersCustomersIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/customers/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersCustomersIdDelete(id) {
      return this.backofficeGrowersCustomersIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the customer provided by id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CustomerWithStripeId} and HTTP response
     */
    backofficeGrowersCustomersIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CustomerWithStripeId;

      return this.apiClient.callApi(
        '/backoffice/growers/customers/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the customer provided by id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CustomerWithStripeId}
     */
    backofficeGrowersCustomersIdGet(id) {
      return this.backofficeGrowersCustomersIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a customer&#x27;s data
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Customer} and HTTP response
     */
    backofficeGrowersCustomersIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Customer;

      return this.apiClient.callApi(
        '/backoffice/growers/customers/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a customer&#x27;s data
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Customer}
     */
    backofficeGrowersCustomersIdPatch(body, id) {
        const bodyMapped = {...body, name: body.contact}
      return this.backofficeGrowersCustomersIdPatchWithHttpInfo(bodyMapped, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * create new customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Customer} and HTTP response
     */
    backofficeGrowersCustomersPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Customer;

      return this.apiClient.callApi(
        '/backoffice/growers/customers', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * create new customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Customer}
     */
    backofficeGrowersCustomersPost(body) {
      return this.backofficeGrowersCustomersPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete the discount
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersDiscountsIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/discounts/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete the discount
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersDiscountsIdDelete(id) {
      return this.backofficeGrowersDiscountsIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a discount.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Discount} and HTTP response
     */
    backofficeGrowersDiscountsPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Discount;

      return this.apiClient.callApi(
        '/backoffice/growers/discounts', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a discount.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Discount}
     */
    backofficeGrowersDiscountsPost(body) {
      return this.backofficeGrowersDiscountsPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the entire content of the drop collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Drop>} and HTTP response
     */
    backofficeGrowersDropsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Drop];

      return this.apiClient.callApi(
        '/backoffice/growers/drops', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the entire content of the drop collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Drop>}
     */
    backofficeGrowersDropsGet() {
      return this.backofficeGrowersDropsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a drop
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersDropsIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/drops/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a drop
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersDropsIdDelete(id) {
      return this.backofficeGrowersDropsIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the drop corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Drop} and HTTP response
     */
    backofficeGrowersDropsIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Drop;

      return this.apiClient.callApi(
        '/backoffice/growers/drops/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the drop corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Drop}
     */
    backofficeGrowersDropsIdGet(id) {
      return this.backofficeGrowersDropsIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a drop
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Drop} and HTTP response
     */
    backofficeGrowersDropsIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Drop;

      return this.apiClient.callApi(
        '/backoffice/growers/drops/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a drop
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Drop}
     */
    backofficeGrowersDropsIdPatch(body, id) {
      return this.backofficeGrowersDropsIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the drops of the provided customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Drop>} and HTTP response
     */
    backofficeGrowersDropsOfCustomerCustomerIdGetWithHttpInfo(customerId) {
      let postBody = null;

      let pathParams = {
        'customerId': customerId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Drop];

      return this.apiClient.callApi(
        '/backoffice/growers/drops/of-customer/{customerId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the drops of the provided customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Drop>}
     */
    backofficeGrowersDropsOfCustomerCustomerIdGet(customerId) {
      return this.backofficeGrowersDropsOfCustomerCustomerIdGetWithHttpInfo(customerId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a drop.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Drop} and HTTP response
     */
    backofficeGrowersDropsPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Drop;

      return this.apiClient.callApi(
        '/backoffice/growers/drops', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a drop.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Drop}
     */
    backofficeGrowersDropsPost(body) {
      return this.backofficeGrowersDropsPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete the option
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersOptionsIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/options/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete the option
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersOptionsIdDelete(id) {
      return this.backofficeGrowersOptionsIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create an option.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Option} and HTTP response
     */
    backofficeGrowersOptionsPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Option;

      return this.apiClient.callApi(
        '/backoffice/growers/options', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create an option.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Option}
     */
    backofficeGrowersOptionsPost(body) {
      return this.backofficeGrowersOptionsPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the entire content of the order collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Order>} and HTTP response
     */
    backofficeGrowersOrdersGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Order];

      return this.apiClient.callApi(
        '/backoffice/growers/orders', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the entire content of the order collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Order>}
     */
    backofficeGrowersOrdersGet() {
      return this.backofficeGrowersOrdersGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete an order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersOrdersIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/orders/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete an order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersOrdersIdDelete(id) {
      return this.backofficeGrowersOrdersIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the order corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    backofficeGrowersOrdersIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/backoffice/growers/orders/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the order corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    backofficeGrowersOrdersIdGet(id) {
      return this.backofficeGrowersOrdersIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    backofficeGrowersOrdersIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/backoffice/growers/orders/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a order
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    backofficeGrowersOrdersIdPatch(body, id) {
      return this.backofficeGrowersOrdersIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the order contract in a pdf file
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link 'Blob'} and HTTP response
     */
    backofficeGrowersOrdersIdPdfGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/pdf', 'text/plain'];
      let returnType = 'Blob';

      return this.apiClient.callApi(
        '/backoffice/growers/orders/{id}/pdf', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the order contract in a pdf file
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link 'Blob'}
     */
    backofficeGrowersOrdersIdPdfGet(id) {
      return this.backofficeGrowersOrdersIdPdfGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the entire content of the order collection linked to specified customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Order>} and HTTP response
     */
    backofficeGrowersOrdersOfCustomerCustomerIdGetWithHttpInfo(customerId) {
      let postBody = null;

      let pathParams = {
        'customerId': customerId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Order];

      return this.apiClient.callApi(
        '/backoffice/growers/orders/of-customer/{customerId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the entire content of the order collection linked to specified customer.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Order>}
     */
    backofficeGrowersOrdersOfCustomerCustomerIdGet(customerId) {
      return this.backofficeGrowersOrdersOfCustomerCustomerIdGetWithHttpInfo(customerId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all simulations
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Simulation>} and HTTP response
     */
    backofficeGrowersPollinationSimulationGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Simulation];

      return this.apiClient.callApi(
        '/backoffice/growers/pollination-simulation', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all simulations
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Simulation>}
     */
    backofficeGrowersPollinationSimulationGet() {
      return this.backofficeGrowersPollinationSimulationGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update the simulations of beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Simulation} and HTTP response
     */
    backofficeGrowersPollinationSimulationIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Simulation;

      return this.apiClient.callApi(
        '/backoffice/growers/pollination-simulation/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update the simulations of beekeeper
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Simulation}
     */
    backofficeGrowersPollinationSimulationIdPatch(body, id) {
      return this.backofficeGrowersPollinationSimulationIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a product
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersProductsIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/products/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a product
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersProductsIdDelete(id) {
      return this.backofficeGrowersProductsIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the product with details corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductWithDetails} and HTTP response
     */
    backofficeGrowersProductsIdDetailsGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProductWithDetails;

      return this.apiClient.callApi(
        '/backoffice/growers/products/{id}/details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the product with details corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductWithDetails}
     */
    backofficeGrowersProductsIdDetailsGet(id) {
      return this.backofficeGrowersProductsIdDetailsGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the product corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductAvailable} and HTTP response
     */
    backofficeGrowersProductsIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProductAvailable;

      return this.apiClient.callApi(
        '/backoffice/growers/products/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the product corresponding to the provided id.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductAvailable}
     */
    backofficeGrowersProductsIdGet(id) {
      return this.backofficeGrowersProductsIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a product
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductAvailable} and HTTP response
     */
    backofficeGrowersProductsIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProductAvailable;

      return this.apiClient.callApi(
        '/backoffice/growers/products/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a product
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductAvailable}
     */
    backofficeGrowersProductsIdPatch(body, id) {
      return this.backofficeGrowersProductsIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a product.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductAvailable} and HTTP response
     */
    backofficeGrowersProductsPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ProductAvailable;

      return this.apiClient.callApi(
        '/backoffice/growers/products', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a product.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductAvailable}
     */
    backofficeGrowersProductsPost(body) {
      return this.backofficeGrowersProductsPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the stock of the customer, with available and used stocks
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductInStock>} and HTTP response
     */
    backofficeGrowersStocksOfCustomerCustomerIdGetWithHttpInfo(customerId) {
      let postBody = null;

      let pathParams = {
        'customerId': customerId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductInStock];

      return this.apiClient.callApi(
        '/backoffice/growers/stocks/of-customer/{customerId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the stock of the customer, with available and used stocks
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductInStock>}
     */
    backofficeGrowersStocksOfCustomerCustomerIdGet(customerId) {
      return this.backofficeGrowersStocksOfCustomerCustomerIdGetWithHttpInfo(customerId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the entire content of the yard collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Yard>} and HTTP response
     */
    backofficeGrowersYardsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Yard];
      return this.apiClient.callApi(
        '/backoffice/growers/yards', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the entire content of the yard collection.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Yard>}
     */
    backofficeGrowersYardsGet() {
      return this.backofficeGrowersYardsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersYardsIdDeleteWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/yards/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersYardsIdDelete(id) {
      return this.backofficeGrowersYardsIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete the drop and remove it from the yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Message} and HTTP response
     */
    backofficeGrowersYardsIdDropsDropIdDeleteWithHttpInfo(id, dropId) {
      let postBody = null;

      let pathParams = {
        'id': id,
        'dropId': dropId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Message;

      return this.apiClient.callApi(
        '/backoffice/growers/yards/{id}/drops/{dropId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete the drop and remove it from the yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Message}
     */
    backofficeGrowersYardsIdDropsDropIdDelete(id, dropId) {
      return this.backofficeGrowersYardsIdDropsDropIdDeleteWithHttpInfo(id, dropId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the drops of the provided yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Drop>} and HTTP response
     */
    backofficeGrowersYardsIdDropsGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Drop];

      return this.apiClient.callApi(
        '/backoffice/growers/yards/{id}/drops', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the drops of the provided yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Drop>}
     */
    backofficeGrowersYardsIdDropsGet(id) {
      return this.backofficeGrowersYardsIdDropsGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Add a drop to the yard.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Drop} and HTTP response
     */
    backofficeGrowersYardsIdDropsPostWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Drop;

      return this.apiClient.callApi(
        '/backoffice/growers/yards/{id}/drops', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add a drop to the yard.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Drop}
     */
    backofficeGrowersYardsIdDropsPost(body, id) {
      return this.backofficeGrowersYardsIdDropsPostWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the yard corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Yard} and HTTP response
     */
    backofficeGrowersYardsIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Yard;

      return this.apiClient.callApi(
        '/backoffice/growers/yards/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the yard corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Yard}
     */
    backofficeGrowersYardsIdGet(id) {
      return this.backofficeGrowersYardsIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Yard} and HTTP response
     */
    backofficeGrowersYardsIdPatchWithHttpInfo(body, id) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Yard;

      return this.apiClient.callApi(
        '/backoffice/growers/yards/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a yard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Yard}
     */
    backofficeGrowersYardsIdPatch(body, id) {
      return this.backofficeGrowersYardsIdPatchWithHttpInfo(body, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the yards of the provided customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Yard>} and HTTP response
     */
    backofficeGrowersYardsOfCustomerCustomerIdGetWithHttpInfo(customerId) {
      let postBody = null;

      let pathParams = {
        'customerId': customerId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Yard];

      return this.apiClient.callApi(
        '/backoffice/growers/yards/of-customer/{customerId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the yards of the provided customer
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Yard>}
     */
    backofficeGrowersYardsOfCustomerCustomerIdGet(customerId) {
      return this.backofficeGrowersYardsOfCustomerCustomerIdGetWithHttpInfo(customerId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a yard.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Yard} and HTTP response
     */
    backofficeGrowersYardsPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Yard;

      return this.apiClient.callApi(
        '/backoffice/growers/yards', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a yard.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Yard}
     */
    backofficeGrowersYardsPost(body) {
      return this.backofficeGrowersYardsPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a contact form.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Contact} and HTTP response
     */
    contactFormPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Contact;

      return this.apiClient.callApi(
        '/contact-form', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a contact form.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Contact}
     */
    contactFormPost(body) {
      return this.contactFormPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Renew an access token with request to firebase API
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AccessToken} and HTTP response
     */
    oauthRefreshPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = AccessToken;

      return this.apiClient.callApi(
        '/oauth/refresh', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Renew an access token with request to firebase API
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AccessToken}
     */
    oauthRefreshPost(body) {
      return this.oauthRefreshPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Exchange user credentails for access and refresh token
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Tokens} and HTTP response
     */
    oauthTokensPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Tokens;

      return this.apiClient.callApi(
        '/oauth/tokens', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Exchange user credentails for access and refresh token
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Tokens}
     */
    oauthTokensPost(body) {
      return this.oauthTokensPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the simulation corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Simulation} and HTTP response
     */
    pollinationSimulationIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Simulation;

      return this.apiClient.callApi(
        '/pollination-simulation/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the simulation corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Simulation}
     */
    pollinationSimulationIdGet(id) {
      return this.pollinationSimulationIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a simulation
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Simulation} and HTTP response
     */
    pollinationSimulationPostWithHttpInfo(body) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['firebase'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Simulation;

      return this.apiClient.callApi(
        '/pollination-simulation', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a simulation
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Simulation}
     */
    pollinationSimulationPost(body) {
      return this.pollinationSimulationPostWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns all the products on the market, with their availability.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ProductAvailable>} and HTTP response
     */
    productsGetWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ProductAvailable];

      return this.apiClient.callApi(
        '/products', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns all the products on the market, with their availability.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ProductAvailable>}
     */
    productsGet() {
      return this.productsGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns all the discounts that can be applied to the basket.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Discount>} and HTTP response
     */
    productsIdDiscountsGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Discount];

      return this.apiClient.callApi(
        '/products/{id}/discounts', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns all the discounts that can be applied to the basket.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Discount>}
     */
    productsIdDiscountsGet(id) {
      return this.productsIdDiscountsGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the product corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductAvailable} and HTTP response
     */
    productsIdGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProductAvailable;

      return this.apiClient.callApi(
        '/products/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the product corresponding to the provided id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductAvailable}
     */
    productsIdGet(id) {
      return this.productsIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns all the options that can be applied to the product.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Option>} and HTTP response
     */
    productsIdOptionsGetWithHttpInfo(id) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Option];

      return this.apiClient.callApi(
        '/products/{id}/options', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns all the options that can be applied to the product.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Option>}
     */
    productsIdOptionsGet(id) {
      return this.productsIdOptionsGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }

}
