import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import RootContextProvider from "./contexts/RootContextProvider";
import initI18n from "./i18n";
import "./index.css";

initI18n();

ReactDOM.render(
	<RootContextProvider>
		<App />
	</RootContextProvider>,
	document.getElementById("root"),
);
