import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import NumberItem from "./NumberItem";

const useStyles = makeStyles((theme) =>
	createStyles({
		products: {
			maxHeight: 400,
			overflowY: "scroll",
		},
	}),
);

const NUMBER_OF_BEEHIVES = Array.from(Array(50).keys()).filter(
	(n) => (n % 4 === 0 || n % 6 === 0) && n > 0,
);

const SelectProduct = ({
	products,
	productFob,
	onProductUpdate,
	numberOfBeehives,
	onNumberOfBeehivesUpdate,
}) => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<div className={styles.products}>
				<Typography variant="subtitle2">
					{i18n.t("components.DropEditMap.SelectProduct.selectNbOfHives")}
				</Typography>
				<div>
					{NUMBER_OF_BEEHIVES.map((nb) => (
						<NumberItem
							key={nb}
							number={nb}
							selected={nb === numberOfBeehives}
							onClick={() => onNumberOfBeehivesUpdate(nb)}
						/>
					))}
				</div>
			</div>
			<div className={styles.products}>
				<Typography variant="subtitle2">
					{i18n.t("components.DropEditMap.SelectProduct.selectNbOfFrames")}
				</Typography>
				{products.map((p) => (
					<NumberItem
						key={p.id}
						number={p.fobAverage}
						selected={p.fobAverage === productFob}
						onClick={() => onProductUpdate(p)}
					/>
				))}
			</div>
		</div>
	);
};

SelectProduct.propTypes = {
	products: PropTypes.array.isRequired,
	productFob: PropTypes.number,
	onProductUpdate: PropTypes.func,
	numberOfBeehives: PropTypes.number,
	onNumberOfBeehivesUpdate: PropTypes.func,
};

export default SelectProduct;
