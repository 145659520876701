import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import getRegion from "../../utils/getRegions";
import metrics from "../../utils/metrics";
import MetricsTitle from "./components/MetricsTitle";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			padding: theme.dimensions.indent / 2,
			display: "flex",
			flexDirection: "column",
			alignContent: "center",
		},
		border: {
			[theme.breakpoints.down("xs")]: {
				borderBottom: "1px dashed #cecece",
			},
		},
	}),
);

const MetricsOnYard = ({ yard, drops, customers = [] }) => {
	const styles = useStyles();

	const [hivesPerAcre, setHivesPerAcre] = useState(null);
	const numberOfDrops = drops.length;
	const numberOfBeehives = metrics.numberOfBeehives(drops);
	const fobAverage = metrics.fobAverage(drops);
	const customer = customers.find((c) => yard.customerId === c.id);

	useEffect(() => {
		if (drops) {
			setHivesPerAcre(
				yard.size > 0 ? metrics.hivesPerAcre(yard.size, drops) : "-",
			);
		}
	}, [drops, yard.size]);

	const { lat, lng } = getRegion(yard);
	const linkToGetDirections = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`;

	return (
		<Grid container item className={styles.container} spacing={4}>
			{customer && (
				<Grid item container alignItems="flex-start" spacing={1}>
					<Grid item xs={8}>
						<MetricsTitle yardName={yard.name} customer={customer} />
					</Grid>
					<Grid item xs={4}>
						<QRCode value={linkToGetDirections} size={80} />
					</Grid>
				</Grid>
			)}
			<Grid item container xs={12}>
				<Grid item xs={8}>
					<Typography
						variant="subtitle2"
						align="left"
						className={styles.border}
					>
						{i18n.t("MapPage.MetricsOnYard.drops")}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle1" display="inline">
						{numberOfDrops}
					</Typography>
				</Grid>
				<Grid item xs={8}>
					<Typography
						variant="subtitle2"
						align="left"
						className={styles.border}
					>
						{i18n.t("MapPage.MetricsOnYard.beehives")}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle1">{numberOfBeehives}</Typography>
				</Grid>
				<Grid item xs={8}>
					<Typography
						variant="subtitle2"
						align="left"
						className={styles.border}
					>
						{i18n.t("MapPage.MetricsOnYard.size")}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle1">{yard.size}</Typography>
				</Grid>
				{!fobAverage && (
					<>
						<Grid item xs={8}>
							<Typography
								variant="subtitle2"
								align="left"
								className={styles.border}
							>
								{i18n.t("MapPage.MetricsOnYard.fobAverage")}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography variant="subtitle1">{fobAverage}</Typography>
						</Grid>
					</>
				)}
				<Grid item xs={8}>
					<Typography
						variant="subtitle2"
						align="left"
						className={styles.border}
					>
						{i18n.t("MapPage.MetricsOnYard.hivesPerAcre")}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle1">{hivesPerAcre}</Typography>
				</Grid>
				<Grid item xs={8}>
					<Typography
						variant="subtitle2"
						align="left"
						className={styles.border}
					>
						{i18n.t("MapPage.MetricsOnYard.crops")}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle1">{yard.crops}</Typography>
				</Grid>
				{yard.additionalInfo && (
					<Grid item container alignItems="baseline">
						<Grid item xs={3} align="left">
							<Typography variant="subtitle2">
								{i18n.t("MapPage.MetricsOnYard.note")}
							</Typography>
						</Grid>
						<Grid item xs={9}>
							<Typography align="left" variant="body2">
								{yard.additionalInfo}
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

MetricsOnYard.propTypes = {
	yard: PropTypes.object.isRequired,
	drops: PropTypes.array.isRequired,
	customers: PropTypes.array,
};

export default MetricsOnYard;
