/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProductUpdate from './ProductUpdate';

/**
* The BasketMetadata model module.
* @module model/BasketMetadata
* @version 0.0.1
*/
export default class BasketMetadata {
    /**
    * Constructs a new <code>BasketMetadata</code>.
    * @alias module:model/BasketMetadata
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>BasketMetadata</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/BasketMetadata} obj Optional instance to populate.
    * @return {module:model/BasketMetadata} The populated <code>BasketMetadata</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BasketMetadata();
                        
            
            if (data.hasOwnProperty('updates')) {
                obj['updates'] = ApiClient.convertToType(data['updates'], [ProductUpdate]);
            }
        }
        return obj;
    }

    /**
    * List of modifications on product list of basket
    * @member {Array.<module:model/ProductUpdate>} updates
    */
    updates = undefined;




}
