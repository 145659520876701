import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import * as React from "react";

/**
 * On row representing one yard
 *
 * Can be expanded to see drops' details.
 */
const TableRowDrop = ({ drop }) => {
	return (
		<TableRow>
			<TableCell align="left">{`#${drop.index}`}</TableCell>
			<TableCell align="left">{drop.numberOfBeehives}</TableCell>
			<TableCell align="left">{drop.fobAverage}</TableCell>
		</TableRow>
	);
};

TableRowDrop.propTypes = {
	drop: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TableRowDrop;
