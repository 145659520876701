import PropTypes from "prop-types";
import * as React from "react";
import { useModal } from "../../../contexts/ModalContextProvider";
import ClientCreate from "./ClientCreate";
import ClientDelete from "./ClientDelete";

const ClientsModalContent = ({ refreshData, clientToDelete }) => {
	const { openModal, modalType } = useModal();

	const r = () => {
		switch (modalType) {
			case "newClient":
				return (
					<ClientCreate
						refreshData={refreshData}
						onQuit={() => {
							openModal(false);
						}}
					/>
				);
			case "deleteClient":
				return (
					<ClientDelete
						refreshData={refreshData}
						onQuit={() => {
							openModal(false);
						}}
						client={clientToDelete}
					/>
				);
			default:
				return null;
		}
	};
	return r();
};

ClientsModalContent.propTypes = {
	refreshData: PropTypes.func.isRequired,
	clientTodelete: PropTypes.object,
};
export default ClientsModalContent;
