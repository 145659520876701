/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Customer from './Customer';
import Tokens from './Tokens';

/**
* The CustomerWithTokens model module.
* @module model/CustomerWithTokens
* @version 0.0.1
*/
export default class CustomerWithTokens {
    /**
    * Constructs a new <code>CustomerWithTokens</code>.
    * Object returned when the user creates his account
    * @alias module:model/CustomerWithTokens
    * @class
    * @extends module:model/Customer
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>CustomerWithTokens</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CustomerWithTokens} obj Optional instance to populate.
    * @return {module:model/CustomerWithTokens} The populated <code>CustomerWithTokens</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerWithTokens();
            
            Customer.constructFromObject(data, obj);
            
            if (data.hasOwnProperty('accessToken')) {
                obj['accessToken'] = ApiClient.convertToType(data['accessToken'], 'String');
            }
            if (data.hasOwnProperty('refreshToken')) {
                obj['refreshToken'] = ApiClient.convertToType(data['refreshToken'], 'String');
            }
        }
        return obj;
    }

    /**
    * An oauth2 access token, used to access data on the backend
    * @member {String} accessToken
    */
    accessToken = undefined;
    /**
    * An oauth2 refresh token, used to renew the access token when it expires
    * @member {String} refreshToken
    */
    refreshToken = undefined;




}
