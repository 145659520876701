import { FormLabel } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core/";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import inside from "point-in-polygon";
import PropTypes from "prop-types";
import * as React from "react";
import HeatMapForm from "../../App/Client/components/ClientYards/YardsTable/HeatMapForm";
import { useApi } from "../../contexts/ApiContextProvider";
import { useNotification } from "../../contexts/NotificationContextProvider";
import Map from "./components/Map";
import SelectProduct from "./components/SelectProduct";
import YardInfo from "./components/YardInfo";

const useStyles = makeStyles((theme) =>
	createStyles({
		itemContainer: {
			margin: theme.dimensions.indent / 2,
		},
	}),
);

/**
 * DropMap page component
 *
 * This page display a yard and allows to place drop inside
 */
const DropEditMap = ({ yard, drops, updateYard, getYardDrops = () => {} }) => {
	const styles = useStyles();
	const { api } = useApi();
	const { notifWarning, notifError, notifSuccess } = useNotification();

	const [products, setProducts] = React.useState([]);

	const [initializedYard, setInitializedYard] = React.useState();

	const [selectedDrop, setSelectedDrop] = React.useState();
	const [selectedProduct, setSelectedProduct] = React.useState({});
	const [
		selectedNumberOfBeehives,
		setSelectedNumberOfBeehives,
	] = React.useState(12);

	const [productsLoaded, setProductsLoaded] = React.useState(false);

	/**
	 * Get call products with different number of frames
	 */
	React.useEffect(() => {
		if (!productsLoaded)
			api
				.productsGet()
				.then((productList) => {
					let distinctFrames = [];
					let products = [];
					productList.forEach((p) => {
						if (!distinctFrames.includes(p.fobAverage)) {
							distinctFrames.push(p.fobAverage);
							products.push(p);
						}
					});
					setProducts(products.sort((p1, p2) => p1.fobAverage - p2.fobAverage));
					setSelectedProduct(products[0]);
					setProductsLoaded(true);
				})
				.catch(() =>
					notifWarning("components.DropEditMap.events.errors.getProducts"),
				);
	}, [api, productsLoaded, notifWarning]);

	/**
	 * Get drops
	 */
	React.useEffect(() => {
		// We only fetch the drops if we are looking at a new yard
		if (initializedYard?.id !== yard.id) {
			setInitializedYard(yard);
			if (drops.length > 1 && typeof drops[0] === "string") {
				getYardDrops(yard);
			}
		}
	}, [getYardDrops, yard, initializedYard, drops]);

	const onClickOnMap = (coordinate) => {
		if (selectedDrop) setSelectedDrop();
		addDrop(coordinate);
	};

	/**
	 * Check that the coordinates entered are inside the yard
	 */
	const checkCoordinateValidity = (coordinates) => {
		if (
			!yard ||
			!coordinates ||
			!inside(
				[coordinates.latitude, coordinates.longitude],
				yard.vertices.map((v) => [v.latitude, v.longitude]),
			)
		) {
			notifWarning("components.DropEditMap.events.errors.coordinatesNotValid");
			return false;
		} else {
			return true;
		}
	};

	/**
	 * Drop management functions
	 */
	const addDrop = (coordinates) => {
		const newDrop = {
			coordinate: coordinates,
			numberOfBeehives: selectedNumberOfBeehives,
			productId: selectedProduct.id,
			fobAverage: selectedProduct.fobAverage,
			locationOrigin: selectedProduct.locationOrigin,
		};
		if (!checkCoordinateValidity(coordinates)) {
			return;
		}
		api
			.backofficeGrowersYardsIdDropsPost(newDrop, yard.id)
			.then((drop) => updateYard({ yard, drops: [...drops, drop] }))
			.then(() => notifSuccess("components.DropEditMap.events.success.addDrop"))
			.catch(() => notifError("components.DropEditMap.events.errors.addDrop"));
	};

	const updateDrop = (drop, { product, numberOfBeehives, coordinate }) => {
		const update = {};
		if (product) {
			update["productId"] = product.id;
			update["fobAverage"] = product.fobAverage;
			update["locationOrigin"] = product.locationOrigin;
		}
		if (numberOfBeehives) {
			update["numberOfBeehives"] = numberOfBeehives;
		}
		if (coordinate && checkCoordinateValidity(coordinate)) {
			update["coordinate"] = coordinate;
		}
		api
			.backofficeGrowersDropsIdPatch(update, drop.id)
			.then((drop) => {
				updateYard({
					yard,
					drops: drops.map((d) => (d.id === drop.id ? drop : d)),
				});
				setSelectedDrop(drop);
			})
			.then(() =>
				notifSuccess("components.DropEditMap.events.success.updateDrop"),
			)
			.catch(() =>
				notifError("components.DropEditMap.events.errors.updateDrop"),
			);
	};

	const deleteDrop = () =>
		api
			.backofficeGrowersYardsIdDropsDropIdDelete(yard.id, selectedDrop.id)
			.then(() => setSelectedDrop())
			.then(() =>
				updateYard({
					yard,
					drops: drops.filter((d) => d.id !== selectedDrop.id),
				}),
			)
			.then(() =>
				notifSuccess("components.DropEditMap.events.success.deleteDrop"),
			)
			.catch(() =>
				notifError("components.DropEditMap.events.errors.deleteDrop"),
			);

	const onMarkerDragEnd = async (drop, position) =>
		updateDrop(drop, {
			coordinate: {
				latitude: position.latLng.lat(),
				longitude: position.latLng.lng(),
			},
		});

	return (
		<Grid container alignItems="center">
			<Grid item xs={9} style={{ height: "calc(100vh - 48px)" }}>
				<Map
					yard={yard}
					drops={drops}
					selectedDrop={selectedDrop}
					onClickOnMap={onClickOnMap}
					onClickOnMarker={setSelectedDrop}
					onCloseMarkerPopup={() => setSelectedDrop()}
					onMarkerDragEnd={onMarkerDragEnd}
					deleteDrop={deleteDrop}
				/>
			</Grid>
			<Grid item xs={3}>
				<YardInfo yard={yard} drops={drops} />
				<Grid item className={styles.itemContainer}>
					<HeatMapForm>
						<FormLabel id="heatmap-type">
							<Typography variant="subtitle1">
								{i18n.t("Client.Yards.heatmap.button")}
							</Typography>
						</FormLabel>
					</HeatMapForm>
				</Grid>
				<Grid item className={styles.itemContainer}>
					<Typography variant="subtitle1">
						{selectedDrop
							? i18n.t("components.DropEditMap.editSelectedDrop")
							: i18n.t("components.DropEditMap.YardInfo.placePins")}
					</Typography>
					<SelectProduct
						products={products}
						productFob={
							selectedDrop === undefined
								? selectedProduct?.fobAverage
								: selectedDrop.fobAverage
						}
						numberOfBeehives={
							selectedDrop === undefined
								? selectedNumberOfBeehives
								: selectedDrop.numberOfBeehives
						}
						onProductUpdate={
							selectedDrop === undefined
								? setSelectedProduct
								: (product) => updateDrop(selectedDrop, { product })
						}
						onNumberOfBeehivesUpdate={
							selectedDrop === undefined
								? setSelectedNumberOfBeehives
								: (numberOfBeehives) =>
										updateDrop(selectedDrop, { numberOfBeehives })
						}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

DropEditMap.propTypes = {
	yard: PropTypes.object.isRequired,
	drops: PropTypes.array.isRequired,
	updateYard: PropTypes.func.isRequired,
	getYardDrops: PropTypes.func,
};

export default DropEditMap;
