import PropTypes from "prop-types";
import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useAccount } from "../contexts/AccountContextProvider";
import Client from "./Client";
import Clients from "./Clients";
import MapsToPrint from "./MapsToPrint";
import paths from "./paths";
import SignIn from "./SignIn";
import Yards from "./Yards";

/**
 * This component is the main wrapper for the rest of the application
 */
const Router = ({ children }) => {
	const { isConnected } = useAccount();

	return (
		<BrowserRouter>
			{children}
			<Routes>
				<Route
					exact
					path={paths.home.baseUrl}
					element={isConnected ? <Clients /> : <SignIn />}
				/>
				<Route
					path={paths.clients.baseUrl + paths.clients.getById.baseUrl}
					element={isConnected ? <Client /> : <SignIn />}
				/>
				<Route
					path={
						paths.clients.baseUrl +
						paths.clients.getById.baseUrl +
						paths.clients.getById.allYards
					}
					element={isConnected ? <Yards /> : <SignIn />}
				/>
				<Route
					exact
					path={paths.mapsToPrint.baseUrl}
					element={isConnected ? <MapsToPrint /> : <SignIn />}
				/>
				<Route
					exact
					path={paths.mapsToPrint.baseUrl + paths.mapsToPrint.getById.baseUrl}
					element={isConnected ? <MapsToPrint /> : <SignIn />}
				/>
				<Route element={isConnected ? <Clients /> : <SignIn />} />
			</Routes>
		</BrowserRouter>
	);
};

Router.propTypes = {
	children: PropTypes.array.isRequired,
};
export default Router;
