import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import columns from "./columns";

/**
 * YardsTableHead component for yards table.
 *
 * @param {Object} styles
 *  Styles for components
 *
 * @param {Function} onSelectAllClick
 *  Select or deselect all rows
 *
 * @param {String} order
 *  Ascendant or descendant
 *
 * @param {String} orderBy
 *  The column used to order the rows
 *
 * @param {Number} numSelected
 *  Row Selected
 *
 * @param {Number} rowCount
 *  Total rows
 *
 * @param {Function} onRequestSort
 *  Sort rows
 *
 * Columns of rows and select all checkbox
 */
const YardsTableHead = ({
	onSelectAllClick,
	order,
	orderBy,
	numSelected,
	rowCount,
	onRequestSort,
}) => {
	const [headCells, setHeadCells] = React.useState([]);
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	const getLabel = React.useCallback(
		() =>
			columns.map((el) => ({
				...el,
				label: i18n.t(`Client.Yards.YardsTable.header.${el.id}`),
			})),
		[],
	);

	React.useEffect(() => {
		setHeadCells(getLabel());
	}, [getLabel]);

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ "aria-label": "select all desserts" }}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align="center"
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{headCell.sort ? (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
							</TableSortLabel>
						) : (
							<div>{headCell.label}</div>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

YardsTableHead.propTypes = {
	styles: PropTypes.object,
	numSelected: PropTypes.number,
	onRequestSort: PropTypes.func,
	onSelectAllClick: PropTypes.func,
	order: PropTypes.oneOf(["asc", "desc"]),
	orderBy: PropTypes.string,
	rowCount: PropTypes.number,
};

export default YardsTableHead;
