/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProductProposedUpdate from './ProductProposedUpdate';

/**
* The ProductProposed model module.
* @module model/ProductProposed
* @version 0.0.1
*/
export default class ProductProposed {
    /**
    * Constructs a new <code>ProductProposed</code>.
    * Product that a beekeeper wants to sell to Ubees
    * @alias module:model/ProductProposed
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ProductProposed</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ProductProposed} obj Optional instance to populate.
    * @return {module:model/ProductProposed} The populated <code>ProductProposed</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductProposed();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
            }
            if (data.hasOwnProperty('operationSize')) {
                obj['operationSize'] = ApiClient.convertToType(data['operationSize'], 'Number');
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('fobAverage')) {
                obj['fobAverage'] = ApiClient.convertToType(data['fobAverage'], 'Number');
            }
            if (data.hasOwnProperty('locationOrigin')) {
                obj['locationOrigin'] = ApiClient.convertToType(data['locationOrigin'], 'String');
            }
            if (data.hasOwnProperty('locationOverwinter')) {
                obj['locationOverwinter'] = ApiClient.convertToType(data['locationOverwinter'], 'String');
            }
            if (data.hasOwnProperty('rating')) {
                obj['rating'] = ApiClient.convertToType(data['rating'], 'Number');
            }
            if (data.hasOwnProperty('seasonAvailability')) {
                obj['seasonAvailability'] = ApiClient.convertToType(data['seasonAvailability'], 'String');
            }
            if (data.hasOwnProperty('equipmentKind')) {
                obj['equipmentKind'] = ApiClient.convertToType(data['equipmentKind'], 'String');
            }
            if (data.hasOwnProperty('palletSize')) {
                obj['palletSize'] = ApiClient.convertToType(data['palletSize'], 'String');
            }
            if (data.hasOwnProperty('isSold')) {
                obj['isSold'] = ApiClient.convertToType(data['isSold'], 'Boolean');
            }
            if (data.hasOwnProperty('dateOfSale')) {
                obj['dateOfSale'] = ApiClient.convertToType(data['dateOfSale'], 'String');
            }
            if (data.hasOwnProperty('isDeleted')) {
                obj['isDeleted'] = ApiClient.convertToType(data['isDeleted'], 'Boolean');
            }
            if (data.hasOwnProperty('updates')) {
                obj['updates'] = ApiClient.convertToType(data['updates'], [ProductProposedUpdate]);
            }
        }
        return obj;
    }

    /**
    * Id of the product
    * @member {String} id
    */
    id = undefined;
    /**
    * id of the customer affiliated with the product
    * @member {String} customerId
    */
    customerId = undefined;
    /**
    * The size of the beekeeper's stocks
    * @member {Number} operationSize
    */
    operationSize = undefined;
    /**
    * Number of beehives that the beekeeper wants to sell us
    * @member {Number} quantity
    */
    quantity = undefined;
    /**
    * Price of such beehive available to the user (in $)
    * @member {Number} price
    */
    price = undefined;
    /**
    * Theoretical FOB average of the beehives
    * @member {Number} fobAverage
    */
    fobAverage = undefined;
    /**
    * From which region the hive come from
    * @member {String} locationOrigin
    */
    locationOrigin = undefined;
    /**
    * Location where beekeeper overwinter his hives
    * @member {String} locationOverwinter
    */
    locationOverwinter = undefined;
    /**
    * Rating ranging from zero to five
    * @member {Number} rating
    */
    rating = undefined;
    /**
    * Period / season in which the product is available for rental
    * @member {String} seasonAvailability
    */
    seasonAvailability = undefined;
    /**
    * Kind of equipment [\"single-deep-hive\", \"double-deep-hive\", \"super-deep-hive\"]
    * @member {String} equipmentKind
    */
    equipmentKind = undefined;
    /**
    * Size of pallet [\"4-ways\", \"6-ways\"],
    * @member {String} palletSize
    */
    palletSize = undefined;
    /**
    * Indicates if products is sold or not
    * @member {Boolean} isSold
    */
    isSold = undefined;
    /**
    * Date at which we accepted the sale
    * @member {String} dateOfSale
    */
    dateOfSale = undefined;
    /**
    * Indicates if products is deleted or not
    * @member {Boolean} isDeleted
    */
    isDeleted = undefined;
    /**
    * List of updates wanted by the beekeeper
    * @member {Array.<module:model/ProductProposedUpdate>} updates
    */
    updates = undefined;




}
