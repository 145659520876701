const getRegion = (yard) => {
	const latitude = yard.vertices.reduce(
		(latitudes, vertice) => [...latitudes, vertice.latitude],
		[],
	);
	const longitude = yard.vertices.reduce(
		(longitudes, vertice) => [...longitudes, vertice.longitude],
		[],
	);
	const minLat = Math.min(...latitude);
	const maxLat = Math.max(...latitude);
	const minLng = Math.min(...longitude);
	const maxLng = Math.max(...longitude);
	return { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 };
};

export default getRegion;
