import { FormControl, FormControlLabel } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useLocal } from "../../../../../contexts/LocalContextProvider";

const HeatMapForm = ({ children: title }) => {
	const { heatmap, setHeatmap } = useLocal();

	return (
		<FormControl>
			{title}
			<RadioGroup
				row
				aria-labelledby="Heatmap-typel"
				defaultValue="nope"
				name="radio-heatmap-type"
				onChange={(e) => setHeatmap(e.target.value)}
			>
				<FormControlLabel
					value="nope"
					control={<Radio size="small" checked={heatmap === "nope"} />}
					label={i18n.t("Client.Yards.heatmap.hidden")}
				/>
				<FormControlLabel
					value="area"
					control={<Radio size="small" checked={heatmap === "area"} />}
					label={i18n.t("Client.Yards.heatmap.byArea")}
				/>
			</RadioGroup>
		</FormControl>
	);
};

HeatMapForm.propTypes = {
	children: PropTypes.object,
};

export default HeatMapForm;
