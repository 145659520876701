import { withStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../../../../theme/theme";
import columns from "./columns";

const StyledCell = withStyles({
	head: {
		backgroundColor: theme.colors.backgrounds.darkWhite,
	},
})(TableCell);

/**
 * YardsTableHead component for yards table.
 *
 * @param {String} order
 *  Ascendant or descendant
 *
 * @param {String} orderBy
 *  The column used to order the rows
 *
 * @param {Function} onRequestSort
 *  Sort rows
 *
 * Columns of rows and select all checkbox
 */
const YardsTableHead = ({ order, orderBy, onRequestSort }) => {
	const [headCells, setHeadCells] = React.useState([]);
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	const getLabel = React.useCallback(
		() =>
			columns.map((el) => ({
				...el,
				label: i18n.t(`Client.Yards.DropsDetails.header.${el.id}`),
			})),
		[],
	);

	React.useEffect(() => {
		setHeadCells(getLabel());
	}, [getLabel]);

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<StyledCell
						key={headCell.id}
						align="left"
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{headCell.id === "drop" ? (
							<div>{headCell.label}</div>
						) : (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
							</TableSortLabel>
						)}
					</StyledCell>
				))}
			</TableRow>
		</TableHead>
	);
};

YardsTableHead.propTypes = {
	onRequestSort: PropTypes.func,
	order: PropTypes.oneOf(["asc", "desc"]),
	orderBy: PropTypes.string,
};

export default YardsTableHead;
