import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { GoogleMap, InfoWindow, Marker, Polygon } from "@react-google-maps/api";
import PropTypes from "prop-types";
import * as React from "react";
import GM_OPTIONS from "../../../../constants/googlemapsOptions";
import { useLocal } from "../../../../contexts/LocalContextProvider";
import PinIcon from "../../../../images/pin.svg";
import theme from "../../../../theme/theme";
import WindowTable from "../WindowTable";
import YardsHeatMap from "../YardsHeatMap";

const useStyles = makeStyles((theme) =>
	createStyles({
		markerLabel: {
			fontSize: theme.fontSizes.xbig,
			fontFamily: theme.fonts.bold,
			marginBottom: 15,
			backgroundColor: "#f0d169",
			width: 20,
		},
	}),
);

const MapComponent = ({
	yard,
	drops,
	selectedDrop,
	onClickOnMap,
	onClickOnMarker,
	onCloseMarkerPopup,
	onMarkerDragEnd,
	deleteDrop,
}) => {
	const styles = useStyles();
	const { heatmap } = useLocal();

	const [currentRegion, setCurrentRegion] = React.useState();
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [map, setMap] = React.useState(null);
	const [zoom, setZoom] = React.useState(15);

	/**
	 * Center map on the yard on load
	 */
	React.useEffect(() => {
		if (yard && yard.vertices.length > 0 && !isLoaded) {
			const region = yard.vertices.reduce(
				(region, v) => ({
					lat: region.lat + v.latitude,
					lng: region.lng + v.longitude,
				}),
				{
					lat: 0,
					lng: 0,
				},
			);
			setCurrentRegion({
				lat: region.lat / yard.vertices.length,
				lng: region.lng / yard.vertices.length,
			});
			setIsLoaded(true);
		}
	}, [yard, isLoaded]);

	const loadHandler = (map) => {
		// save map to use it in onZoomChanged()
		setMap(map);
	};

	const zoomHandler = () => {
		if (map) setZoom(map.getZoom());
	};

	return (
		<GoogleMap
			center={currentRegion}
			mapContainerStyle={{ height: "100%" }}
			onClick={(position) =>
				onClickOnMap({
					latitude: position.latLng.lat(),
					longitude: position.latLng.lng(),
				})
			}
			zoom={zoom}
			onZoomChanged={zoomHandler}
			onLoad={loadHandler}
			options={GM_OPTIONS}
		>
			{heatmap === "area" && <YardsHeatMap yards={[yard]} zoom={zoom} />}

			{drops.map((drop) => (
				<Marker
					name="Current location"
					key={drop.id}
					icon={PinIcon}
					position={{
						lat: drop.coordinate.latitude,
						lng: drop.coordinate.longitude,
					}}
					draggable
					onClick={() => onClickOnMarker(drop)}
					onDragEnd={(position) => onMarkerDragEnd(drop, position)}
					label={{
						text: `${drop.numberOfBeehives}`,
						color: "black",
						className: styles.markerLabel,
					}}
				>
					{selectedDrop?.id === drop.id && (
						<InfoWindow onCloseClick={onCloseMarkerPopup}>
							<Grid container direction="column">
								<Grid item>
									<WindowTable drop={drop} deleteDrop={deleteDrop} />
								</Grid>
							</Grid>
						</InfoWindow>
					)}
				</Marker>
			))}

			<Polygon
				key={yard.id}
				path={yard.vertices.map((el) => ({
					lat: el.latitude,
					lng: el.longitude,
				}))}
				options={{
					strokeColor: theme.colors.map.polygonStroke,
					fillColor: theme.colors.map.polygonFill,
					clickable: false,
				}}
			/>
		</GoogleMap>
	);
};

MapComponent.propTypes = {
	yard: PropTypes.object,
	drops: PropTypes.array,
	selectedDrop: PropTypes.object,
	onClickOnMap: PropTypes.func,
	onCloseMarkerPopup: PropTypes.func,
	onClickOnMarker: PropTypes.func,
	onMarkerDragEnd: PropTypes.func,
	deleteDrop: PropTypes.func,
};

export default MapComponent;
