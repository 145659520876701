/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The SimulationRows model module.
* @module model/SimulationRows
* @version 0.0.1
*/
export default class SimulationRows {
    /**
    * Constructs a new <code>SimulationRows</code>.
    * @alias module:model/SimulationRows
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>SimulationRows</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SimulationRows} obj Optional instance to populate.
    * @return {module:model/SimulationRows} The populated <code>SimulationRows</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SimulationRows();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('variety')) {
                obj['variety'] = ApiClient.convertToType(data['variety'], 'String');
            }
        }
        return obj;
    }

    /**
    * Id of the row, representing the number of the row
    * @member {Number} id
    */
    id = undefined;
    /**
    * Variety of tree in a row
    * @member {String} variety
    */
    variety = undefined;




}
