/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Customer from './Customer';

/**
* The Details model module.
* @module model/Details
* @version 0.0.1
*/
export default class Details {
    /**
    * Constructs a new <code>Details</code>.
    * @alias module:model/Details
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Details</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Details} obj Optional instance to populate.
    * @return {module:model/Details} The populated <code>Details</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Details();
                        
            
            if (data.hasOwnProperty('customer')) {
                obj['customer'] = Customer.constructFromObject(data['customer']);
            }
            if (data.hasOwnProperty('hivesBooked')) {
                obj['hivesBooked'] = ApiClient.convertToType(data['hivesBooked'], 'Number');
            }
            if (data.hasOwnProperty('hivesPaid')) {
                obj['hivesPaid'] = ApiClient.convertToType(data['hivesPaid'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {module:model/Customer} customer
    */
    customer = undefined;
    /**
    * Number of beehives booked by customer
    * @member {Number} hivesBooked
    */
    hivesBooked = undefined;
    /**
    * Number of beehives paid by customer
    * @member {Number} hivesPaid
    */
    hivesPaid = undefined;




}
