/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SimulationBloomOverTime from './SimulationBloomOverTime';
import SimulationVisitsOverTime from './SimulationVisitsOverTime';

/**
* The SimulationResult model module.
* @module model/SimulationResult
* @version 0.0.1
*/
export default class SimulationResult {
    /**
    * Constructs a new <code>SimulationResult</code>.
    * @alias module:model/SimulationResult
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>SimulationResult</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SimulationResult} obj Optional instance to populate.
    * @return {module:model/SimulationResult} The populated <code>SimulationResult</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SimulationResult();
                        
            
            if (data.hasOwnProperty('year')) {
                obj['year'] = ApiClient.convertToType(data['year'], 'Number');
            }
            if (data.hasOwnProperty('strategy')) {
                obj['strategy'] = ApiClient.convertToType(data['strategy'], 'Number');
            }
            if (data.hasOwnProperty('bloom_over_time')) {
                obj['bloom_over_time'] = ApiClient.convertToType(data['bloom_over_time'], [SimulationBloomOverTime]);
            }
            if (data.hasOwnProperty('visits_over_time')) {
                obj['visits_over_time'] = ApiClient.convertToType(data['visits_over_time'], [SimulationVisitsOverTime]);
            }
            if (data.hasOwnProperty('nut_set')) {
                obj['nut_set'] = ApiClient.convertToType(data['nut_set'], 'Number');
            }
        }
        return obj;
    }

    /**
    * Year of the result
    * @member {Number} year
    */
    year = undefined;
    /**
    * Strategy of the result
    * @member {Number} strategy
    */
    strategy = undefined;
    /**
    * @member {Array.<module:model/SimulationBloomOverTime>} bloom_over_time
    */
    bloom_over_time = undefined;
    /**
    * @member {Array.<module:model/SimulationVisitsOverTime>} visits_over_time
    */
    visits_over_time = undefined;
    /**
    * Percentage of flowers that will turn into almonds
    * @member {Number} nut_set
    */
    nut_set = undefined;




}
