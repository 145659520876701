import PropTypes from "prop-types";
import * as React from "react";
import HeatMap from "./HeatMap";

const YardsHeatMap = ({ yards, zoom }) => {
	// For performance reasons, we aggregate drops except for almonds
	// For almonds we want accuracy and performance is less a problem
	// Performance is only problematic when radius is big
	const isAggregated = yards[0].crops !== "almonds";

	let heatmapDrops = [];

	if (isAggregated) {
		yards.forEach((yard) => {
			if (yard.drops.length > 0) {
				// We take every drop of the yard and calculate the number of bee frames
				const totalFrames = yard.drops
					.map((drop) => drop.numberOfBeehives * drop.fobAverage)
					.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

				// We create a global drop instead of multiple small drops
				const globalDrop = {
					totalFrames: totalFrames,
					coordinate: yard.drops[0].coordinate,
				};
				heatmapDrops.push(globalDrop);
			}
		});
	} else {
		heatmapDrops = yards.map((y) => y.drops).flat();
	}

	return (
		yards[0].drops.length > 0 && (
			<HeatMap
				key={0}
				drops={heatmapDrops}
				crops={yards[0].crops}
				zoom={zoom}
				regionLatitude={yards[0].drops[0].coordinate.latitude}
				isAggregated={isAggregated}
			/>
		)
	);
};

YardsHeatMap.propTypes = {
	yards: PropTypes.array,
	zoom: PropTypes.number,
};

export default YardsHeatMap;
