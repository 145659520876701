import { TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import * as React from "react";
import StyledCell from "./StyledCell";
import StyledHead from "./StyledHead";

const WindowTable = ({ drop, deleteDrop }) => {
	return (
		<TableContainer component={Paper}>
			<Table size="small" aria-label="global metrics">
				<StyledHead />
				<TableBody>
					<TableRow>
						<StyledCell>{drop.numberOfBeehives}</StyledCell>
						<StyledCell>{drop.fobAverage}</StyledCell>
						<StyledCell>
							<IconButton onClick={deleteDrop} size="small">
								<DeleteIcon fontSize="small" />
							</IconButton>
						</StyledCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

WindowTable.propTypes = {
	drop: PropTypes.object.isRequired,
	deleteDrop: PropTypes.func.isRequired,
};
export default WindowTable;
