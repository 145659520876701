const metrics = {
	numberOfBeehives: (drops) =>
		drops?.reduce((tot, d) => tot + d?.numberOfBeehives || 0, 0) || 0,
	fobAverage: (drops) => {
		return (
			drops.reduce((tot, d) => tot + d?.numberOfBeehives * d?.fobAverage, 0) /
			drops.reduce((tot, d) => tot + d?.numberOfBeehives, 0)
		).toFixed(1);
	},
	hivesPerAcre: (size, drops) => {
		return (metrics.numberOfBeehives(drops) / size).toFixed(1);
	},
};

export default metrics;
