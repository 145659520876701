import { createTheme } from "@material-ui/core/styles";

const defaultTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			md: 1150,
			lg: 1400,
			xl: 1600,
		},
	},
	roundness: 10,
	roundnessProgressBar: 50,
	dimensions: {
		indent: 32,
	},
	fontSizes: {
		xbig: 40,
		big: 35,
		xlarge: 30,
		large: 25,
		xmedium: 20,
		smedium: 18,
		medium: 15,
		small: 13,
		xsmall: 10,
	},
	fonts: {
		bold: "Futura-Bold",
		xBold: "Futura-CondensedExtraBold",
		medium: "Futura-Medium",
		italic: "Futura-MediumItalic",
		condensedMedium: "Futura-CondensedMedium",
	},
	border: {
		invisible: "1px solid #fff",
		blackSmall: "1px solid #000",
		lightGreySmall: "1px solid rgba(0, 0, 0, 0.26)",
		tabSwitcher: "2px solid #c2c0c1",
		tabSwitcherSelected: "2px solid #4B4848",
		greySmall: "1px solid #898989",
		greyMedium: "2px solid #898989",
		greyLarge: "3px solid #898989",
	},
	colors: {
		background: "#fff",
		text: "#4B4848",
		alert: "#D16663",
		backgrounds: {
			// white
			primary: "#fff",
			// light green
			lightGreen: "#B9D3C0",
			// background white for images that do not take up the whole screen
			lightGrey: "#f6f4f4",
			// transparent white
			transparentWhite: "#ffffff55",
			// background gray for selected paper
			grey: "#E7E1E1",
			// background color for footer.
			green: "#31A853",
			// primary color for empty part
			darkWhite: "#F1EDED",
			// primary color for full part
			darkGreen: "#23753A",
			// secondary color for full part
			yellow: "#F0D269",
			yellowLight: "#fce49e",
			// tertiary color for full part
			red: "#D16663",
			// background & lines color when the step is passed for USER.
			deliveryStepUserMessage: "#B9D3C0",
			// background & lines color when the step is passed for UBEES.
			deliveryStepUbeesMessage: "#F0D269cc",
			// background & lines color when the step is passed for UBEES.
			deliveryStepNoOwnerMessage: "#e2dbda",
			// lines color when the step is passed for not done.
			deliveryStepNotDone: "#707070",
		},
		texts: {
			// black
			primary: "#4B4848",
			// grey
			secondary: "#804B4848",
			// yellow
			tertiary: "#f5d253",
			// white
			white: "#FFF",
			// red
			alert: "#D16663",
			// green
			green: "#22753A",
			// grey
			grey: "#c2c0c1",
			// dark grey
			darkGrey: "#a4a4a4",
			// secondary color for full part
			yellow: "#F0D269",
			// orange
			orange: "#FF7400",
		},
		button: {
			// green
			primary: "#3c7f4e",
			// yellow
			secondary: "#f5d253",
			// grey
			disabled: "#E2DADA",
			// white
			white: "#fff",
			// red
			red: "#D16663",
		},
		map: {
			background: {
				information: "#ccc",
			},
			polygonStroke: "#f5d253",
			polygonFill: "rgba(0, 0, 0, 0)",
		},
		icon: { grey: "#868686" },
		// background color for header & footer.
		primary: "#31A853",
		// yellow
		secondary: "#f5d253",
		// Color to use for texts on primary color.
		onPrimary: "#fff",
	},
});

const theme = createTheme({
	...defaultTheme,
	palette: {
		primary: {
			main: defaultTheme.colors.primary,
		},
		secondary: {
			main: defaultTheme.colors.secondary,
		},
	},
	overrides: {
		MuiTableCell: {
			root: {
				color: defaultTheme.colors.texts.primary,
				fontFamily: defaultTheme.fonts.medium,
			},
			head: {
				fontFamily: defaultTheme.fonts.bold,
				color: defaultTheme.colors.texts.primary,
				backgroundColor: defaultTheme.colors.backgrounds.yellowLight,
			},
			stickyHeader: {
				fontFamily: defaultTheme.fonts.bold,
				color: defaultTheme.colors.texts.primary,
				backgroundColor: defaultTheme.colors.backgrounds.yellowLight,
			},
			body: {
				fontSize: defaultTheme.fontSizes.medium,
				[defaultTheme.breakpoints.down("xs")]: {
					fontSize: defaultTheme.fontSizes.smedium,
				},
			},
		},
		MuiTypography: {
			h1: {
				fontSize: defaultTheme.fontSizes.big,
				fontFamily: defaultTheme.fonts.xBold,
				color: defaultTheme.colors.texts.primary,
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignSelf: "center",
				marginTop: defaultTheme.dimensions.indent,
				marginBottom: defaultTheme.dimensions.indent * 2,
			},
			h2: {
				fontSize: defaultTheme.fontSizes.xlarge,
				fontFamily: defaultTheme.fonts.xBold,
				color: defaultTheme.colors.texts.primary,
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignSelf: "center",
				marginTop: defaultTheme.dimensions.indent,
				marginBottom: defaultTheme.dimensions.indent * 2,
			},
			h3: {
				fontSize: defaultTheme.fontSizes.large,
				fontFamily: defaultTheme.fonts.xBold,
				color: defaultTheme.colors.texts.primary,
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignSelf: "center",
				marginTop: defaultTheme.dimensions.indent / 2,
				marginBottom: defaultTheme.dimensions.indent / 2,
			},
			subtitle1: {
				fontSize: defaultTheme.fontSizes.xmedium,
				fontFamily: defaultTheme.fonts.bold,
				color: defaultTheme.colors.texts.primary,
				[defaultTheme.breakpoints.down("xs")]: {
					fontSize: defaultTheme.fontSizes.smedium,
				},
			},
			subtitle2: {
				color: defaultTheme.colors.texts.primary,
				fontFamily: defaultTheme.fonts.medium,
				fontSize: defaultTheme.fontSizes.xmedium,
				[defaultTheme.breakpoints.down("xs")]: {
					fontSize: defaultTheme.fontSizes.smedium,
				},
			},
			body1: {
				fontFamily: defaultTheme.fonts.medium,
			},
			body2: {
				color: defaultTheme.colors.texts.primary,
				fontFamily: defaultTheme.fonts.medium,
				[defaultTheme.breakpoints.up("md")]: {
					fontSize: defaultTheme.fontSizes.xmedium,
					marginTop: defaultTheme.dimensions.indent / 2,
					marginBottom: defaultTheme.dimensions.indent / 2,
				},
				[defaultTheme.breakpoints.down("sm")]: {
					fontSize: defaultTheme.fontSizes.medium,
					marginTop: defaultTheme.dimensions.indent / 4,
					marginBottom: defaultTheme.dimensions.indent / 4,
				},
			},
			button: {
				fontFamily: defaultTheme.fonts.medium,
				fontSize: defaultTheme.fontSizes.small,
				color: defaultTheme.colors.texts.primary,
			},
		},
		MuiButton: {
			outlined: {
				"&.Mui-disabled .MuiTypography-button": {
					color: defaultTheme.colors.texts.darkGrey,
				},
				"&.Mui-disabled .MuiSvgIcon-colorAction": {
					color: defaultTheme.colors.texts.darkGrey,
				},
			},
		},
		MuiInput: {
			underline: {
				"&::before": {
					borderBottomColor: defaultTheme.colors.primary,
				},
				"&:hover:not(.Mui-disabled)": {
					"&::before": {
						borderBottom: "2px solid #22753A",
					},
				},
			},
			root: {
				fontFamily: defaultTheme.fonts.medium,
				fontSize: defaultTheme.fontSizes.medium,
				color: defaultTheme.colors.primary,
			},
		},
		MuiTablePagination: {
			caption: {
				fontFamily: defaultTheme.fonts.medium,
				fontSize: defaultTheme.fontSizes.medium,
				color: defaultTheme.colors.texts.primary,
			},
		},
		MuiDialog: {
			paperWidthMd: {
				padding: defaultTheme.dimensions.indent,
			},
			paperWidthSm: {
				padding: defaultTheme.dimensions.indent / 1.5,
			},
		},
	},
});

export default theme;
