import { createStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		cell: {
			overflow: "hidden",
			width: 180,
			display: "box",
			lineClamp: 1,
			boxOrient: "vertical",
		},
	}),
);

const ClientTableCell = ({
	children = null,
	isVisible,
	id = "",
	data,
	isEmpty = false,
}) => {
	const styles = useStyles();

	if (!isVisible) {
		return null;
	}

	if (isEmpty) {
		return <TableCell />;
	}

	return (
		<TableCell id={id} align="left">
			{data ? <div className={styles.cell}>{data}</div> : children}
		</TableCell>
	);
};

ClientTableCell.propTypes = {
	children: PropTypes.object,
	isEmpty: PropTypes.bool,
	isVisible: PropTypes.bool.isRequired,
	data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	id: PropTypes.string,
};
export default ClientTableCell;
