import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Button from "../../../../../../components/Button";

const useStyles = makeStyles((theme) =>
	createStyles({
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			textAlign: "center",
			margin: theme.dimensions.indent / 2,
		},
		button: {
			height: "100%",
			marginLeft: theme.dimensions.indent,
			backgroundColor: theme.colors.button.primary,
			color: theme.colors.texts.white,
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xmedium,
		},
		padding: {
			paddingBlock: theme.dimensions.indent,
		},
	}),
);

/**
 * NameAndSizePopUp component
 *
 * Pop up allowing you to enter the name and the size of the yard
 */
const NameAndSizePopUp = ({
	yard,
	setYardName,
	setYardSize,
	createYard,
	setYardCrops,
	setYardAdditionalInfo,
}) => {
	const styles = useStyles();

	return (
		<Grid item container spacing={2} className={styles.padding}>
			<Grid item>
				<TextField
					onChange={(e) => setYardName(e.target.value)}
					id="create-yard-name"
					label={i18n.t("Client.Yards.YardCreateMap.modal.setName")}
					variant="outlined"
					value={yard.name}
					size="small"
				/>
			</Grid>
			<Grid item>
				<TextField
					onChange={(e) => setYardSize(e.target.value)}
					type="number"
					id="create-yard-size"
					label={i18n.t("Client.Yards.YardCreateMap.modal.setSize")}
					variant="outlined"
					value={yard.size}
					size="small"
				/>
			</Grid>
			<Grid container item direction="column" xs={2}>
				<FormControl variant="outlined" size="small">
					<InputLabel shrink={false}>
						{!yard.crops && i18n.t("Client.Yards.YardCreateMap.modal.setCrops")}
					</InputLabel>
					<Select
						id="sort-by-label"
						onChange={(e) =>
							setYardCrops(e.target.value !== "" ? e.target.value : undefined)
						}
						value={yard.crops}
						size="small"
					>
						<MenuItem value="almonds">
							{i18n.t("Client.Yards.YardCreateMap.crops.almonds")}
						</MenuItem>
						<MenuItem value="blueberries">
							{i18n.t("Client.Yards.YardCreateMap.crops.blueberries")}
						</MenuItem>
						<MenuItem value="avocados">
							{i18n.t("Client.Yards.YardCreateMap.crops.avocados")}
						</MenuItem>
						<MenuItem value="cranberries">
							{i18n.t("Client.Yards.YardCreateMap.crops.cranberries")}
						</MenuItem>
						<MenuItem value="canola">
							{i18n.t("Client.Yards.YardCreateMap.crops.canola")}
						</MenuItem>
						<MenuItem value="apples">
							{i18n.t("Client.Yards.YardCreateMap.crops.apples")}
						</MenuItem>
						<MenuItem value="cherries">
							{i18n.t("Client.Yards.YardCreateMap.crops.cherries")}
						</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<Grid item>
				<TextField
					onChange={(e) => setYardAdditionalInfo(e.target.value)}
					id="create-yard-additional-info"
					label={i18n.t("Client.Yards.YardCreateMap.modal.setAdditionalInfo")}
					variant="outlined"
					value={yard.additionalInfo}
					size="small"
				/>
			</Grid>
			<Grid item>
				<Button
					onClick={createYard}
					label={i18n.t("Client.Yards.YardCreateMap.modal.createYard")}
				/>
			</Grid>
		</Grid>
	);
};

NameAndSizePopUp.propTypes = {
	yard: PropTypes.object.isRequired,
	setYardName: PropTypes.func.isRequired,
	setYardSize: PropTypes.func.isRequired,
	setYardCrops: PropTypes.func.isRequired,
	createYard: PropTypes.func.isRequired,
	setYardAdditionalInfo: PropTypes.func.isRequired,
};

export default NameAndSizePopUp;
