import * as React from "react";

const LoadingHandler = ({ children }) => {
	const [loadingCounter, setLoadingCounter] = React.useState(0);
	const incrementLoader = () => setLoadingCounter((l) => l + 1);
	const decrementLoader = () => setLoadingCounter((l) => l - 1);

	const withLoading = (fct) => (...params) =>
		new Promise((resolve, reject) => {
			incrementLoader();
			fct(...params)
				.then(resolve)
				.catch((err) => reject(err))
				.finally(decrementLoader);
		});

	return React.cloneElement(children, {
		withLoading,
		isLoading: loadingCounter > 0,
	});
};

export default LoadingHandler;
