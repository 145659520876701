import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import * as React from "react";
import DropsDetails from "../DropsDetails";

const useStyles = makeStyles(() => ({
	hidden: {
		display: "none",
	},
}));

/**
 * On row representing the yard's drops
 */
const TableRowDropsLevel = ({ drops, metrics, visible, info, updateYard }) => {
	const styles = useStyles();
	return (
		<TableRow>
			<TableCell colSpan={6} className={!visible ? styles.hidden : ""}>
				<Collapse in={visible}>
					{drops.length > 0 && typeof drops[1] === "string" ? (
						<CircularProgress />
					) : (
						<DropsDetails
							updateYard={updateYard}
							info={info}
							drops={drops}
							totalHives={metrics.totalHives}
							FoBAverage={metrics.FoBAverage}
						/>
					)}
				</Collapse>
			</TableCell>
		</TableRow>
	);
};

TableRowDropsLevel.propTypes = {
	metrics: PropTypes.shape({
		totalHives: PropTypes.number,
		FoBAverage: PropTypes.string,
	}).isRequired,
	drops: PropTypes.array.isRequired,
	info: PropTypes.string,
	visible: PropTypes.bool.isRequired,
	updateYard: PropTypes.func.isRequired,
};

export default TableRowDropsLevel;
