import { HeatmapLayer } from "@react-google-maps/api";
import PropTypes from "prop-types";
import * as React from "react";
import cropsParameters from "../../../constants/cropsParameters";
import gradients from "../../../constants/gradients";

const DEFAULT_CROP_PARAMETERS = [
	{ radius: 1500, opacity: 0.6 },
	{ radius: 2500, opacity: 0.4 },
	{ radius: 3500, opacity: 0.4 },
	{ radius: 4000, opacity: 0.4 },
	{ radius: 5000, opacity: 0.4 },
];

const HeatMap = ({ drops, crops, zoom, regionLatitude, isAggregated }) => {
	const currentCropParameters = cropsParameters[crops]
		? cropsParameters[crops]
		: DEFAULT_CROP_PARAMETERS;

	const calculateRadius = (baseRadiusInMeters, zoom) => {
		let metersPerPixel =
			(156543.03392 * Math.cos((regionLatitude * Math.PI) / 180)) /
			Math.pow(2, zoom);

		return baseRadiusInMeters / metersPerPixel;
	};

	const generateAccurateHeatMapLayer = (
		drop,
		circleIdx,
		zoom,
		dropIdx,
		isAggregated,
	) => {
		const radius = isAggregated
			? currentCropParameters[circleIdx].radius + drop.totalFrames
			: currentCropParameters[circleIdx].radius +
			  drop.numberOfBeehives * drop.fobAverage;

		const opacity = currentCropParameters[circleIdx].opacity;

		const gradient = gradients[circleIdx];
		const data = [
			{
				location: new window.google.maps.LatLng(
					drop.coordinate.latitude,
					drop.coordinate.longitude,
				),
				weight: 1,
			},
		];
		return (
			<HeatmapLayer
				data={data}
				options={{
					radius: calculateRadius(radius, zoom),
					gradient: gradient,
					opacity: opacity,
				}}
				key={`${circleIdx}.${dropIdx}`}
			/>
		);
	};

	const generateSimpleHeatMapLayer = (drops, zoom) => {
		const radius =
			currentCropParameters[currentCropParameters.length - 1].radius +
			drops[drops.length - 1].totalFrames;

		const data = drops.map((drop) => {
			return {
				location: new window.google.maps.LatLng(
					drop.coordinate.latitude,
					drop.coordinate.longitude,
				),
				weight: 1,
			};
		});
		return (
			<HeatmapLayer
				data={data}
				options={{
					radius: calculateRadius(radius, zoom),
				}}
				key="heatmapLayer"
			/>
		);
	};

	if (drops.length === 0) return null;

	return !isAggregated ? (
		<>
			{[...Array(currentCropParameters.length).keys()]
				.reverse()
				.map((circleIdx) =>
					drops.map((drop, dropIdx) =>
						generateAccurateHeatMapLayer(
							drop,
							circleIdx,
							zoom,
							dropIdx,
							isAggregated,
						),
					),
				)}
			;
		</>
	) : (
		<>{generateSimpleHeatMapLayer(drops, zoom)}</>
	);
};

HeatMap.propTypes = {
	drops: PropTypes.array,
	crops: PropTypes.string,
	yard: PropTypes.object,
	zoom: PropTypes.number,
	regionLatitude: PropTypes.number,
	isAggregated: PropTypes.bool,
};

export default HeatMap;
