/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Product model module.
* @module model/Product
* @version 0.0.1
*/
export default class Product {
    /**
    * Constructs a new <code>Product</code>.
    * Partial schema serving as a base for ProductBought and ProductAvailable
    * @alias module:model/Product
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Product</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Product} obj Optional instance to populate.
    * @return {module:model/Product} The populated <code>Product</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Product();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('fobAverage')) {
                obj['fobAverage'] = ApiClient.convertToType(data['fobAverage'], 'Number');
            }
            if (data.hasOwnProperty('locationOrigin')) {
                obj['locationOrigin'] = ApiClient.convertToType(data['locationOrigin'], 'String');
            }
            if (data.hasOwnProperty('rating')) {
                obj['rating'] = ApiClient.convertToType(data['rating'], 'Number');
            }
        }
        return obj;
    }

    /**
    * Id to identify the Beehive product
    * @member {String} id
    */
    id = undefined;
    /**
    * Price of such beehive available to the user (in $)
    * @member {Number} price
    */
    price = undefined;
    /**
    * Theoretical FOB average of the beehives
    * @member {Number} fobAverage
    */
    fobAverage = undefined;
    /**
    * From which region does the hive come from ?
    * @member {String} locationOrigin
    */
    locationOrigin = undefined;
    /**
    * Rating ranging from zero to five
    * @member {Number} rating
    */
    rating = undefined;




}
